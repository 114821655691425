// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  //gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  //gradientColors: "#0B3C49, #786F52, #FEB95F, #F71735, #C2095A",
  //gradientColors: " #ead1dc, #b4a7d6	, #9fc5e8	, #b6d7a8	, #d9ead3",
  gradientColors: "#b84680, #3e2943, #121d2e, #1e2d44, #5a4a78, #5baa8d", //#5baa8d #b84680
  //gradientColors: "#C9B1FF, #FFCAF2, #FFB2B1, #FFF3AD, #BCFFBC, #A2EDFF", //unicorn
  //gradientColors: "#fb4242, #a90101, #650000, #173a00, #016a0d, #39a005", //christmas
  firstName: "D'orlena",
  middleName: "Boutique",
  lastName: "Design",
  message: " Mutluluğunuzu Nasıl Alırdınız? ",
  imageSize: 350,
  icons1: [
    {
      image: "fa-instagram",
      url: "https://www.instagram.com/dorlena.boutique/",
    },
    {
      image: "fa fa-globe",
      url: "https://boutique.dorlena.com",
    },
    {
      image: "fa-whatsapp",
      url: "https://wa.me/905312111910",
    },
  ],
  icons2: [
    {
      image: "fa-instagram",
      url: "https://www.instagram.com/dorlena.design/",
    },
    {
      image: "fa fa-globe",
      url: "https://design.dorlena.com",
    },
    {
      image: "fa-whatsapp",
      url: "https://wa.me/905312111910",
    },
    {
      imageLink: require("../editable-stuff/s.png"),
      image: "",
      url: "https://www.shopier.com/dorlenadesign",
      alt: "SHOPİER"
    },
    {
      imageLink: require("../editable-stuff/ty.png"),
      image: "",
      url: "https://ty.gl/miiimyxhn",
      alt: "TRENDYOL",
      className: "icon"
    },
  ],
  imageLink1: require("../editable-stuff/boutiquebig.png"),
  imageLink2: require("../editable-stuff/designbig.png"),
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/hashirshoaeb.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const boutique = {
  show: true,
  heading: "D'orlena Boutique",
  imageLink: require("../editable-stuff/boutiquebig.png"),
  imageSize: 375,
  message: `D'orlena Boutique tekstil sektörüne 2022 yılında e-ticaret platformu olarak girmiş bir işletmedir. Her gün büyük bir heyecan ile yenilikleri takip eden, işini aşkla yapmaya çalışan bir işletme :)

    Satılan her ürün D'orlena Boutique güvencesi altındadır. Ürünlerin kalitesi, ürünlerin seçimi ve özenle paketleme aşamasında iki defa kontrol edilmektedir.
    
    Instagram ve whatsapp kanallarımız aracılığı ile sürekli her konuda ulaşılabilir durumdayız.`,
  icons: [
    {
      image: "fa-instagram",
      url: "https://www.instagram.com/dorlena.boutique/",
    },
    {
      image: "fa fa-globe",
      url: "https://boutique.dorlena.com",
    },
    {
      image: "fa-whatsapp",
      url: "https://wa.me/905312111910",
    },
  ],
};

const design = {
  show: true,
  heading: "D'orlena Design",
  imageLink: require("../editable-stuff/designbig.png"),
  imageSize: 375,
  message: `D'orlena Design el emeği ürünler sektörüne 2023 yılında e-ticaret platformu olarak girmiş bir işletmedir. Her gün büyük bir heyecan ile yenilikleri takip eden, işini aşkla yapmaya çalışan bir işletme :)

  Satılan her ürün D'orlena Design güvencesi altındadır. Ürünlerimiz tamamen kendi dizaynımız ve el emeğimizdir. Kişiye özel tasarım ürünler sadece 1 adet olarak üretilmektedir. Kişi için tasarımlar, tasarım sonrası satışa açılmaktadır.
  
  Instagram ve whatsapp kanallarımız aracılığı ile sürekli her konuda ulaşılabilir durumdayız. `,
  icons: [
    {
      image: "fa-instagram",
      url: "https://www.instagram.com/dorlena.design/",
    },
    {
      image: "fa fa-globe",
      url: "https://design.dorlena.com",
    },
    {
      image: "fa-whatsapp",
      url: "https://wa.me/905312111910",
    },
    {
      imageLink: require("../editable-stuff/s.png"),
      image: "",
      url: "https://www.shopier.com/dorlenadesign",
      alt: "SHOPİER"
    },
    {
      imageLink: require("../editable-stuff/ty.png"),
      image: "",
      url: "https://ty.gl/miiimyxhn",
      alt: "TRENDYOL",
      className: "icon"
    },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "İletişim",
  message:
    "",
  email: "",
};


// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, boutique, design, getInTouch };
