import React from "react";
import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  navBar,
  mainBody,
  getInTouch,
} from "./editable-stuff/config.js";


import Pantone from "./components/pantone/Pantone";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
// import { Blog } from "./components/blog/Blog";
// import BlogPost from "./components/blog/BlogPost";
import GetInTouch from "./components/home/GetInTouch.jsx";


const MainBody = React.lazy(() => import('./components/home/MainBody'));
const Home = React.forwardRef((props, ref) => {

  return (
    <>

      <Suspense fallback={<div>Loading...</div>}>

        <MainBody
          gradient={mainBody.gradientColors}
          icons1={mainBody.icons1}
          icons2={mainBody.icons2}
          message={mainBody.message}
          imageLink1={mainBody.imageLink1}
          imageLink2={mainBody.imageLink2}
          imgSize={mainBody.imageSize}
          ref={ref}
        />
      </Suspense>

      {getInTouch.show && (
        <GetInTouch
          heading={getInTouch.heading}
          message={getInTouch.message}
          email={getInTouch.email}
        />
      )}


    </>
  );
});

const PantoneHome = React.forwardRef((props, ref) => {

  return (
    <Pantone
      gradient={mainBody.gradientColors}
      ref={ref}
    />
  );
});

const App = () => {
  const titleRef = React.useRef();

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL + "/"}>
      {navBar.show && <Navbar ref={titleRef} />}
      <Routes>
        <Route path="/" exact element={<Home ref={titleRef} />} />
        <Route path="/pantone" exact element={<PantoneHome ref={titleRef} />} />
      </Routes>
      {/* {false && <Route path="/blog" exact component={Blog} />}
      {false && <Route path="/blog/:id" component={BlogPost} />} */}
      <Footer />
    </BrowserRouter>
  );
};

export default App;
