import React from "react";

const Footer = (props) => {
  const bgStyle = { backgroundColor: "#f5f5f5" };

  return (
    <footer style={bgStyle} className="mt-auto py-2 text-center ">
      <small className="text-muted">
        Copyright © 2024 D'orlena Desing - All Rights Reserved
      </small>
    </footer >
  );
};

export default Footer;
