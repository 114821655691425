import React, { useState } from "react";
import { useScrollPosition } from "../hooks/useScrollPosition";
import useResizeObserver from "../hooks/useResizeObserver";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { mainBody, boutique, getInTouch, design } from "../editable-stuff/config.js";
import { NavLink } from "./home/migration";

const Navigation = React.forwardRef((props, ref) => {
  // const { showBlog, FirstName } = config;
  const [isTop, setIsTop] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navbarMenuRef = React.useRef();
  const navbarDimensions = useResizeObserver(navbarMenuRef);
  const navBottom = navbarDimensions ? navbarDimensions.bottom : 0;
  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (!navbarDimensions) return;
      if (ref.current) {
        currPos.y + ref.current.offsetTop - navbarDimensions.bottom > 5
          ? setIsTop(true)
          : setIsTop(false);
      }
      setScrollPosition(currPos.y);
    },
    [navBottom]
  );

  React.useEffect(() => {
    if (!navbarDimensions) return;
    if (ref.current) {
      navBottom - scrollPosition >= ref.current.offsetTop
        ? setIsTop(false)
        : setIsTop(true);
    }
  }, [navBottom, navbarDimensions, ref, scrollPosition]);

  return (
    <Navbar
      ref={navbarMenuRef}
      className={`pxd-4 fixed-top  ${!isTop ? "navbar-white" : "navbar-transparent navbar-dark"
        }`}
      expand="lg"
    >
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggler" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="navbar-nav mr-auto">
          {/* {
            <NavLink className="nav-item lead">
              <Link to={process.env.PUBLIC_URL + "/blog"}>Blog</Link>
            </NavLink>
          } */}
          {design.show && (
            <NavLink
              className="nav-item lead"
              href="https://dorlena.com"
            >
              {`${mainBody.firstName}`}
            </NavLink>
          )}

          {boutique.show && (
            <NavLink
              className="nav-item lead"
              href="https://design.dorlena.com"
            >
              Design
            </NavLink>
          )}
          {getInTouch.show && (
            <NavLink
              className="nav-item lead"
              href="https://boutique.dorlena.com"
            >
              Boutique
            </NavLink>
          )}


          <NavLink
            className="nav-item lead"
            href="https://design.dorlena.com/pantone"
          >
            PANTONE
          </NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
});

export default Navigation;
