export const pantoneScala = [
    {
        "code": "13-0905 TPG",
        "name": "Birch",
        "rgb": {
            "r": 222,
            "g": 213,
            "b": 197
        },
        "hex": "DED5C5",
        "positionInBook": 21,
        "pageNumberInBook": "1.003",
        "lab": {
            "l": 85.71,
            "a": 1.44,
            "b": 8.77
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0703 TPG",
        "name": "Seedpearl",
        "rgb": {
            "r": 227,
            "g": 218,
            "b": 194
        },
        "hex": "E3DAC2",
        "positionInBook": 23,
        "pageNumberInBook": "1.004",
        "lab": {
            "l": 87.43,
            "a": -0.01,
            "b": 13.12
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0304 TPG",
        "name": "Whitecap Gray",
        "rgb": {
            "r": 225,
            "g": 215,
            "b": 198
        },
        "hex": "E1D7C6",
        "positionInBook": 25,
        "pageNumberInBook": "1.004",
        "lab": {
            "l": 86.51,
            "a": 1.44,
            "b": 9.76
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-0607 TPG",
        "name": "Fog",
        "rgb": {
            "r": 213,
            "g": 201,
            "b": 182
        },
        "hex": "D5C9B6",
        "positionInBook": 51,
        "pageNumberInBook": "1.008",
        "lab": {
            "l": 81.72,
            "a": 1.63,
            "b": 11.36
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-1106 TPG",
        "name": "Peyote",
        "rgb": {
            "r": 196,
            "g": 188,
            "b": 173
        },
        "hex": "C4BCAD",
        "positionInBook": 53,
        "pageNumberInBook": "1.008",
        "lab": {
            "l": 76.75,
            "a": 0.95,
            "b": 8.84
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1306 TPG",
        "name": "Oxford Tan",
        "rgb": {
            "r": 188,
            "g": 173,
            "b": 158
        },
        "hex": "BCAD9E",
        "positionInBook": 55,
        "pageNumberInBook": "1.008",
        "lab": {
            "l": 71.86,
            "a": 4.49,
            "b": 9.84
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-4302 TPG",
        "name": "Vaporous Gray",
        "rgb": {
            "r": 225,
            "g": 225,
            "b": 219
        },
        "hex": "E1E1DB",
        "positionInBook": 57,
        "pageNumberInBook": "1.009",
        "lab": {
            "l": 89.55,
            "a": -0.8,
            "b": 3
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-4403 TPG",
        "name": "Silver Birch",
        "rgb": {
            "r": 206,
            "g": 205,
            "b": 193
        },
        "hex": "CECDC1",
        "positionInBook": 59,
        "pageNumberInBook": "1.009",
        "lab": {
            "l": 82.17,
            "a": -0.93,
            "b": 6.03
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-0105 TPG",
        "name": "Overcast",
        "rgb": {
            "r": 196,
            "g": 192,
            "b": 170
        },
        "hex": "C4C0AA",
        "positionInBook": 61,
        "pageNumberInBook": "1.009",
        "lab": {
            "l": 77.59,
            "a": -1.47,
            "b": 11.78
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1105 TPG",
        "name": "Plaza Taupe",
        "rgb": {
            "r": 179,
            "g": 168,
            "b": 152
        },
        "hex": "B3A898",
        "positionInBook": 63,
        "pageNumberInBook": "1.009",
        "lab": {
            "l": 69.42,
            "a": 2.87,
            "b": 9.62
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0404 TPG",
        "name": "Light Gray",
        "rgb": {
            "r": 218,
            "g": 214,
            "b": 202
        },
        "hex": "DAD6CA",
        "positionInBook": 65,
        "pageNumberInBook": "1.010",
        "lab": {
            "l": 85.76,
            "a": 0.3,
            "b": 6.09
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-6308 TPG",
        "name": "Alfalfa",
        "rgb": {
            "r": 185,
            "g": 185,
            "b": 164
        },
        "hex": "B9B9A4",
        "positionInBook": 67,
        "pageNumberInBook": "1.010",
        "lab": {
            "l": 74.63,
            "a": -2.09,
            "b": 10.15
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-1108 TPG",
        "name": "Wood Ash",
        "rgb": {
            "r": 221,
            "g": 205,
            "b": 177
        },
        "hex": "DDCDB1",
        "positionInBook": 99,
        "pageNumberInBook": "1.015",
        "lab": {
            "l": 83.2,
            "a": 3.56,
            "b": 15.82
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-1112 TPG",
        "name": "Pebble",
        "rgb": {
            "r": 210,
            "g": 188,
            "b": 156
        },
        "hex": "D2BC9C",
        "positionInBook": 101,
        "pageNumberInBook": "1.015",
        "lab": {
            "l": 77.5,
            "a": 5.35,
            "b": 18.8
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1213 TPG",
        "name": "Savannah Tan",
        "rgb": {
            "r": 168,
            "g": 149,
            "b": 131
        },
        "hex": "A89583",
        "positionInBook": 103,
        "pageNumberInBook": "1.015",
        "lab": {
            "l": 63.11,
            "a": 5.85,
            "b": 12.06
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1312 TPG",
        "name": "Silver Mink",
        "rgb": {
            "r": 164,
            "g": 146,
            "b": 130
        },
        "hex": "A49282",
        "positionInBook": 105,
        "pageNumberInBook": "1.015",
        "lab": {
            "l": 62.08,
            "a": 5.52,
            "b": 11.43
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1217 TPG",
        "name": "Mojave Desert",
        "rgb": {
            "r": 199,
            "g": 183,
            "b": 149
        },
        "hex": "C7B795",
        "positionInBook": 107,
        "pageNumberInBook": "1.016",
        "lab": {
            "l": 75.25,
            "a": 1.92,
            "b": 19.85
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1307 TPG",
        "name": "White Pepper",
        "rgb": {
            "r": 186,
            "g": 170,
            "b": 149
        },
        "hex": "BAAA95",
        "positionInBook": 109,
        "pageNumberInBook": "1.016",
        "lab": {
            "l": 70.67,
            "a": 4.32,
            "b": 13.15
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1104 TPG",
        "name": "Crockery",
        "rgb": {
            "r": 171,
            "g": 157,
            "b": 141
        },
        "hex": "AB9D8D",
        "positionInBook": 111,
        "pageNumberInBook": "1.016",
        "lab": {
            "l": 65.81,
            "a": 3.86,
            "b": 10.24
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-1110 TPG",
        "name": "Boulder",
        "rgb": {
            "r": 209,
            "g": 190,
            "b": 154
        },
        "hex": "D1BE9A",
        "positionInBook": 113,
        "pageNumberInBook": "1.017",
        "lab": {
            "l": 78.11,
            "a": 2.58,
            "b": 20.96
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1119 TPG",
        "name": "Taos Taupe",
        "rgb": {
            "r": 191,
            "g": 171,
            "b": 131
        },
        "hex": "BFAB83",
        "positionInBook": 115,
        "pageNumberInBook": "1.017",
        "lab": {
            "l": 71.04,
            "a": 3.06,
            "b": 23.61
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-0920 TPG",
        "name": "Curds & Whey",
        "rgb": {
            "r": 192,
            "g": 166,
            "b": 134
        },
        "hex": "C0A686",
        "positionInBook": 117,
        "pageNumberInBook": "1.017",
        "lab": {
            "l": 70.09,
            "a": 7.09,
            "b": 20.64
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1324 TPG",
        "name": "Lark",
        "rgb": {
            "r": 190,
            "g": 159,
            "b": 119
        },
        "hex": "BE9F77",
        "positionInBook": 119,
        "pageNumberInBook": "1.017",
        "lab": {
            "l": 67.87,
            "a": 8.32,
            "b": 25.85
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-1016 TPG",
        "name": "Wheat",
        "rgb": {
            "r": 226,
            "g": 198,
            "b": 165
        },
        "hex": "E2C6A5",
        "positionInBook": 121,
        "pageNumberInBook": "1.018",
        "lab": {
            "l": 81.81,
            "a": 6.68,
            "b": 20.89
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-1118 TPG",
        "name": "Beige",
        "rgb": {
            "r": 212,
            "g": 185,
            "b": 150
        },
        "hex": "D4B996",
        "positionInBook": 123,
        "pageNumberInBook": "1.018",
        "lab": {
            "l": 77,
            "a": 6.42,
            "b": 21.84
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1020 TPG",
        "name": "Ginger Root",
        "rgb": {
            "r": 193,
            "g": 166,
            "b": 139
        },
        "hex": "C1A68B",
        "positionInBook": 125,
        "pageNumberInBook": "1.018",
        "lab": {
            "l": 70.14,
            "a": 8.07,
            "b": 17.71
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1215 TPG",
        "name": "Sesame",
        "rgb": {
            "r": 191,
            "g": 166,
            "b": 143
        },
        "hex": "BFA68F",
        "positionInBook": 151,
        "pageNumberInBook": "1.022",
        "lab": {
            "l": 69.95,
            "a": 7.68,
            "b": 15.22
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1314 TPG",
        "name": "Cuban Sand",
        "rgb": {
            "r": 195,
            "g": 169,
            "b": 144
        },
        "hex": "C3A990",
        "positionInBook": 153,
        "pageNumberInBook": "1.022",
        "lab": {
            "l": 71.3,
            "a": 7.15,
            "b": 16.92
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1309 TPG",
        "name": "Moonlight",
        "rgb": {
            "r": 194,
            "g": 174,
            "b": 157
        },
        "hex": "C2AE9D",
        "positionInBook": 155,
        "pageNumberInBook": "1.023",
        "lab": {
            "l": 72.67,
            "a": 5.33,
            "b": 11.73
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1308 TPG",
        "name": "Doeskin",
        "rgb": {
            "r": 190,
            "g": 173,
            "b": 154
        },
        "hex": "BEAD9A",
        "positionInBook": 157,
        "pageNumberInBook": "1.023",
        "lab": {
            "l": 72,
            "a": 3.76,
            "b": 12.27
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1318 TPG",
        "name": "Warm Taupe",
        "rgb": {
            "r": 177,
            "g": 153,
            "b": 134
        },
        "hex": "B19986",
        "positionInBook": 159,
        "pageNumberInBook": "1.023",
        "lab": {
            "l": 65.06,
            "a": 7.3,
            "b": 13.55
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1316 TPG",
        "name": "Portabella",
        "rgb": {
            "r": 154,
            "g": 133,
            "b": 119
        },
        "hex": "9A8577",
        "positionInBook": 161,
        "pageNumberInBook": "1.023",
        "lab": {
            "l": 57.19,
            "a": 7.22,
            "b": 10.81
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1415 TPG",
        "name": "Almondine",
        "rgb": {
            "r": 171,
            "g": 143,
            "b": 127
        },
        "hex": "AB8F7F",
        "positionInBook": 163,
        "pageNumberInBook": "1.024",
        "lab": {
            "l": 61.98,
            "a": 9.72,
            "b": 12.86
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1418 TPG",
        "name": "Ginger Snap",
        "rgb": {
            "r": 150,
            "g": 129,
            "b": 114
        },
        "hex": "968172",
        "positionInBook": 165,
        "pageNumberInBook": "1.024",
        "lab": {
            "l": 55.7,
            "a": 6.74,
            "b": 11.28
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1417 TPG",
        "name": "Beaver Fur",
        "rgb": {
            "r": 149,
            "g": 120,
            "b": 103
        },
        "hex": "957867",
        "positionInBook": 167,
        "pageNumberInBook": "1.024",
        "lab": {
            "l": 53.19,
            "a": 9.8,
            "b": 14.21
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-0909 TPG",
        "name": "Fossil",
        "rgb": {
            "r": 128,
            "g": 116,
            "b": 102
        },
        "hex": "807466",
        "positionInBook": 199,
        "pageNumberInBook": "1.029",
        "lab": {
            "l": 49.65,
            "a": 3.28,
            "b": 9.7
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-0809 TPG",
        "name": "Chocolate Chip",
        "rgb": {
            "r": 106,
            "g": 96,
            "b": 84
        },
        "hex": "6A6054",
        "positionInBook": 201,
        "pageNumberInBook": "1.029",
        "lab": {
            "l": 41.46,
            "a": 2.79,
            "b": 8.45
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1304 TPG",
        "name": "Falcon",
        "rgb": {
            "r": 111,
            "g": 101,
            "b": 96
        },
        "hex": "6F6560",
        "positionInBook": 203,
        "pageNumberInBook": "1.029",
        "lab": {
            "l": 43.74,
            "a": 4.17,
            "b": 4.1
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1105 TPG",
        "name": "Roasted Cashew",
        "rgb": {
            "r": 151,
            "g": 139,
            "b": 127
        },
        "hex": "978B7F",
        "positionInBook": 205,
        "pageNumberInBook": "1.030",
        "lab": {
            "l": 58.86,
            "a": 3.35,
            "b": 8.27
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0513 TPG",
        "name": "Bungee Cord",
        "rgb": {
            "r": 113,
            "g": 106,
            "b": 97
        },
        "hex": "716A61",
        "positionInBook": 207,
        "pageNumberInBook": "1.030",
        "lab": {
            "l": 45.26,
            "a": 2.33,
            "b": 6.08
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-0810 TPG",
        "name": "Major Brown",
        "rgb": {
            "r": 97,
            "g": 91,
            "b": 82
        },
        "hex": "615B52",
        "positionInBook": 209,
        "pageNumberInBook": "1.030",
        "lab": {
            "l": 38.99,
            "a": 1.04,
            "b": 5.9
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1103 TPG",
        "name": "Pure Cashmere",
        "rgb": {
            "r": 177,
            "g": 166,
            "b": 153
        },
        "hex": "B1A699",
        "positionInBook": 211,
        "pageNumberInBook": "1.031",
        "lab": {
            "l": 68.89,
            "a": 2.49,
            "b": 8.24
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1110 TPG",
        "name": "Brindle",
        "rgb": {
            "r": 136,
            "g": 126,
            "b": 116
        },
        "hex": "887E74",
        "positionInBook": 213,
        "pageNumberInBook": "1.031",
        "lab": {
            "l": 53.62,
            "a": 3.03,
            "b": 7.02
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-0820 TPG",
        "name": "Canteen",
        "rgb": {
            "r": 98,
            "g": 89,
            "b": 79
        },
        "hex": "62594F",
        "positionInBook": 215,
        "pageNumberInBook": "1.031",
        "lab": {
            "l": 38.56,
            "a": 1.89,
            "b": 7.71
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0615 TPG",
        "name": "Stone Gray",
        "rgb": {
            "r": 114,
            "g": 105,
            "b": 92
        },
        "hex": "72695C",
        "positionInBook": 217,
        "pageNumberInBook": "1.031",
        "lab": {
            "l": 45.02,
            "a": 2.51,
            "b": 8.58
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-4502 TPG",
        "name": "Silver Cloud",
        "rgb": {
            "r": 197,
            "g": 188,
            "b": 181
        },
        "hex": "C5BCB5",
        "positionInBook": 219,
        "pageNumberInBook": "1.032",
        "lab": {
            "l": 76.99,
            "a": 2.67,
            "b": 4.87
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-0000 TPG",
        "name": "Silver Gray",
        "rgb": {
            "r": 197,
            "g": 190,
            "b": 182
        },
        "hex": "C5BEB6",
        "positionInBook": 221,
        "pageNumberInBook": "1.032",
        "lab": {
            "l": 77.44,
            "a": 1.69,
            "b": 4.91
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1305 TPG",
        "name": "String",
        "rgb": {
            "r": 172,
            "g": 164,
            "b": 155
        },
        "hex": "ACA49B",
        "positionInBook": 223,
        "pageNumberInBook": "1.032",
        "lab": {
            "l": 67.9,
            "a": 1.79,
            "b": 5.95
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-0002 TPG",
        "name": "White Sand",
        "rgb": {
            "r": 220,
            "g": 214,
            "b": 209
        },
        "hex": "DCD6D1",
        "positionInBook": 225,
        "pageNumberInBook": "1.033",
        "lab": {
            "l": 85.98,
            "a": 1.97,
            "b": 3.33
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1501 TPG",
        "name": "Wild Dove",
        "rgb": {
            "r": 139,
            "g": 142,
            "b": 141
        },
        "hex": "8B8E8D",
        "positionInBook": 251,
        "pageNumberInBook": "1.036",
        "lab": {
            "l": 58.58,
            "a": -0.93,
            "b": -0.45
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-4705 TPG",
        "name": "Blue Blush",
        "rgb": {
            "r": 214,
            "g": 222,
            "b": 217
        },
        "hex": "D6DED9",
        "positionInBook": 253,
        "pageNumberInBook": "1.037",
        "lab": {
            "l": 87.69,
            "a": -3.81,
            "b": 1.38
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-4804 TPG",
        "name": "Blue Fox",
        "rgb": {
            "r": 181,
            "g": 190,
            "b": 184
        },
        "hex": "B5BEB8",
        "positionInBook": 255,
        "pageNumberInBook": "1.037",
        "lab": {
            "l": 76.17,
            "a": -4.06,
            "b": 1.84
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-4402 TPG",
        "name": "Neutral Gray",
        "rgb": {
            "r": 138,
            "g": 143,
            "b": 142
        },
        "hex": "8A8F8E",
        "positionInBook": 257,
        "pageNumberInBook": "1.037",
        "lab": {
            "l": 58.89,
            "a": -2.3,
            "b": -0.05
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0306 TPG",
        "name": "Gunmetal",
        "rgb": {
            "r": 96,
            "g": 99,
            "b": 97
        },
        "hex": "606361",
        "positionInBook": 259,
        "pageNumberInBook": "1.037",
        "lab": {
            "l": 41.7,
            "a": -1.47,
            "b": 0.96
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-4201 TPG",
        "name": "Oyster Mushroom",
        "rgb": {
            "r": 200,
            "g": 202,
            "b": 202
        },
        "hex": "C8CACA",
        "positionInBook": 261,
        "pageNumberInBook": "1.038",
        "lab": {
            "l": 81.02,
            "a": -0.25,
            "b": -0.75
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-4305 TPG",
        "name": "Quarry",
        "rgb": {
            "r": 154,
            "g": 164,
            "b": 169
        },
        "hex": "9AA4A9",
        "positionInBook": 263,
        "pageNumberInBook": "1.038",
        "lab": {
            "l": 66.5,
            "a": -2.97,
            "b": -4.21
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-3914 TPG",
        "name": "Sharkskin",
        "rgb": {
            "r": 138,
            "g": 138,
            "b": 140
        },
        "hex": "8A8A8C",
        "positionInBook": 265,
        "pageNumberInBook": "1.038",
        "lab": {
            "l": 57.6,
            "a": 0.82,
            "b": -1.28
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-4108 TPG",
        "name": "Nimbus Cloud",
        "rgb": {
            "r": 218,
            "g": 217,
            "b": 219
        },
        "hex": "DAD9DB",
        "positionInBook": 267,
        "pageNumberInBook": "1.039",
        "lab": {
            "l": 86.7,
            "a": 0.73,
            "b": -0.87
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3908 TPG",
        "name": "Volcanic Glass",
        "rgb": {
            "r": 104,
            "g": 99,
            "b": 104
        },
        "hex": "686368",
        "positionInBook": 299,
        "pageNumberInBook": "1.043",
        "lab": {
            "l": 42.57,
            "a": 3.49,
            "b": -2.13
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3906 TPG",
        "name": "Boulevard",
        "rgb": {
            "r": 93,
            "g": 89,
            "b": 95
        },
        "hex": "5D595F",
        "positionInBook": 301,
        "pageNumberInBook": "1.043",
        "lab": {
            "l": 38.21,
            "a": 3.54,
            "b": -3.37
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-4016 TPG",
        "name": "Gray Flannel",
        "rgb": {
            "r": 137,
            "g": 133,
            "b": 133
        },
        "hex": "898585",
        "positionInBook": 303,
        "pageNumberInBook": "1.044",
        "lab": {
            "l": 56.01,
            "a": 1.88,
            "b": 0.5
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0503 TPG",
        "name": "Gargoyle",
        "rgb": {
            "r": 107,
            "g": 108,
            "b": 107
        },
        "hex": "6B6C6B",
        "positionInBook": 305,
        "pageNumberInBook": "1.044",
        "lab": {
            "l": 45.38,
            "a": -0.51,
            "b": 0.19
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-5206 TPG",
        "name": "Thunderstorm",
        "rgb": {
            "r": 96,
            "g": 95,
            "b": 96
        },
        "hex": "605F60",
        "positionInBook": 307,
        "pageNumberInBook": "1.044",
        "lab": {
            "l": 40.29,
            "a": 1.31,
            "b": -1.1
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-4405 TPG",
        "name": "Forest River",
        "rgb": {
            "r": 59,
            "g": 65,
            "b": 66
        },
        "hex": "3B4142",
        "positionInBook": 309,
        "pageNumberInBook": "1.045",
        "lab": {
            "l": 26.78,
            "a": -2.22,
            "b": -2.16
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-5005 TPG",
        "name": "Unexplored",
        "rgb": {
            "r": 72,
            "g": 74,
            "b": 73
        },
        "hex": "484A49",
        "positionInBook": 311,
        "pageNumberInBook": "1.045",
        "lab": {
            "l": 31.33,
            "a": -0.92,
            "b": 0.3
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1100 TPG",
        "name": "Bristol Black",
        "rgb": {
            "r": 71,
            "g": 68,
            "b": 69
        },
        "hex": "474445",
        "positionInBook": 313,
        "pageNumberInBook": "1.045",
        "lab": {
            "l": 29.11,
            "a": 1.9,
            "b": -0.39
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-0205 TPG",
        "name": "Black Oyster",
        "rgb": {
            "r": 74,
            "g": 74,
            "b": 77
        },
        "hex": "4A4A4D",
        "positionInBook": 315,
        "pageNumberInBook": "1.045",
        "lab": {
            "l": 31.55,
            "a": 0.51,
            "b": -1.9
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0201 TPG",
        "name": "Castlerock",
        "rgb": {
            "r": 95,
            "g": 99,
            "b": 103
        },
        "hex": "5F6367",
        "positionInBook": 317,
        "pageNumberInBook": "1.046",
        "lab": {
            "l": 41.79,
            "a": -1.23,
            "b": -2.62
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-5210 TPG",
        "name": "Eiffel Tower",
        "rgb": {
            "r": 102,
            "g": 96,
            "b": 98
        },
        "hex": "666062",
        "positionInBook": 319,
        "pageNumberInBook": "1.046",
        "lab": {
            "l": 41.45,
            "a": 2.96,
            "b": -0.05
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-0201 TPG",
        "name": "Asphalt",
        "rgb": {
            "r": 82,
            "g": 82,
            "b": 87
        },
        "hex": "525257",
        "positionInBook": 321,
        "pageNumberInBook": "1.046",
        "lab": {
            "l": 35.12,
            "a": 0.64,
            "b": -2.59
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3905 TPG",
        "name": "Rabbit",
        "rgb": {
            "r": 94,
            "g": 90,
            "b": 94
        },
        "hex": "5E5A5E",
        "positionInBook": 323,
        "pageNumberInBook": "1.047",
        "lab": {
            "l": 38.87,
            "a": 2.33,
            "b": -1.67
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3903 TPG",
        "name": "Shale",
        "rgb": {
            "r": 77,
            "g": 72,
            "b": 75
        },
        "hex": "4D484B",
        "positionInBook": 325,
        "pageNumberInBook": "1.047",
        "lab": {
            "l": 31.1,
            "a": 2.72,
            "b": -1.13
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-0812 TPG",
        "name": "Turkish Coffee",
        "rgb": {
            "r": 77,
            "g": 71,
            "b": 67
        },
        "hex": "4D4743",
        "positionInBook": 351,
        "pageNumberInBook": "1.051",
        "lab": {
            "l": 30.79,
            "a": 1.66,
            "b": 3.37
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-0614 TPG",
        "name": "Wren",
        "rgb": {
            "r": 79,
            "g": 72,
            "b": 65
        },
        "hex": "4F4841",
        "positionInBook": 353,
        "pageNumberInBook": "1.051",
        "lab": {
            "l": 31.07,
            "a": 1.97,
            "b": 5.41
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-0910 TPG",
        "name": "Mulch",
        "rgb": {
            "r": 80,
            "g": 72,
            "b": 70
        },
        "hex": "504846",
        "positionInBook": 355,
        "pageNumberInBook": "1.051",
        "lab": {
            "l": 31.38,
            "a": 3.38,
            "b": 2.47
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1103 TPG",
        "name": "Espresso",
        "rgb": {
            "r": 68,
            "g": 62,
            "b": 65
        },
        "hex": "443E41",
        "positionInBook": 357,
        "pageNumberInBook": "1.051",
        "lab": {
            "l": 27.03,
            "a": 3.32,
            "b": -0.48
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-0912 TPG",
        "name": "Chocolate Brown",
        "rgb": {
            "r": 78,
            "g": 68,
            "b": 62
        },
        "hex": "4E443E",
        "positionInBook": 359,
        "pageNumberInBook": "1.052",
        "lab": {
            "l": 29.63,
            "a": 3.71,
            "b": 4.93
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1016 TPG",
        "name": "Java",
        "rgb": {
            "r": 76,
            "g": 65,
            "b": 62
        },
        "hex": "4C413E",
        "positionInBook": 361,
        "pageNumberInBook": "1.052",
        "lab": {
            "l": 28.73,
            "a": 4.08,
            "b": 3.96
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1106 TPG",
        "name": "Molé",
        "rgb": {
            "r": 72,
            "g": 61,
            "b": 60
        },
        "hex": "483D3C",
        "positionInBook": 363,
        "pageNumberInBook": "1.052",
        "lab": {
            "l": 27,
            "a": 5.38,
            "b": 2.67
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1213 TPG",
        "name": "Shopping Bag",
        "rgb": {
            "r": 91,
            "g": 75,
            "b": 72
        },
        "hex": "5B4B48",
        "positionInBook": 365,
        "pageNumberInBook": "1.053",
        "lab": {
            "l": 33.58,
            "a": 6.29,
            "b": 4.93
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1314 TPG",
        "name": "Seal Brown",
        "rgb": {
            "r": 76,
            "g": 65,
            "b": 64
        },
        "hex": "4C4140",
        "positionInBook": 367,
        "pageNumberInBook": "1.053",
        "lab": {
            "l": 28.79,
            "a": 4.59,
            "b": 2.38
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1325 TPG",
        "name": "Hot Chocolate",
        "rgb": {
            "r": 112,
            "g": 74,
            "b": 73
        },
        "hex": "704A49",
        "positionInBook": 399,
        "pageNumberInBook": "1.057",
        "lab": {
            "l": 36.2,
            "a": 17.24,
            "b": 8.22
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1433 TPG",
        "name": "Chutney",
        "rgb": {
            "r": 150,
            "g": 95,
            "b": 83
        },
        "hex": "965F53",
        "positionInBook": 401,
        "pageNumberInBook": "1.058",
        "lab": {
            "l": 46.58,
            "a": 23.03,
            "b": 17.29
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1541 TPG",
        "name": "Brandy Brown",
        "rgb": {
            "r": 120,
            "g": 70,
            "b": 62
        },
        "hex": "78463E",
        "positionInBook": 403,
        "pageNumberInBook": "1.058",
        "lab": {
            "l": 35.93,
            "a": 22.4,
            "b": 14.91
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1325 TPG",
        "name": "Spiced Apple",
        "rgb": {
            "r": 126,
            "g": 72,
            "b": 71
        },
        "hex": "7E4847",
        "positionInBook": 405,
        "pageNumberInBook": "1.058",
        "lab": {
            "l": 37.58,
            "a": 24.5,
            "b": 11.74
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1432 TPG",
        "name": "Redwood Burl",
        "rgb": {
            "r": 159,
            "g": 94,
            "b": 85
        },
        "hex": "9F5E55",
        "positionInBook": 407,
        "pageNumberInBook": "1.059",
        "lab": {
            "l": 47.97,
            "a": 27.53,
            "b": 18.11
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1341 TPG",
        "name": "Maple Syrup",
        "rgb": {
            "r": 141,
            "g": 87,
            "b": 75
        },
        "hex": "8D574B",
        "positionInBook": 409,
        "pageNumberInBook": "1.059",
        "lab": {
            "l": 43.35,
            "a": 22.76,
            "b": 17.37
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1541 TPG",
        "name": "Roasted Russet",
        "rgb": {
            "r": 131,
            "g": 74,
            "b": 66
        },
        "hex": "834A42",
        "positionInBook": 411,
        "pageNumberInBook": "1.059",
        "lab": {
            "l": 38.66,
            "a": 25.23,
            "b": 16.47
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1242 TPG",
        "name": "Cambridge Brown",
        "rgb": {
            "r": 119,
            "g": 79,
            "b": 64
        },
        "hex": "774F40",
        "positionInBook": 413,
        "pageNumberInBook": "1.059",
        "lab": {
            "l": 38.15,
            "a": 16.65,
            "b": 16.74
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1350 TPG",
        "name": "Burnt Brick",
        "rgb": {
            "r": 163,
            "g": 88,
            "b": 77
        },
        "hex": "A3584D",
        "positionInBook": 415,
        "pageNumberInBook": "1.060",
        "lab": {
            "l": 46.93,
            "a": 32.16,
            "b": 21.55
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1531 TPG",
        "name": "Barn Red",
        "rgb": {
            "r": 144,
            "g": 79,
            "b": 76
        },
        "hex": "904F4C",
        "positionInBook": 417,
        "pageNumberInBook": "1.060",
        "lab": {
            "l": 42.05,
            "a": 29,
            "b": 15.03
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1440 TPG",
        "name": "Chili Oil",
        "rgb": {
            "r": 148,
            "g": 71,
            "b": 67
        },
        "hex": "944743",
        "positionInBook": 419,
        "pageNumberInBook": "1.060",
        "lab": {
            "l": 40.92,
            "a": 33.73,
            "b": 19.37
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1443 TPG",
        "name": "Redwood",
        "rgb": {
            "r": 164,
            "g": 96,
            "b": 87
        },
        "hex": "A46057",
        "positionInBook": 421,
        "pageNumberInBook": "1.061",
        "lab": {
            "l": 48.97,
            "a": 29.31,
            "b": 18.49
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1441 TPG",
        "name": "Baked Clay",
        "rgb": {
            "r": 161,
            "g": 94,
            "b": 79
        },
        "hex": "A15E4F",
        "positionInBook": 423,
        "pageNumberInBook": "1.061",
        "lab": {
            "l": 48.13,
            "a": 28.09,
            "b": 21.74
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1245 TPG",
        "name": "Arabian Spice",
        "rgb": {
            "r": 140,
            "g": 82,
            "b": 72
        },
        "hex": "8C5248",
        "positionInBook": 425,
        "pageNumberInBook": "1.061",
        "lab": {
            "l": 42.02,
            "a": 25.54,
            "b": 17.71
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1307 TPG",
        "name": "Coffee Quartz",
        "rgb": {
            "r": 109,
            "g": 93,
            "b": 88
        },
        "hex": "6D5D58",
        "positionInBook": 451,
        "pageNumberInBook": "1.065",
        "lab": {
            "l": 41.08,
            "a": 6.36,
            "b": 5.57
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1223 TPG",
        "name": "Downtown Brown",
        "rgb": {
            "r": 104,
            "g": 75,
            "b": 65
        },
        "hex": "684B41",
        "positionInBook": 453,
        "pageNumberInBook": "1.065",
        "lab": {
            "l": 35.07,
            "a": 13.14,
            "b": 11.47
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-0913 TPG",
        "name": "Hot Fudge",
        "rgb": {
            "r": 84,
            "g": 71,
            "b": 67
        },
        "hex": "544743",
        "positionInBook": 455,
        "pageNumberInBook": "1.065",
        "lab": {
            "l": 31.41,
            "a": 5.8,
            "b": 4.35
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1044 TPG",
        "name": "Chipmunk",
        "rgb": {
            "r": 151,
            "g": 114,
            "b": 82
        },
        "hex": "977252",
        "positionInBook": 457,
        "pageNumberInBook": "1.066",
        "lab": {
            "l": 51.39,
            "a": 12.17,
            "b": 24.04
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1030 TPG",
        "name": "Thrush",
        "rgb": {
            "r": 145,
            "g": 112,
            "b": 90
        },
        "hex": "91705A",
        "positionInBook": 459,
        "pageNumberInBook": "1.066",
        "lab": {
            "l": 50.36,
            "a": 11.53,
            "b": 17.78
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1137 TPG",
        "name": "Rawhide",
        "rgb": {
            "r": 134,
            "g": 99,
            "b": 81
        },
        "hex": "866351",
        "positionInBook": 461,
        "pageNumberInBook": "1.066",
        "lab": {
            "l": 45.65,
            "a": 13.35,
            "b": 16.48
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1324 TPG",
        "name": "Cinnamon Swirl",
        "rgb": {
            "r": 161,
            "g": 128,
            "b": 104
        },
        "hex": "A18068",
        "positionInBook": 463,
        "pageNumberInBook": "1.067",
        "lab": {
            "l": 56.39,
            "a": 11.69,
            "b": 18.04
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1317 TPG",
        "name": "Sinopia Fresco",
        "rgb": {
            "r": 150,
            "g": 121,
            "b": 108
        },
        "hex": "96796C",
        "positionInBook": 465,
        "pageNumberInBook": "1.067",
        "lab": {
            "l": 53.63,
            "a": 10.94,
            "b": 11.61
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1125 TPG",
        "name": "Pecan Pie",
        "rgb": {
            "r": 127,
            "g": 96,
            "b": 80
        },
        "hex": "7F6050",
        "positionInBook": 467,
        "pageNumberInBook": "1.067",
        "lab": {
            "l": 43.74,
            "a": 12.28,
            "b": 14.27
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "11-1007 TPG",
        "name": "Coconut Cream",
        "rgb": {
            "r": 239,
            "g": 227,
            "b": 222
        },
        "hex": "EFE3DE",
        "positionInBook": 499,
        "pageNumberInBook": "1.072",
        "lab": {
            "l": 91.02,
            "a": 4.32,
            "b": 3.5
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-2103 TPG",
        "name": "Almost Mauve",
        "rgb": {
            "r": 234,
            "g": 222,
            "b": 219
        },
        "hex": "EADEDB",
        "positionInBook": 501,
        "pageNumberInBook": "1.072",
        "lab": {
            "l": 89.52,
            "a": 4.06,
            "b": 3.04
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-1105 TPG",
        "name": "Salsa Rosa",
        "rgb": {
            "r": 241,
            "g": 221,
            "b": 211
        },
        "hex": "F1DDD3",
        "positionInBook": 503,
        "pageNumberInBook": "1.072",
        "lab": {
            "l": 89.61,
            "a": 6.72,
            "b": 7.53
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "11-1302 TPG",
        "name": "Sea Salt",
        "rgb": {
            "r": 241,
            "g": 233,
            "b": 224
        },
        "hex": "F1E9E0",
        "positionInBook": 505,
        "pageNumberInBook": "1.073",
        "lab": {
            "l": 92.71,
            "a": 1.94,
            "b": 5.24
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "11-1306 TPG",
        "name": "Cream Pink",
        "rgb": {
            "r": 242,
            "g": 226,
            "b": 216
        },
        "hex": "F2E2D8",
        "positionInBook": 507,
        "pageNumberInBook": "1.073",
        "lab": {
            "l": 90.92,
            "a": 4.79,
            "b": 6.83
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-1108 TPG",
        "name": "Dew",
        "rgb": {
            "r": 239,
            "g": 226,
            "b": 212
        },
        "hex": "EFE2D4",
        "positionInBook": 509,
        "pageNumberInBook": "1.073",
        "lab": {
            "l": 90.7,
            "a": 3.22,
            "b": 8.63
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-1504 TPG",
        "name": "Peach Blush",
        "rgb": {
            "r": 230,
            "g": 205,
            "b": 197
        },
        "hex": "E6CDC5",
        "positionInBook": 511,
        "pageNumberInBook": "1.073",
        "lab": {
            "l": 84.56,
            "a": 8.63,
            "b": 7.42
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-1107 TPG",
        "name": "Peach Dust",
        "rgb": {
            "r": 242,
            "g": 219,
            "b": 204
        },
        "hex": "F2DBCC",
        "positionInBook": 513,
        "pageNumberInBook": "1.074",
        "lab": {
            "l": 89.09,
            "a": 6.61,
            "b": 10.47
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-1110 TPG",
        "name": "Crème de Pêche",
        "rgb": {
            "r": 248,
            "g": 219,
            "b": 201
        },
        "hex": "F8DBC9",
        "positionInBook": 515,
        "pageNumberInBook": "1.074",
        "lab": {
            "l": 89.7,
            "a": 8.76,
            "b": 12.97
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-1404 TPG",
        "name": "Pale Dogwood",
        "rgb": {
            "r": 240,
            "g": 207,
            "b": 195
        },
        "hex": "F0CFC3",
        "positionInBook": 517,
        "pageNumberInBook": "1.074",
        "lab": {
            "l": 85.9,
            "a": 10.45,
            "b": 10.89
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-1406 TPG",
        "name": "Cloud Pink",
        "rgb": {
            "r": 238,
            "g": 209,
            "b": 198
        },
        "hex": "EED1C6",
        "positionInBook": 519,
        "pageNumberInBook": "1.075",
        "lab": {
            "l": 86.22,
            "a": 10.03,
            "b": 9.38
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-1208 TPG",
        "name": "Peachy Keen",
        "rgb": {
            "r": 231,
            "g": 191,
            "b": 180
        },
        "hex": "E7BFB4",
        "positionInBook": 521,
        "pageNumberInBook": "1.075",
        "lab": {
            "l": 80.91,
            "a": 14.32,
            "b": 11.49
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-1311 TPG",
        "name": "Evening Sand",
        "rgb": {
            "r": 223,
            "g": 183,
            "b": 170
        },
        "hex": "DFB7AA",
        "positionInBook": 523,
        "pageNumberInBook": "1.075",
        "lab": {
            "l": 78.04,
            "a": 13.42,
            "b": 12.6
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-1316 TPG",
        "name": "Dusty Pink",
        "rgb": {
            "r": 224,
            "g": 174,
            "b": 158
        },
        "hex": "E0AE9E",
        "positionInBook": 525,
        "pageNumberInBook": "1.075",
        "lab": {
            "l": 75.68,
            "a": 18.64,
            "b": 16.04
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1432 TPG",
        "name": "Almond",
        "rgb": {
            "r": 169,
            "g": 122,
            "b": 85
        },
        "hex": "A97A55",
        "positionInBook": 551,
        "pageNumberInBook": "1.079",
        "lab": {
            "l": 55.8,
            "a": 15.96,
            "b": 28.79
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1137 TPG",
        "name": "Cashew",
        "rgb": {
            "r": 166,
            "g": 118,
            "b": 81
        },
        "hex": "A67651",
        "positionInBook": 553,
        "pageNumberInBook": "1.079",
        "lab": {
            "l": 54.46,
            "a": 16.27,
            "b": 29.29
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1340 TPG",
        "name": "Adobe",
        "rgb": {
            "r": 166,
            "g": 105,
            "b": 72
        },
        "hex": "A66948",
        "positionInBook": 555,
        "pageNumberInBook": "1.080",
        "lab": {
            "l": 51.13,
            "a": 23.17,
            "b": 30.03
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1052 TPG",
        "name": "Roasted Pecan",
        "rgb": {
            "r": 153,
            "g": 98,
            "b": 58
        },
        "hex": "99623A",
        "positionInBook": 557,
        "pageNumberInBook": "1.080",
        "lab": {
            "l": 47.51,
            "a": 21.03,
            "b": 32.97
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1142 TPG",
        "name": "Leather Brown",
        "rgb": {
            "r": 151,
            "g": 94,
            "b": 63
        },
        "hex": "975E3F",
        "positionInBook": 559,
        "pageNumberInBook": "1.080",
        "lab": {
            "l": 46.34,
            "a": 21.82,
            "b": 28.88
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1327 TPG",
        "name": "Peach Bloom",
        "rgb": {
            "r": 220,
            "g": 157,
            "b": 124
        },
        "hex": "DC9D7C",
        "positionInBook": 561,
        "pageNumberInBook": "1.081",
        "lab": {
            "l": 70.72,
            "a": 21.98,
            "b": 27.75
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1332 TPG",
        "name": "Pheasant",
        "rgb": {
            "r": 198,
            "g": 134,
            "b": 103
        },
        "hex": "C68667",
        "positionInBook": 563,
        "pageNumberInBook": "1.081",
        "lab": {
            "l": 62.57,
            "a": 23.9,
            "b": 27.93
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1429 TPG",
        "name": "Sunburn",
        "rgb": {
            "r": 179,
            "g": 116,
            "b": 89
        },
        "hex": "B37459",
        "positionInBook": 565,
        "pageNumberInBook": "1.081",
        "lab": {
            "l": 55.53,
            "a": 24.71,
            "b": 26.12
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1143 TPG",
        "name": "Hazel",
        "rgb": {
            "r": 175,
            "g": 119,
            "b": 87
        },
        "hex": "AF7757",
        "positionInBook": 567,
        "pageNumberInBook": "1.081",
        "lab": {
            "l": 55.79,
            "a": 19.88,
            "b": 27.66
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1532 TPG",
        "name": "Crabapple",
        "rgb": {
            "r": 213,
            "g": 124,
            "b": 111
        },
        "hex": "D57C6F",
        "positionInBook": 599,
        "pageNumberInBook": "1.086",
        "lab": {
            "l": 62.47,
            "a": 35.33,
            "b": 23.88
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1544 TPG",
        "name": "Burnt Sienna",
        "rgb": {
            "r": 196,
            "g": 97,
            "b": 87
        },
        "hex": "C46157",
        "positionInBook": 601,
        "pageNumberInBook": "1.086",
        "lab": {
            "l": 54.17,
            "a": 40.85,
            "b": 26.19
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1333 TPG",
        "name": "Canyon Sunset",
        "rgb": {
            "r": 226,
            "g": 149,
            "b": 122
        },
        "hex": "E2957A",
        "positionInBook": 603,
        "pageNumberInBook": "1.087",
        "lab": {
            "l": 69.7,
            "a": 29.36,
            "b": 27.35
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1435 TPG",
        "name": "Carnelian",
        "rgb": {
            "r": 209,
            "g": 124,
            "b": 98
        },
        "hex": "D17C62",
        "positionInBook": 605,
        "pageNumberInBook": "1.087",
        "lab": {
            "l": 61.75,
            "a": 33.41,
            "b": 29.91
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1441 TPG",
        "name": "Arabesque",
        "rgb": {
            "r": 208,
            "g": 113,
            "b": 88
        },
        "hex": "D07158",
        "positionInBook": 607,
        "pageNumberInBook": "1.087",
        "lab": {
            "l": 59.14,
            "a": 38.58,
            "b": 32.69
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1446 TPG",
        "name": "Mango",
        "rgb": {
            "r": 183,
            "g": 100,
            "b": 82
        },
        "hex": "B76452",
        "positionInBook": 609,
        "pageNumberInBook": "1.087",
        "lab": {
            "l": 52.65,
            "a": 34.66,
            "b": 26.6
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1529 TPG",
        "name": "Burnt Coral",
        "rgb": {
            "r": 236,
            "g": 139,
            "b": 131
        },
        "hex": "EC8B83",
        "positionInBook": 611,
        "pageNumberInBook": "1.088",
        "lab": {
            "l": 68.99,
            "a": 39.38,
            "b": 22.26
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1345 TPG",
        "name": "Spice Route",
        "rgb": {
            "r": 191,
            "g": 98,
            "b": 76
        },
        "hex": "BF624C",
        "positionInBook": 613,
        "pageNumberInBook": "1.088",
        "lab": {
            "l": 53.42,
            "a": 37.93,
            "b": 31.54
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1450 TPG",
        "name": "Flamingo",
        "rgb": {
            "r": 223,
            "g": 114,
            "b": 88
        },
        "hex": "DF7258",
        "positionInBook": 615,
        "pageNumberInBook": "1.088",
        "lab": {
            "l": 61.66,
            "a": 43.96,
            "b": 35.86
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1433 TPG",
        "name": "Papaya Punch",
        "rgb": {
            "r": 254,
            "g": 160,
            "b": 136
        },
        "hex": "FEA088",
        "positionInBook": 617,
        "pageNumberInBook": "1.089",
        "lab": {
            "l": 75.86,
            "a": 34.87,
            "b": 29.22
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1331 TPG",
        "name": "Coral Reef",
        "rgb": {
            "r": 249,
            "g": 164,
            "b": 130
        },
        "hex": "F9A482",
        "positionInBook": 619,
        "pageNumberInBook": "1.089",
        "lab": {
            "l": 76.02,
            "a": 31.02,
            "b": 32.32
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1340 TPG",
        "name": "Cadmium Orange",
        "rgb": {
            "r": 243,
            "g": 148,
            "b": 112
        },
        "hex": "F39470",
        "positionInBook": 621,
        "pageNumberInBook": "1.089",
        "lab": {
            "l": 71.62,
            "a": 36.02,
            "b": 36.05
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1442 TPG",
        "name": "Melon",
        "rgb": {
            "r": 246,
            "g": 138,
            "b": 106
        },
        "hex": "F68A6A",
        "positionInBook": 623,
        "pageNumberInBook": "1.089",
        "lab": {
            "l": 69.79,
            "a": 41.61,
            "b": 36.86
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0917 TPG",
        "name": "Bleached Apricot",
        "rgb": {
            "r": 252,
            "g": 207,
            "b": 174
        },
        "hex": "FCCFAE",
        "positionInBook": 625,
        "pageNumberInBook": "1.090",
        "lab": {
            "l": 86.8,
            "a": 12.98,
            "b": 23.55
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1443 TPG",
        "name": "Apricot Buff",
        "rgb": {
            "r": 208,
            "g": 128,
            "b": 79
        },
        "hex": "D0804F",
        "positionInBook": 651,
        "pageNumberInBook": "1.093",
        "lab": {
            "l": 62.23,
            "a": 29.1,
            "b": 40.89
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1348 TPG",
        "name": "Tomato Cream",
        "rgb": {
            "r": 198,
            "g": 121,
            "b": 76
        },
        "hex": "C6794C",
        "positionInBook": 653,
        "pageNumberInBook": "1.094",
        "lab": {
            "l": 59.18,
            "a": 29.57,
            "b": 38.44
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1448 TPG",
        "name": "Burnt Orange",
        "rgb": {
            "r": 201,
            "g": 105,
            "b": 57
        },
        "hex": "C96939",
        "positionInBook": 655,
        "pageNumberInBook": "1.094",
        "lab": {
            "l": 56.19,
            "a": 37.12,
            "b": 45.48
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1353 TPG",
        "name": "Apricot Orange",
        "rgb": {
            "r": 196,
            "g": 108,
            "b": 70
        },
        "hex": "C46C46",
        "positionInBook": 657,
        "pageNumberInBook": "1.094",
        "lab": {
            "l": 56,
            "a": 34.07,
            "b": 38.36
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1242 TPG",
        "name": "Muskmelon",
        "rgb": {
            "r": 242,
            "g": 152,
            "b": 100
        },
        "hex": "F29864",
        "positionInBook": 659,
        "pageNumberInBook": "1.095",
        "lab": {
            "l": 72.14,
            "a": 31.69,
            "b": 43.28
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1253 TPG",
        "name": "Orange Ochre",
        "rgb": {
            "r": 223,
            "g": 128,
            "b": 69
        },
        "hex": "DF8045",
        "positionInBook": 661,
        "pageNumberInBook": "1.095",
        "lab": {
            "l": 64.15,
            "a": 34.8,
            "b": 49.1
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1145 TPG",
        "name": "Autumn Maple",
        "rgb": {
            "r": 201,
            "g": 108,
            "b": 50
        },
        "hex": "C96C32",
        "positionInBook": 663,
        "pageNumberInBook": "1.095",
        "lab": {
            "l": 56.57,
            "a": 35.43,
            "b": 49.37
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1249 TPG",
        "name": "Hawaiian Sunset",
        "rgb": {
            "r": 189,
            "g": 101,
            "b": 55
        },
        "hex": "BD6537",
        "positionInBook": 665,
        "pageNumberInBook": "1.095",
        "lab": {
            "l": 53.44,
            "a": 33.99,
            "b": 42.69
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1243 TPG",
        "name": "Papaya",
        "rgb": {
            "r": 255,
            "g": 162,
            "b": 105
        },
        "hex": "FFA269",
        "positionInBook": 667,
        "pageNumberInBook": "1.096",
        "lab": {
            "l": 77.19,
            "a": 35.62,
            "b": 46.88
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1363 TPG",
        "name": "Puffin's Bill",
        "rgb": {
            "r": 230,
            "g": 97,
            "b": 50
        },
        "hex": "E66132",
        "positionInBook": 699,
        "pageNumberInBook": "1.100",
        "lab": {
            "l": 59.3,
            "a": 52.15,
            "b": 53.9
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1362 TPG",
        "name": "Vermillion Orange",
        "rgb": {
            "r": 252,
            "g": 105,
            "b": 63
        },
        "hex": "FC693F",
        "positionInBook": 701,
        "pageNumberInBook": "1.101",
        "lab": {
            "l": 64.48,
            "a": 57.11,
            "b": 53.74
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1452 TPG",
        "name": "Koi",
        "rgb": {
            "r": 208,
            "g": 95,
            "b": 68
        },
        "hex": "D05F44",
        "positionInBook": 703,
        "pageNumberInBook": "1.101",
        "lab": {
            "l": 55.42,
            "a": 45.61,
            "b": 38.98
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1461 TPG",
        "name": "Orangeade",
        "rgb": {
            "r": 229,
            "g": 93,
            "b": 59
        },
        "hex": "E55D3B",
        "positionInBook": 705,
        "pageNumberInBook": "1.101",
        "lab": {
            "l": 58.58,
            "a": 54.2,
            "b": 48.34
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1463 TPG",
        "name": "Tangerine Tango",
        "rgb": {
            "r": 228,
            "g": 79,
            "b": 51
        },
        "hex": "E44F33",
        "positionInBook": 707,
        "pageNumberInBook": "1.101",
        "lab": {
            "l": 56.33,
            "a": 59.46,
            "b": 50.3
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1450 TPG",
        "name": "Summer Fig",
        "rgb": {
            "r": 195,
            "g": 84,
            "b": 72
        },
        "hex": "C35448",
        "positionInBook": 709,
        "pageNumberInBook": "1.102",
        "lab": {
            "l": 51.38,
            "a": 46.55,
            "b": 31.75
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1447 TPG",
        "name": "Orange Rust",
        "rgb": {
            "r": 195,
            "g": 99,
            "b": 79
        },
        "hex": "C3634F",
        "positionInBook": 711,
        "pageNumberInBook": "1.102",
        "lab": {
            "l": 54.18,
            "a": 39.57,
            "b": 30.76
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1454 TPG",
        "name": "Red Clay",
        "rgb": {
            "r": 193,
            "g": 80,
            "b": 68
        },
        "hex": "C15044",
        "positionInBook": 713,
        "pageNumberInBook": "1.102",
        "lab": {
            "l": 50.38,
            "a": 47.2,
            "b": 32.61
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1445 TPG",
        "name": "Spicy Orange",
        "rgb": {
            "r": 221,
            "g": 82,
            "b": 63
        },
        "hex": "DD523F",
        "positionInBook": 715,
        "pageNumberInBook": "1.103",
        "lab": {
            "l": 55.54,
            "a": 56.25,
            "b": 42.61
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1561 TPG",
        "name": "Orange.com",
        "rgb": {
            "r": 216,
            "g": 69,
            "b": 57
        },
        "hex": "D84539",
        "positionInBook": 717,
        "pageNumberInBook": "1.103",
        "lab": {
            "l": 52.73,
            "a": 60.15,
            "b": 42.74
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1564 TPG",
        "name": "Fiesta",
        "rgb": {
            "r": 222,
            "g": 77,
            "b": 68
        },
        "hex": "DE4D44",
        "positionInBook": 719,
        "pageNumberInBook": "1.103",
        "lab": {
            "l": 55.02,
            "a": 59.27,
            "b": 39.5
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1550 TPG",
        "name": "Aurora Red",
        "rgb": {
            "r": 189,
            "g": 72,
            "b": 72
        },
        "hex": "BD4848",
        "positionInBook": 721,
        "pageNumberInBook": "1.103",
        "lab": {
            "l": 48.34,
            "a": 50.14,
            "b": 27.59
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1541 TPG",
        "name": "Camellia",
        "rgb": {
            "r": 248,
            "g": 119,
            "b": 102
        },
        "hex": "F87766",
        "positionInBook": 723,
        "pageNumberInBook": "1.104",
        "lab": {
            "l": 66.43,
            "a": 51.02,
            "b": 35.52
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1451 TPG",
        "name": "Nasturtium",
        "rgb": {
            "r": 249,
            "g": 106,
            "b": 81
        },
        "hex": "F96A51",
        "positionInBook": 725,
        "pageNumberInBook": "1.104",
        "lab": {
            "l": 64.3,
            "a": 56.32,
            "b": 44.18
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1663 TPG",
        "name": "Bittersweet",
        "rgb": {
            "r": 216,
            "g": 70,
            "b": 83
        },
        "hex": "D84653",
        "positionInBook": 751,
        "pageNumberInBook": "1.108",
        "lab": {
            "l": 53.14,
            "a": 60.66,
            "b": 28.52
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1660 TPG",
        "name": "Tomato",
        "rgb": {
            "r": 206,
            "g": 56,
            "b": 70
        },
        "hex": "CE3846",
        "positionInBook": 753,
        "pageNumberInBook": "1.108",
        "lab": {
            "l": 49.28,
            "a": 62.05,
            "b": 30.91
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1760 TPG",
        "name": "Scarlet",
        "rgb": {
            "r": 180,
            "g": 59,
            "b": 76
        },
        "hex": "B43B4C",
        "positionInBook": 755,
        "pageNumberInBook": "1.108",
        "lab": {
            "l": 44.64,
            "a": 52.77,
            "b": 20.2
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1763 TPG",
        "name": "High Risk Red",
        "rgb": {
            "r": 204,
            "g": 51,
            "b": 61
        },
        "hex": "CC333D",
        "positionInBook": 757,
        "pageNumberInBook": "1.109",
        "lab": {
            "l": 48.13,
            "a": 63.01,
            "b": 34.6
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1657 TPG",
        "name": "Salsa",
        "rgb": {
            "r": 172,
            "g": 48,
            "b": 62
        },
        "hex": "AC303E",
        "positionInBook": 759,
        "pageNumberInBook": "1.109",
        "lab": {
            "l": 41.46,
            "a": 53.99,
            "b": 24.37
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1659 TPG",
        "name": "Goji Berry",
        "rgb": {
            "r": 184,
            "g": 51,
            "b": 62
        },
        "hex": "B8333E",
        "positionInBook": 761,
        "pageNumberInBook": "1.109",
        "lab": {
            "l": 44.28,
            "a": 56.92,
            "b": 28.24
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1758 TPG",
        "name": "Haute Red",
        "rgb": {
            "r": 164,
            "g": 47,
            "b": 59
        },
        "hex": "A42F3B",
        "positionInBook": 763,
        "pageNumberInBook": "1.109",
        "lab": {
            "l": 39.72,
            "a": 51.87,
            "b": 23.65
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1563 TPG",
        "name": "Rubicondo",
        "rgb": {
            "r": 195,
            "g": 61,
            "b": 65
        },
        "hex": "C33D41",
        "positionInBook": 765,
        "pageNumberInBook": "1.110",
        "lab": {
            "l": 47.65,
            "a": 56.57,
            "b": 30.98
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1553 TPG",
        "name": "Adrenaline Rush",
        "rgb": {
            "r": 183,
            "g": 52,
            "b": 56
        },
        "hex": "B73438",
        "positionInBook": 767,
        "pageNumberInBook": "1.110",
        "lab": {
            "l": 44.07,
            "a": 56.02,
            "b": 31.72
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1650 TPG",
        "name": "Biking Red",
        "rgb": {
            "r": 128,
            "g": 61,
            "b": 69
        },
        "hex": "803D45",
        "positionInBook": 799,
        "pageNumberInBook": "1.115",
        "lab": {
            "l": 35.6,
            "a": 31.64,
            "b": 10.37
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1534 TPG",
        "name": "Merlot",
        "rgb": {
            "r": 121,
            "g": 57,
            "b": 62
        },
        "hex": "79393E",
        "positionInBook": 801,
        "pageNumberInBook": "1.115",
        "lab": {
            "l": 33.52,
            "a": 30.82,
            "b": 11.46
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1531 TPG",
        "name": "Sun-Dried Tomato",
        "rgb": {
            "r": 128,
            "g": 58,
            "b": 64
        },
        "hex": "803A40",
        "positionInBook": 803,
        "pageNumberInBook": "1.115",
        "lab": {
            "l": 34.82,
            "a": 32.95,
            "b": 12.29
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1327 TPG",
        "name": "Andorra",
        "rgb": {
            "r": 105,
            "g": 69,
            "b": 70
        },
        "hex": "694546",
        "positionInBook": 805,
        "pageNumberInBook": "1.115",
        "lab": {
            "l": 33.81,
            "a": 17.1,
            "b": 6.55
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1536 TPG",
        "name": "Red Pear",
        "rgb": {
            "r": 128,
            "g": 65,
            "b": 69
        },
        "hex": "804145",
        "positionInBook": 807,
        "pageNumberInBook": "1.116",
        "lab": {
            "l": 36.38,
            "a": 29.47,
            "b": 11.39
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1726 TPG",
        "name": "Cordovan",
        "rgb": {
            "r": 111,
            "g": 64,
            "b": 72
        },
        "hex": "6F4048",
        "positionInBook": 809,
        "pageNumberInBook": "1.116",
        "lab": {
            "l": 33.5,
            "a": 23.02,
            "b": 4.92
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1525 TPG",
        "name": "Port",
        "rgb": {
            "r": 109,
            "g": 69,
            "b": 73
        },
        "hex": "6D4549",
        "positionInBook": 811,
        "pageNumberInBook": "1.116",
        "lab": {
            "l": 34.57,
            "a": 18.89,
            "b": 5.97
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1533 TPG",
        "name": "Cowhide",
        "rgb": {
            "r": 139,
            "g": 82,
            "b": 83
        },
        "hex": "8B5253",
        "positionInBook": 813,
        "pageNumberInBook": "1.117",
        "lab": {
            "l": 42.02,
            "a": 25.56,
            "b": 10.95
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1524 TPG",
        "name": "Oxblood Red",
        "rgb": {
            "r": 117,
            "g": 74,
            "b": 78
        },
        "hex": "754A4E",
        "positionInBook": 815,
        "pageNumberInBook": "1.117",
        "lab": {
            "l": 36.98,
            "a": 19.99,
            "b": 6.38
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1522 TPG",
        "name": "Zinfandel",
        "rgb": {
            "r": 102,
            "g": 63,
            "b": 69
        },
        "hex": "663F45",
        "positionInBook": 817,
        "pageNumberInBook": "1.117",
        "lab": {
            "l": 31.9,
            "a": 18.96,
            "b": 4.31
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1526 TPG",
        "name": "Chocolate Truffle",
        "rgb": {
            "r": 103,
            "g": 66,
            "b": 72
        },
        "hex": "674248",
        "positionInBook": 819,
        "pageNumberInBook": "1.117",
        "lab": {
            "l": 32.85,
            "a": 17.47,
            "b": 4.08
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1543 TPG",
        "name": "Brick Red",
        "rgb": {
            "r": 145,
            "g": 72,
            "b": 77
        },
        "hex": "91484D",
        "positionInBook": 821,
        "pageNumberInBook": "1.118",
        "lab": {
            "l": 40.68,
            "a": 33.04,
            "b": 12.92
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1656 TPG",
        "name": "Rio Red",
        "rgb": {
            "r": 142,
            "g": 56,
            "b": 65
        },
        "hex": "8E3841",
        "positionInBook": 823,
        "pageNumberInBook": "1.118",
        "lab": {
            "l": 36.96,
            "a": 40.11,
            "b": 15.02
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1840 TPG",
        "name": "Deep Claret",
        "rgb": {
            "r": 156,
            "g": 65,
            "b": 80
        },
        "hex": "9C4150",
        "positionInBook": 825,
        "pageNumberInBook": "1.118",
        "lab": {
            "l": 41.36,
            "a": 41.12,
            "b": 12.22
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1514 TPG",
        "name": "Ash Rose",
        "rgb": {
            "r": 178,
            "g": 130,
            "b": 130
        },
        "hex": "B28282",
        "positionInBook": 851,
        "pageNumberInBook": "1.122",
        "lab": {
            "l": 59.46,
            "a": 20.22,
            "b": 8.07
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1518 TPG",
        "name": "Old Rose",
        "rgb": {
            "r": 177,
            "g": 125,
            "b": 123
        },
        "hex": "B17D7B",
        "positionInBook": 853,
        "pageNumberInBook": "1.122",
        "lab": {
            "l": 58.12,
            "a": 22.04,
            "b": 10.05
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-1506 TPG",
        "name": "Rose Smoke",
        "rgb": {
            "r": 214,
            "g": 185,
            "b": 178
        },
        "hex": "D6B9B2",
        "positionInBook": 855,
        "pageNumberInBook": "1.123",
        "lab": {
            "l": 77.84,
            "a": 10.42,
            "b": 8.31
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1512 TPG",
        "name": "Misty Rose",
        "rgb": {
            "r": 205,
            "g": 168,
            "b": 160
        },
        "hex": "CDA8A0",
        "positionInBook": 857,
        "pageNumberInBook": "1.123",
        "lab": {
            "l": 72.16,
            "a": 14.01,
            "b": 9.64
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1516 TPG",
        "name": "Cameo Brown",
        "rgb": {
            "r": 191,
            "g": 139,
            "b": 132
        },
        "hex": "BF8B84",
        "positionInBook": 859,
        "pageNumberInBook": "1.123",
        "lab": {
            "l": 63.19,
            "a": 20.87,
            "b": 12.48
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1426 TPG",
        "name": "Apple Butter",
        "rgb": {
            "r": 136,
            "g": 85,
            "b": 88
        },
        "hex": "885558",
        "positionInBook": 861,
        "pageNumberInBook": "1.123",
        "lab": {
            "l": 42.46,
            "a": 23.25,
            "b": 8.29
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1518 TPG",
        "name": "Rosette",
        "rgb": {
            "r": 203,
            "g": 138,
            "b": 134
        },
        "hex": "CB8A86",
        "positionInBook": 863,
        "pageNumberInBook": "1.124",
        "lab": {
            "l": 64.5,
            "a": 25.91,
            "b": 13.53
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1524 TPG",
        "name": "Desert Sand",
        "rgb": {
            "r": 186,
            "g": 125,
            "b": 119
        },
        "hex": "BA7D77",
        "positionInBook": 865,
        "pageNumberInBook": "1.124",
        "lab": {
            "l": 59.14,
            "a": 25.42,
            "b": 14.31
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1525 TPG",
        "name": "Cedar Wood",
        "rgb": {
            "r": 162,
            "g": 110,
            "b": 100
        },
        "hex": "A26E64",
        "positionInBook": 867,
        "pageNumberInBook": "1.124",
        "lab": {
            "l": 52.19,
            "a": 21.47,
            "b": 14.79
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-1706 TPG",
        "name": "Pink Dogwood",
        "rgb": {
            "r": 245,
            "g": 211,
            "b": 212
        },
        "hex": "F5D3D4",
        "positionInBook": 899,
        "pageNumberInBook": "1.129",
        "lab": {
            "l": 87.67,
            "a": 12.75,
            "b": 4.61
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-1907 TPG",
        "name": "Peachskin",
        "rgb": {
            "r": 225,
            "g": 184,
            "b": 185
        },
        "hex": "E1B8B9",
        "positionInBook": 901,
        "pageNumberInBook": "1.129",
        "lab": {
            "l": 78.75,
            "a": 16.36,
            "b": 5.57
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-1904 TPG",
        "name": "Pink Dolphin",
        "rgb": {
            "r": 235,
            "g": 176,
            "b": 184
        },
        "hex": "EBB0B8",
        "positionInBook": 903,
        "pageNumberInBook": "1.129",
        "lab": {
            "l": 78,
            "a": 24.01,
            "b": 5.22
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-1305 TPG",
        "name": "Heavenly Pink",
        "rgb": {
            "r": 243,
            "g": 222,
            "b": 223
        },
        "hex": "F3DEDF",
        "positionInBook": 905,
        "pageNumberInBook": "1.130",
        "lab": {
            "l": 90.32,
            "a": 7.8,
            "b": 2.43
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-2102 TPG",
        "name": "Mauve Morn",
        "rgb": {
            "r": 234,
            "g": 217,
            "b": 216
        },
        "hex": "EAD9D8",
        "positionInBook": 907,
        "pageNumberInBook": "1.130",
        "lab": {
            "l": 88.31,
            "a": 5.89,
            "b": 2.86
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-1905 TPG",
        "name": "Lotus",
        "rgb": {
            "r": 227,
            "g": 193,
            "b": 193
        },
        "hex": "E3C1C1",
        "positionInBook": 909,
        "pageNumberInBook": "1.130",
        "lab": {
            "l": 81.31,
            "a": 12.92,
            "b": 5.35
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-1310 TPG",
        "name": "Icy Pink",
        "rgb": {
            "r": 245,
            "g": 211,
            "b": 219
        },
        "hex": "F5D3DB",
        "positionInBook": 911,
        "pageNumberInBook": "1.131",
        "lab": {
            "l": 87.74,
            "a": 13.83,
            "b": 1.01
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-2902 TPG",
        "name": "Mauve Chalk",
        "rgb": {
            "r": 227,
            "g": 210,
            "b": 207
        },
        "hex": "E3D2CF",
        "positionInBook": 913,
        "pageNumberInBook": "1.131",
        "lab": {
            "l": 85.76,
            "a": 5.81,
            "b": 3.85
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-2803 TPG",
        "name": "Pale Lilac",
        "rgb": {
            "r": 227,
            "g": 201,
            "b": 206
        },
        "hex": "E3C9CE",
        "positionInBook": 915,
        "pageNumberInBook": "1.131",
        "lab": {
            "l": 83.47,
            "a": 9.94,
            "b": 1.39
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-1808 TPG",
        "name": "Pinkesque",
        "rgb": {
            "r": 245,
            "g": 203,
            "b": 209
        },
        "hex": "F5CBD1",
        "positionInBook": 917,
        "pageNumberInBook": "1.131",
        "lab": {
            "l": 85.87,
            "a": 16.49,
            "b": 3.53
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-2808 TPG",
        "name": "Ballet Slipper",
        "rgb": {
            "r": 240,
            "g": 209,
            "b": 216
        },
        "hex": "F0D1D8",
        "positionInBook": 919,
        "pageNumberInBook": "1.132",
        "lab": {
            "l": 86.87,
            "a": 12.16,
            "b": 1.23
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-1906 TPG",
        "name": "Rose Shadow",
        "rgb": {
            "r": 247,
            "g": 194,
            "b": 208
        },
        "hex": "F7C2D0",
        "positionInBook": 921,
        "pageNumberInBook": "1.132",
        "lab": {
            "l": 83.9,
            "a": 21.5,
            "b": 1.47
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-2010 TPG",
        "name": "Orchid Pink",
        "rgb": {
            "r": 241,
            "g": 188,
            "b": 202
        },
        "hex": "F1BCCA",
        "positionInBook": 923,
        "pageNumberInBook": "1.132",
        "lab": {
            "l": 81.79,
            "a": 21.58,
            "b": 1.22
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "11-2511 TPG",
        "name": "Shrinking Violet",
        "rgb": {
            "r": 240,
            "g": 221,
            "b": 225
        },
        "hex": "F0DDE1",
        "positionInBook": 925,
        "pageNumberInBook": "1.133",
        "lab": {
            "l": 89.91,
            "a": 7.42,
            "b": 0.85
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-2118 TPG",
        "name": "Pink Power",
        "rgb": {
            "r": 214,
            "g": 109,
            "b": 151
        },
        "hex": "D66D97",
        "positionInBook": 951,
        "pageNumberInBook": "1.136",
        "lab": {
            "l": 60.47,
            "a": 47.13,
            "b": -1.46
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1915 TPG",
        "name": "Flowering Ginger",
        "rgb": {
            "r": 232,
            "g": 125,
            "b": 152
        },
        "hex": "E87D98",
        "positionInBook": 953,
        "pageNumberInBook": "1.137",
        "lab": {
            "l": 66.15,
            "a": 45.66,
            "b": 6.58
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1731 TPG",
        "name": "Strawberry Pink",
        "rgb": {
            "r": 248,
            "g": 129,
            "b": 146
        },
        "hex": "F88192",
        "positionInBook": 955,
        "pageNumberInBook": "1.137",
        "lab": {
            "l": 69.02,
            "a": 48.39,
            "b": 14.35
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1930 TPG",
        "name": "Camellia Rose",
        "rgb": {
            "r": 241,
            "g": 105,
            "b": 135
        },
        "hex": "F16987",
        "positionInBook": 957,
        "pageNumberInBook": "1.137",
        "lab": {
            "l": 63.53,
            "a": 56.15,
            "b": 13.64
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1739 TPG",
        "name": "Rethink Pink",
        "rgb": {
            "r": 202,
            "g": 69,
            "b": 100
        },
        "hex": "CA4564",
        "positionInBook": 959,
        "pageNumberInBook": "1.137",
        "lab": {
            "l": 50.74,
            "a": 57.45,
            "b": 14.49
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1735 TPG",
        "name": "Pink Lemonade",
        "rgb": {
            "r": 250,
            "g": 123,
            "b": 149
        },
        "hex": "FA7B95",
        "positionInBook": 961,
        "pageNumberInBook": "1.138",
        "lab": {
            "l": 68.25,
            "a": 51.68,
            "b": 12.27
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1744 TPG",
        "name": "Calypso Coral",
        "rgb": {
            "r": 238,
            "g": 103,
            "b": 121
        },
        "hex": "EE6779",
        "positionInBook": 963,
        "pageNumberInBook": "1.138",
        "lab": {
            "l": 62.5,
            "a": 55.46,
            "b": 19.35
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1665 TPG",
        "name": "Watermelon",
        "rgb": {
            "r": 204,
            "g": 67,
            "b": 88
        },
        "hex": "CC4358",
        "positionInBook": 965,
        "pageNumberInBook": "1.138",
        "lab": {
            "l": 50.53,
            "a": 57.82,
            "b": 21.94
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1822 TPG",
        "name": "Plumeria",
        "rgb": {
            "r": 248,
            "g": 145,
            "b": 163
        },
        "hex": "F891A3",
        "positionInBook": 967,
        "pageNumberInBook": "1.139",
        "lab": {
            "l": 72.52,
            "a": 42.14,
            "b": 9.86
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-2033 TPG",
        "name": "Fandango Pink",
        "rgb": {
            "r": 221,
            "g": 91,
            "b": 134
        },
        "hex": "DD5B86",
        "positionInBook": 999,
        "pageNumberInBook": "1.143",
        "lab": {
            "l": 58.03,
            "a": 55.69,
            "b": 5.67
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1831 TPG",
        "name": "Carmine",
        "rgb": {
            "r": 187,
            "g": 80,
            "b": 114
        },
        "hex": "BB5072",
        "positionInBook": 1001,
        "pageNumberInBook": "1.143",
        "lab": {
            "l": 50.02,
            "a": 48.62,
            "b": 4.71
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-2216 TPG",
        "name": "Sachet Pink",
        "rgb": {
            "r": 243,
            "g": 140,
            "b": 177
        },
        "hex": "F38CB1",
        "positionInBook": 1003,
        "pageNumberInBook": "1.144",
        "lab": {
            "l": 71.08,
            "a": 43.66,
            "b": 0.37
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-2124 TPG",
        "name": "Pink Carnation",
        "rgb": {
            "r": 234,
            "g": 126,
            "b": 164
        },
        "hex": "EA7EA4",
        "positionInBook": 1005,
        "pageNumberInBook": "1.144",
        "lab": {
            "l": 66.87,
            "a": 46.73,
            "b": 1.11
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-2120 TPG",
        "name": "Chateau Rose",
        "rgb": {
            "r": 213,
            "g": 120,
            "b": 152
        },
        "hex": "D57898",
        "positionInBook": 1007,
        "pageNumberInBook": "1.144",
        "lab": {
            "l": 62.4,
            "a": 41.28,
            "b": 0.68
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-2215 TPG",
        "name": "Begonia Pink",
        "rgb": {
            "r": 232,
            "g": 154,
            "b": 188
        },
        "hex": "E89ABC",
        "positionInBook": 1009,
        "pageNumberInBook": "1.145",
        "lab": {
            "l": 72.91,
            "a": 34.6,
            "b": -3.72
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-2718 TPG",
        "name": "Fuchsia Pink",
        "rgb": {
            "r": 224,
            "g": 140,
            "b": 185
        },
        "hex": "E08CB9",
        "positionInBook": 1011,
        "pageNumberInBook": "1.145",
        "lab": {
            "l": 68.88,
            "a": 38.48,
            "b": -8.02
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-2227 TPG",
        "name": "Lilac Rose",
        "rgb": {
            "r": 190,
            "g": 76,
            "b": 125
        },
        "hex": "BE4C7D",
        "positionInBook": 1013,
        "pageNumberInBook": "1.145",
        "lab": {
            "l": 50.29,
            "a": 52.07,
            "b": -1.08
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-2031 TPG",
        "name": "Fuchsia Rose",
        "rgb": {
            "r": 195,
            "g": 78,
            "b": 124
        },
        "hex": "C34E7C",
        "positionInBook": 1015,
        "pageNumberInBook": "1.145",
        "lab": {
            "l": 51.42,
            "a": 52.92,
            "b": 1.04
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-2235 TPG",
        "name": "Sangria Sunset",
        "rgb": {
            "r": 230,
            "g": 102,
            "b": 147
        },
        "hex": "E66693",
        "positionInBook": 1017,
        "pageNumberInBook": "1.146",
        "lab": {
            "l": 61.57,
            "a": 54.81,
            "b": 2.99
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-2034 TPG",
        "name": "Pink Yarrow",
        "rgb": {
            "r": 206,
            "g": 74,
            "b": 126
        },
        "hex": "CE4A7E",
        "positionInBook": 1019,
        "pageNumberInBook": "1.146",
        "lab": {
            "l": 52.65,
            "a": 57.48,
            "b": 2.43
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-2143 TPG",
        "name": "Beetroot Purple",
        "rgb": {
            "r": 202,
            "g": 66,
            "b": 115
        },
        "hex": "CA4273",
        "positionInBook": 1021,
        "pageNumberInBook": "1.146",
        "lab": {
            "l": 50.62,
            "a": 59.19,
            "b": 5.92
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-2619 TPG",
        "name": "Strawberry Moon",
        "rgb": {
            "r": 221,
            "g": 114,
            "b": 167
        },
        "hex": "DD72A7",
        "positionInBook": 1023,
        "pageNumberInBook": "1.147",
        "lab": {
            "l": 62.88,
            "a": 48.29,
            "b": -6.84
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-2521 TPG",
        "name": "Cone Flower",
        "rgb": {
            "r": 193,
            "g": 91,
            "b": 137
        },
        "hex": "C15B89",
        "positionInBook": 1025,
        "pageNumberInBook": "1.147",
        "lab": {
            "l": 53.54,
            "a": 46,
            "b": -3.42
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3224 TPG",
        "name": "Radiant Orchid",
        "rgb": {
            "r": 176,
            "g": 103,
            "b": 161
        },
        "hex": "B067A1",
        "positionInBook": 1051,
        "pageNumberInBook": "1.151",
        "lab": {
            "l": 54.12,
            "a": 36.82,
            "b": -17.04
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3324 TPG",
        "name": "Dahlia",
        "rgb": {
            "r": 138,
            "g": 78,
            "b": 133
        },
        "hex": "8A4E85",
        "positionInBook": 1053,
        "pageNumberInBook": "1.151",
        "lab": {
            "l": 42.53,
            "a": 32.8,
            "b": -18.68
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3138 TPG",
        "name": "Byzantium",
        "rgb": {
            "r": 140,
            "g": 77,
            "b": 131
        },
        "hex": "8C4D83",
        "positionInBook": 1055,
        "pageNumberInBook": "1.151",
        "lab": {
            "l": 42.49,
            "a": 34.07,
            "b": -17.37
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3022 TPG",
        "name": "Gloxinia",
        "rgb": {
            "r": 104,
            "g": 67,
            "b": 99
        },
        "hex": "684363",
        "positionInBook": 1057,
        "pageNumberInBook": "1.151",
        "lab": {
            "l": 34.08,
            "a": 20.65,
            "b": -10.97
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-3323 TPG",
        "name": "Iris Orchid",
        "rgb": {
            "r": 169,
            "g": 107,
            "b": 166
        },
        "hex": "A96BA6",
        "positionInBook": 1059,
        "pageNumberInBook": "1.152",
        "lab": {
            "l": 54.05,
            "a": 32.87,
            "b": -20.52
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3025 TPG",
        "name": "Striking Purple",
        "rgb": {
            "r": 153,
            "g": 89,
            "b": 143
        },
        "hex": "99598F",
        "positionInBook": 1061,
        "pageNumberInBook": "1.152",
        "lab": {
            "l": 47.26,
            "a": 33.64,
            "b": -17.1
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3230 TPG",
        "name": "Grape Juice",
        "rgb": {
            "r": 114,
            "g": 65,
            "b": 102
        },
        "hex": "724166",
        "positionInBook": 1063,
        "pageNumberInBook": "1.152",
        "lab": {
            "l": 34.94,
            "a": 26.98,
            "b": -11.29
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-3115 TPG",
        "name": "Crocus",
        "rgb": {
            "r": 195,
            "g": 131,
            "b": 176
        },
        "hex": "C383B0",
        "positionInBook": 1065,
        "pageNumberInBook": "1.153",
        "lab": {
            "l": 62.94,
            "a": 31.3,
            "b": -12.23
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-3240 TPG",
        "name": "Bodacious",
        "rgb": {
            "r": 182,
            "g": 108,
            "b": 162
        },
        "hex": "B66CA2",
        "positionInBook": 1067,
        "pageNumberInBook": "1.153",
        "lab": {
            "l": 55.93,
            "a": 36.79,
            "b": -14.79
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1610 TPG",
        "name": "Dusky Orchid",
        "rgb": {
            "r": 156,
            "g": 121,
            "b": 137
        },
        "hex": "9C7989",
        "positionInBook": 1099,
        "pageNumberInBook": "1.157",
        "lab": {
            "l": 54.88,
            "a": 16.6,
            "b": -2.52
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1614 TPG",
        "name": "Deco Rose",
        "rgb": {
            "r": 152,
            "g": 96,
            "b": 107
        },
        "hex": "98606B",
        "positionInBook": 1101,
        "pageNumberInBook": "1.158",
        "lab": {
            "l": 47.77,
            "a": 25.44,
            "b": 4.46
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1718 TPG",
        "name": "Hawthorn Rose",
        "rgb": {
            "r": 135,
            "g": 86,
            "b": 102
        },
        "hex": "875666",
        "positionInBook": 1103,
        "pageNumberInBook": "1.158",
        "lab": {
            "l": 42.94,
            "a": 23.26,
            "b": 0.21
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1619 TPG",
        "name": "Maroon",
        "rgb": {
            "r": 136,
            "g": 85,
            "b": 97
        },
        "hex": "885561",
        "positionInBook": 1105,
        "pageNumberInBook": "1.158",
        "lab": {
            "l": 42.57,
            "a": 24.22,
            "b": 3.09
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1623 TPG",
        "name": "Rose Wine",
        "rgb": {
            "r": 167,
            "g": 98,
            "b": 119
        },
        "hex": "A76277",
        "positionInBook": 1107,
        "pageNumberInBook": "1.159",
        "lab": {
            "l": 50.46,
            "a": 32.18,
            "b": 1.7
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1723 TPG",
        "name": "Malaga",
        "rgb": {
            "r": 164,
            "g": 88,
            "b": 114
        },
        "hex": "A45872",
        "positionInBook": 1109,
        "pageNumberInBook": "1.159",
        "lab": {
            "l": 48.02,
            "a": 35.78,
            "b": 0.83
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-2030 TPG",
        "name": "Beet Red",
        "rgb": {
            "r": 131,
            "g": 57,
            "b": 79
        },
        "hex": "83394F",
        "positionInBook": 1111,
        "pageNumberInBook": "1.159",
        "lab": {
            "l": 35.63,
            "a": 35.94,
            "b": 4.51
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-2024 TPG",
        "name": "Rhododendron",
        "rgb": {
            "r": 122,
            "g": 64,
            "b": 79
        },
        "hex": "7A404F",
        "positionInBook": 1113,
        "pageNumberInBook": "1.159",
        "lab": {
            "l": 35.26,
            "a": 28.34,
            "b": 3.64
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1512 TPG",
        "name": "Nostalgia Rose",
        "rgb": {
            "r": 165,
            "g": 125,
            "b": 133
        },
        "hex": "A57D85",
        "positionInBook": 1115,
        "pageNumberInBook": "1.16",
        "lab": {
            "l": 56.92,
            "a": 17.48,
            "b": 2.64
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1613 TPG",
        "name": "Renaissance Rose",
        "rgb": {
            "r": 135,
            "g": 87,
            "b": 98
        },
        "hex": "875762",
        "positionInBook": 1117,
        "pageNumberInBook": "1.16",
        "lab": {
            "l": 43.01,
            "a": 21.98,
            "b": 2.75
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1616 TPG",
        "name": "Roan Rouge",
        "rgb": {
            "r": 138,
            "g": 89,
            "b": 95
        },
        "hex": "8A595F",
        "positionInBook": 1119,
        "pageNumberInBook": "1.16",
        "lab": {
            "l": 43.85,
            "a": 22.02,
            "b": 5.84
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1905 TPG",
        "name": "Burnished Lilac",
        "rgb": {
            "r": 195,
            "g": 174,
            "b": 179
        },
        "hex": "C3AEB3",
        "positionInBook": 1121,
        "pageNumberInBook": "1.161",
        "lab": {
            "l": 73.07,
            "a": 9.21,
            "b": 0.47
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-2205 TPG",
        "name": "Dawn Pink",
        "rgb": {
            "r": 193,
            "g": 164,
            "b": 178
        },
        "hex": "C1A4B2",
        "positionInBook": 1123,
        "pageNumberInBook": "1.161",
        "lab": {
            "l": 70.35,
            "a": 13.41,
            "b": -3.18
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1605 TPG",
        "name": "Elderberry",
        "rgb": {
            "r": 156,
            "g": 134,
            "b": 144
        },
        "hex": "9C8690",
        "positionInBook": 1125,
        "pageNumberInBook": "1.161",
        "lab": {
            "l": 58.41,
            "a": 10,
            "b": -1.99
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1705 TPG",
        "name": "Arctic Dusk",
        "rgb": {
            "r": 121,
            "g": 100,
            "b": 114
        },
        "hex": "796472",
        "positionInBook": 1151,
        "pageNumberInBook": "1.165",
        "lab": {
            "l": 44.84,
            "a": 11.18,
            "b": -4.58
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1708 TPG",
        "name": "Ephemera",
        "rgb": {
            "r": 119,
            "g": 99,
            "b": 110
        },
        "hex": "77636E",
        "positionInBook": 1153,
        "pageNumberInBook": "1.165",
        "lab": {
            "l": 44.38,
            "a": 10.27,
            "b": -2.75
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3316 TPG",
        "name": "Plum Perfect",
        "rgb": {
            "r": 82,
            "g": 70,
            "b": 81
        },
        "hex": "524651",
        "positionInBook": 1155,
        "pageNumberInBook": "1.165",
        "lab": {
            "l": 31.24,
            "a": 7.28,
            "b": -4.26
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-2311 TPG",
        "name": "Eggplant",
        "rgb": {
            "r": 101,
            "g": 77,
            "b": 87
        },
        "hex": "654D57",
        "positionInBook": 1157,
        "pageNumberInBook": "1.166",
        "lab": {
            "l": 35.84,
            "a": 11.73,
            "b": -1.43
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1624 TPG",
        "name": "Sassafras",
        "rgb": {
            "r": 92,
            "g": 74,
            "b": 80
        },
        "hex": "5C4A50",
        "positionInBook": 1159,
        "pageNumberInBook": "1.166",
        "lab": {
            "l": 33.61,
            "a": 9.28,
            "b": -0.24
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1623 TPG",
        "name": "Vineyard Wine",
        "rgb": {
            "r": 96,
            "g": 71,
            "b": 77
        },
        "hex": "60474D",
        "positionInBook": 1161,
        "pageNumberInBook": "1.166",
        "lab": {
            "l": 33.4,
            "a": 12.04,
            "b": 1.48
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1716 TPG",
        "name": "Mauve Wine",
        "rgb": {
            "r": 98,
            "g": 67,
            "b": 80
        },
        "hex": "624350",
        "positionInBook": 1163,
        "pageNumberInBook": "1.167",
        "lab": {
            "l": 32.56,
            "a": 16.1,
            "b": -1.69
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1627 TPG",
        "name": "Port Royale",
        "rgb": {
            "r": 94,
            "g": 62,
            "b": 69
        },
        "hex": "5E3E45",
        "positionInBook": 1165,
        "pageNumberInBook": "1.167",
        "lab": {
            "l": 30.51,
            "a": 15.96,
            "b": 2.24
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1528 TPG",
        "name": "Windsor Wine",
        "rgb": {
            "r": 100,
            "g": 62,
            "b": 70
        },
        "hex": "643E46",
        "positionInBook": 1167,
        "pageNumberInBook": "1.167",
        "lab": {
            "l": 31.36,
            "a": 18.58,
            "b": 3.14
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3325 TPG",
        "name": "Wood Violet",
        "rgb": {
            "r": 122,
            "g": 80,
            "b": 118
        },
        "hex": "7A5076",
        "positionInBook": 1199,
        "pageNumberInBook": "1.172",
        "lab": {
            "l": 40.27,
            "a": 23.12,
            "b": -13.44
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-2524 TPG",
        "name": "Dark Purple",
        "rgb": {
            "r": 108,
            "g": 62,
            "b": 91
        },
        "hex": "6C3E5B",
        "positionInBook": 1201,
        "pageNumberInBook": "1.172",
        "lab": {
            "l": 33.27,
            "a": 24.65,
            "b": -7.33
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-2620 TPG",
        "name": "Winter Bloom",
        "rgb": {
            "r": 83,
            "g": 58,
            "b": 75
        },
        "hex": "533A4B",
        "positionInBook": 1203,
        "pageNumberInBook": "1.172",
        "lab": {
            "l": 28.2,
            "a": 14.12,
            "b": -5.27
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-3206 TPG",
        "name": "Winsome Orchid",
        "rgb": {
            "r": 209,
            "g": 182,
            "b": 204
        },
        "hex": "D1B6CC",
        "positionInBook": 1205,
        "pageNumberInBook": "1.173",
        "lab": {
            "l": 77.15,
            "a": 13.07,
            "b": -6.73
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-3313 TPG",
        "name": "Dusty Lavender",
        "rgb": {
            "r": 157,
            "g": 120,
            "b": 154
        },
        "hex": "9D789A",
        "positionInBook": 1207,
        "pageNumberInBook": "1.173",
        "lab": {
            "l": 55.13,
            "a": 19.86,
            "b": -12.44
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-3520 TPG",
        "name": "Diffused Orchid",
        "rgb": {
            "r": 158,
            "g": 127,
            "b": 166
        },
        "hex": "9E7FA6",
        "positionInBook": 1209,
        "pageNumberInBook": "1.173",
        "lab": {
            "l": 57.29,
            "a": 17.95,
            "b": -15.74
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3530 TPG",
        "name": "Lavender Crystal",
        "rgb": {
            "r": 150,
            "g": 110,
            "b": 156
        },
        "hex": "966E9C",
        "positionInBook": 1211,
        "pageNumberInBook": "1.173",
        "lab": {
            "l": 52.19,
            "a": 22.46,
            "b": -17.49
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-3521 TPG",
        "name": "Lupine",
        "rgb": {
            "r": 190,
            "g": 160,
            "b": 196
        },
        "hex": "BEA0C4",
        "positionInBook": 1213,
        "pageNumberInBook": "1.174",
        "lab": {
            "l": 69.58,
            "a": 16.57,
            "b": -13.85
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-3520 TPG",
        "name": "African Violet",
        "rgb": {
            "r": 177,
            "g": 133,
            "b": 186
        },
        "hex": "B185BA",
        "positionInBook": 1215,
        "pageNumberInBook": "1.174",
        "lab": {
            "l": 61.63,
            "a": 24.28,
            "b": -20.28
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-3640 TPG",
        "name": "Fairy Wren",
        "rgb": {
            "r": 152,
            "g": 125,
            "b": 180
        },
        "hex": "987DB4",
        "positionInBook": 1217,
        "pageNumberInBook": "1.174",
        "lab": {
            "l": 56.85,
            "a": 18.33,
            "b": -24.17
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-3802 TPG",
        "name": "Orchid Tint",
        "rgb": {
            "r": 220,
            "g": 212,
            "b": 220
        },
        "hex": "DCD4DC",
        "positionInBook": 1219,
        "pageNumberInBook": "1.175",
        "lab": {
            "l": 85.74,
            "a": 4.14,
            "b": -2.93
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-3805 TPG",
        "name": "Orchid Hush",
        "rgb": {
            "r": 205,
            "g": 195,
            "b": 211
        },
        "hex": "CDC3D3",
        "positionInBook": 1221,
        "pageNumberInBook": "1.175",
        "lab": {
            "l": 79.94,
            "a": 5.92,
            "b": -6.81
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-3508 TPG",
        "name": "Fair Orchid",
        "rgb": {
            "r": 192,
            "g": 171,
            "b": 194
        },
        "hex": "C0ABC2",
        "positionInBook": 1223,
        "pageNumberInBook": "1.175",
        "lab": {
            "l": 72.54,
            "a": 10.93,
            "b": -8.5
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-3817 TPG",
        "name": "Rhapsody",
        "rgb": {
            "r": 162,
            "g": 138,
            "b": 172
        },
        "hex": "A28AAC",
        "positionInBook": 1225,
        "pageNumberInBook": "1.175",
        "lab": {
            "l": 60.78,
            "a": 14.32,
            "b": -14.18
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3521 TPG",
        "name": "Plum Jam",
        "rgb": {
            "r": 107,
            "g": 76,
            "b": 125
        },
        "hex": "6B4C7D",
        "positionInBook": 1251,
        "pageNumberInBook": "1.179",
        "lab": {
            "l": 37.64,
            "a": 20.63,
            "b": -21.78
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3514 TPG",
        "name": "Majesty",
        "rgb": {
            "r": 97,
            "g": 66,
            "b": 102
        },
        "hex": "614266",
        "positionInBook": 1253,
        "pageNumberInBook": "1.179",
        "lab": {
            "l": 32.95,
            "a": 19.01,
            "b": -14.79
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3632 TPG",
        "name": "Petunia",
        "rgb": {
            "r": 91,
            "g": 68,
            "b": 109
        },
        "hex": "5B446D",
        "positionInBook": 1255,
        "pageNumberInBook": "1.18",
        "lab": {
            "l": 32.9,
            "a": 16.01,
            "b": -19.34
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3640 TPG",
        "name": "Crown Jewel",
        "rgb": {
            "r": 84,
            "g": 63,
            "b": 92
        },
        "hex": "543F5C",
        "positionInBook": 1257,
        "pageNumberInBook": "1.18",
        "lab": {
            "l": 30.13,
            "a": 13.77,
            "b": -13.17
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3410 TPG",
        "name": "Vintage Violet",
        "rgb": {
            "r": 98,
            "g": 81,
            "b": 98
        },
        "hex": "625162",
        "positionInBook": 1259,
        "pageNumberInBook": "1.18",
        "lab": {
            "l": 36.83,
            "a": 9.67,
            "b": -6.77
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3513 TPG",
        "name": "Grape Compote",
        "rgb": {
            "r": 114,
            "g": 98,
            "b": 125
        },
        "hex": "72627D",
        "positionInBook": 1261,
        "pageNumberInBook": "1.181",
        "lab": {
            "l": 43.91,
            "a": 11.12,
            "b": -12.31
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3323 TPG",
        "name": "Deep Purple",
        "rgb": {
            "r": 94,
            "g": 71,
            "b": 92
        },
        "hex": "5E475C",
        "positionInBook": 1263,
        "pageNumberInBook": "1.181",
        "lab": {
            "l": 33.32,
            "a": 13.35,
            "b": -7.97
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3714 TPG",
        "name": "Navy Cosmos",
        "rgb": {
            "r": 88,
            "g": 74,
            "b": 93
        },
        "hex": "584A5D",
        "positionInBook": 1265,
        "pageNumberInBook": "1.181",
        "lab": {
            "l": 33.52,
            "a": 9.59,
            "b": -8.94
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3520 TPG",
        "name": "Blackberry Cordial",
        "rgb": {
            "r": 78,
            "g": 62,
            "b": 83
        },
        "hex": "4E3E53",
        "positionInBook": 1267,
        "pageNumberInBook": "1.181",
        "lab": {
            "l": 28.67,
            "a": 10.97,
            "b": -9.68
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-3725 TPG",
        "name": "Bougainvillea",
        "rgb": {
            "r": 157,
            "g": 134,
            "b": 189
        },
        "hex": "9D86BD",
        "positionInBook": 1299,
        "pageNumberInBook": "1.186",
        "lab": {
            "l": 59.83,
            "a": 17.05,
            "b": -24.98
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-3826 TPG",
        "name": "Aster Purple",
        "rgb": {
            "r": 130,
            "g": 120,
            "b": 169
        },
        "hex": "8278A9",
        "positionInBook": 1301,
        "pageNumberInBook": "1.186",
        "lab": {
            "l": 52.84,
            "a": 11.76,
            "b": -24.36
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-3710 TPG",
        "name": "Orchid Petal",
        "rgb": {
            "r": 189,
            "g": 177,
            "b": 203
        },
        "hex": "BDB1CB",
        "positionInBook": 1303,
        "pageNumberInBook": "1.187",
        "lab": {
            "l": 74.01,
            "a": 8.41,
            "b": -11.56
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-3812 TPG",
        "name": "Heirloom Lilac",
        "rgb": {
            "r": 153,
            "g": 147,
            "b": 177
        },
        "hex": "9993B1",
        "positionInBook": 1305,
        "pageNumberInBook": "1.187",
        "lab": {
            "l": 62.39,
            "a": 6.92,
            "b": -14.68
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-3812 TPG",
        "name": "Dusk",
        "rgb": {
            "r": 139,
            "g": 131,
            "b": 157
        },
        "hex": "8B839D",
        "positionInBook": 1307,
        "pageNumberInBook": "1.187",
        "lab": {
            "l": 56.19,
            "a": 7.01,
            "b": -12.45
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3718 TPG",
        "name": "Purple Haze",
        "rgb": {
            "r": 128,
            "g": 115,
            "b": 152
        },
        "hex": "807398",
        "positionInBook": 1309,
        "pageNumberInBook": "1.187",
        "lab": {
            "l": 50.74,
            "a": 10.81,
            "b": -18
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-3907 TPG",
        "name": "Thistle",
        "rgb": {
            "r": 188,
            "g": 183,
            "b": 202
        },
        "hex": "BCB7CA",
        "positionInBook": 1311,
        "pageNumberInBook": "1.188",
        "lab": {
            "l": 75.41,
            "a": 4.29,
            "b": -8.64
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-3910 TPG",
        "name": "Lavender Gray",
        "rgb": {
            "r": 150,
            "g": 144,
            "b": 163
        },
        "hex": "9690A3",
        "positionInBook": 1313,
        "pageNumberInBook": "1.188",
        "lab": {
            "l": 60.83,
            "a": 4.89,
            "b": -8.95
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3710 TPG",
        "name": "Gray Ridge",
        "rgb": {
            "r": 128,
            "g": 119,
            "b": 133
        },
        "hex": "807785",
        "positionInBook": 1315,
        "pageNumberInBook": "1.188",
        "lab": {
            "l": 51.18,
            "a": 5.6,
            "b": -6.3
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-3903 TPG",
        "name": "Lilac Marble",
        "rgb": {
            "r": 192,
            "g": 185,
            "b": 194
        },
        "hex": "C0B9C2",
        "positionInBook": 1317,
        "pageNumberInBook": "1.189",
        "lab": {
            "l": 75.96,
            "a": 3.74,
            "b": -3.48
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-3906 TPG",
        "name": "Raindrops",
        "rgb": {
            "r": 180,
            "g": 172,
            "b": 179
        },
        "hex": "B4ACB3",
        "positionInBook": 1319,
        "pageNumberInBook": "1.189",
        "lab": {
            "l": 71.37,
            "a": 3.89,
            "b": -2.25
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-3808 TPG",
        "name": "Nirvana",
        "rgb": {
            "r": 156,
            "g": 141,
            "b": 154
        },
        "hex": "9C8D9A",
        "positionInBook": 1321,
        "pageNumberInBook": "1.189",
        "lab": {
            "l": 60.47,
            "a": 7.4,
            "b": -4.43
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1505 TPG",
        "name": "Quail",
        "rgb": {
            "r": 152,
            "g": 135,
            "b": 143
        },
        "hex": "98878F",
        "positionInBook": 1323,
        "pageNumberInBook": "1.189",
        "lab": {
            "l": 58.23,
            "a": 7.75,
            "b": -1.28
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-3905 TPG",
        "name": "Lavender Blue",
        "rgb": {
            "r": 192,
            "g": 189,
            "b": 209
        },
        "hex": "C0BDD1",
        "positionInBook": 1325,
        "pageNumberInBook": "1.19",
        "lab": {
            "l": 77.41,
            "a": 3.65,
            "b": -9.35
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3963 TPG",
        "name": "Spectrum Blue",
        "rgb": {
            "r": 73,
            "g": 75,
            "b": 132
        },
        "hex": "494B84",
        "positionInBook": 1351,
        "pageNumberInBook": "2.003",
        "lab": {
            "l": 33.88,
            "a": 10.14,
            "b": -32.01
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-3932 TPG",
        "name": "Deep Periwinkle",
        "rgb": {
            "r": 125,
            "g": 132,
            "b": 189
        },
        "hex": "7D84BD",
        "positionInBook": 1353,
        "pageNumberInBook": "2.004",
        "lab": {
            "l": 56.32,
            "a": 6.8,
            "b": -30.56
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3944 TPG",
        "name": "Violet Storm",
        "rgb": {
            "r": 95,
            "g": 101,
            "b": 162
        },
        "hex": "5F65A2",
        "positionInBook": 1355,
        "pageNumberInBook": "2.004",
        "lab": {
            "l": 44.44,
            "a": 8.83,
            "b": -34.04
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3943 TPG",
        "name": "Blue Iris",
        "rgb": {
            "r": 99,
            "g": 100,
            "b": 165
        },
        "hex": "6364A5",
        "positionInBook": 1357,
        "pageNumberInBook": "2.004",
        "lab": {
            "l": 44.53,
            "a": 11.87,
            "b": -35.58
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-3925 TPG",
        "name": "Easter Egg",
        "rgb": {
            "r": 145,
            "g": 153,
            "b": 204
        },
        "hex": "9199CC",
        "positionInBook": 1359,
        "pageNumberInBook": "2.005",
        "lab": {
            "l": 64.01,
            "a": 5.11,
            "b": -27.13
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-3934 TPG",
        "name": "Persian Jewel",
        "rgb": {
            "r": 108,
            "g": 128,
            "b": 190
        },
        "hex": "6C80BE",
        "positionInBook": 1361,
        "pageNumberInBook": "2.005",
        "lab": {
            "l": 53.88,
            "a": 4.3,
            "b": -35.23
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3946 TPG",
        "name": "Baja Blue",
        "rgb": {
            "r": 90,
            "g": 105,
            "b": 175
        },
        "hex": "5A69AF",
        "positionInBook": 1363,
        "pageNumberInBook": "2.005",
        "lab": {
            "l": 45.6,
            "a": 8.01,
            "b": -39.52
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3951 TPG",
        "name": "Clematis Blue",
        "rgb": {
            "r": 71,
            "g": 73,
            "b": 129
        },
        "hex": "474981",
        "positionInBook": 1365,
        "pageNumberInBook": "2.005",
        "lab": {
            "l": 32.94,
            "a": 10.28,
            "b": -32.01
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3945 TPG",
        "name": "Amparo Blue",
        "rgb": {
            "r": 80,
            "g": 101,
            "b": 172
        },
        "hex": "5065AC",
        "positionInBook": 1367,
        "pageNumberInBook": "2.006",
        "lab": {
            "l": 43.54,
            "a": 7.15,
            "b": -41.27
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-4048 TPG",
        "name": "Baleine Blue",
        "rgb": {
            "r": 48,
            "g": 89,
            "b": 140
        },
        "hex": "30598C",
        "positionInBook": 1399,
        "pageNumberInBook": "2.010",
        "lab": {
            "l": 36.52,
            "a": -1.86,
            "b": -33.55
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-4132 TPG",
        "name": "Little Boy Blue",
        "rgb": {
            "r": 111,
            "g": 160,
            "b": 216
        },
        "hex": "6FA0D8",
        "positionInBook": 1401,
        "pageNumberInBook": "2.011",
        "lab": {
            "l": 63.76,
            "a": -5.73,
            "b": -34.78
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-4245 TPG",
        "name": "Ibiza Blue",
        "rgb": {
            "r": 39,
            "g": 127,
            "b": 187
        },
        "hex": "277FBB",
        "positionInBook": 1403,
        "pageNumberInBook": "2.011",
        "lab": {
            "l": 50.09,
            "a": -11.05,
            "b": -39.94
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-4247 TPG",
        "name": "Brilliant Blue",
        "rgb": {
            "r": 0,
            "g": 120,
            "b": 186
        },
        "hex": "0078BA",
        "positionInBook": 1405,
        "pageNumberInBook": "2.011",
        "lab": {
            "l": 47.09,
            "a": -10.25,
            "b": -44.37
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-4245 TPG",
        "name": "Imperial Blue",
        "rgb": {
            "r": 25,
            "g": 94,
            "b": 154
        },
        "hex": "195E9A",
        "positionInBook": 1407,
        "pageNumberInBook": "2.011",
        "lab": {
            "l": 37.77,
            "a": -3.99,
            "b": -40.13
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-4323 TPG",
        "name": "Ethereal Blue",
        "rgb": {
            "r": 97,
            "g": 169,
            "b": 210
        },
        "hex": "61A9D2",
        "positionInBook": 1409,
        "pageNumberInBook": "2.012",
        "lab": {
            "l": 65.37,
            "a": -15.15,
            "b": -28.96
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-4435 TPG",
        "name": "Malibu Blue",
        "rgb": {
            "r": 45,
            "g": 145,
            "b": 198
        },
        "hex": "2D91C6",
        "positionInBook": 1411,
        "pageNumberInBook": "2.012",
        "lab": {
            "l": 55.92,
            "a": -15.67,
            "b": -37.6
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-4032 TPG",
        "name": "Deep Water",
        "rgb": {
            "r": 59,
            "g": 107,
            "b": 144
        },
        "hex": "3B6B90",
        "positionInBook": 1413,
        "pageNumberInBook": "2.012",
        "lab": {
            "l": 42.73,
            "a": -8.25,
            "b": -26.53
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-4122 TPG",
        "name": "Airy Blue",
        "rgb": {
            "r": 149,
            "g": 184,
            "b": 216
        },
        "hex": "95B8D8",
        "positionInBook": 1415,
        "pageNumberInBook": "2.013",
        "lab": {
            "l": 72.94,
            "a": -6.82,
            "b": -20.73
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-4032 TPG",
        "name": "Lichen Blue",
        "rgb": {
            "r": 99,
            "g": 141,
            "b": 183
        },
        "hex": "638DB7",
        "positionInBook": 1417,
        "pageNumberInBook": "2.013",
        "lab": {
            "l": 56.6,
            "a": -6.28,
            "b": -27.5
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-4033 TPG",
        "name": "Pacific Coast",
        "rgb": {
            "r": 91,
            "g": 132,
            "b": 177
        },
        "hex": "5B84B1",
        "positionInBook": 1419,
        "pageNumberInBook": "2.013",
        "lab": {
            "l": 53.24,
            "a": -4.98,
            "b": -29.04
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-4029 TPG",
        "name": "Federal Blue",
        "rgb": {
            "r": 68,
            "g": 99,
            "b": 140
        },
        "hex": "44638C",
        "positionInBook": 1421,
        "pageNumberInBook": "2.013",
        "lab": {
            "l": 40.71,
            "a": -2.71,
            "b": -26.94
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-4121 TPG",
        "name": "Blissful Blue ",
        "rgb": {
            "r": 114,
            "g": 150,
            "b": 186
        },
        "hex": "7296BA",
        "positionInBook": 1423,
        "pageNumberInBook": "2.014",
        "lab": {
            "l": 60.25,
            "a": -5.82,
            "b": -23.83
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-4035 TPG",
        "name": "Cyaneus",
        "rgb": {
            "r": 92,
            "g": 132,
            "b": 167
        },
        "hex": "5C84A7",
        "positionInBook": 1425,
        "pageNumberInBook": "2.014",
        "lab": {
            "l": 53,
            "a": -7.31,
            "b": -23.83
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-3921 TPG",
        "name": "Blue Heron",
        "rgb": {
            "r": 155,
            "g": 167,
            "b": 201
        },
        "hex": "9BA7C9",
        "positionInBook": 1451,
        "pageNumberInBook": "2.018",
        "lab": {
            "l": 68.28,
            "a": 1.39,
            "b": -19.19
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-3930 TPG",
        "name": "Vista Blue",
        "rgb": {
            "r": 126,
            "g": 156,
            "b": 211
        },
        "hex": "7E9CD3",
        "positionInBook": 1453,
        "pageNumberInBook": "2.018",
        "lab": {
            "l": 63.65,
            "a": -1.11,
            "b": -32.09
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-4030 TPG",
        "name": "Hydrangea",
        "rgb": {
            "r": 138,
            "g": 159,
            "b": 204
        },
        "hex": "8A9FCC",
        "positionInBook": 1455,
        "pageNumberInBook": "2.018",
        "lab": {
            "l": 64.96,
            "a": 0.02,
            "b": -25.67
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-4031 TPG",
        "name": "Windsurfer",
        "rgb": {
            "r": 166,
            "g": 190,
            "b": 229
        },
        "hex": "A6BEE5",
        "positionInBook": 1457,
        "pageNumberInBook": "2.019",
        "lab": {
            "l": 76,
            "a": -1.78,
            "b": -22.64
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-3920 TPG",
        "name": "Placid Blue",
        "rgb": {
            "r": 141,
            "g": 172,
            "b": 210
        },
        "hex": "8DACD2",
        "positionInBook": 1459,
        "pageNumberInBook": "2.019",
        "lab": {
            "l": 68.79,
            "a": -4.42,
            "b": -23.77
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-4020 TPG",
        "name": "Della Robbia Blue",
        "rgb": {
            "r": 120,
            "g": 153,
            "b": 200
        },
        "hex": "7899C8",
        "positionInBook": 1461,
        "pageNumberInBook": "2.019",
        "lab": {
            "l": 61.83,
            "a": -3.2,
            "b": -28.87
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3936 TPG",
        "name": "Ebb and Flow",
        "rgb": {
            "r": 100,
            "g": 126,
            "b": 172
        },
        "hex": "647EAC",
        "positionInBook": 1463,
        "pageNumberInBook": "2.019",
        "lab": {
            "l": 51.89,
            "a": -0.38,
            "b": -28.25
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-4005 TPG",
        "name": "Nantucket Breeze",
        "rgb": {
            "r": 188,
            "g": 213,
            "b": 234
        },
        "hex": "BCD5EA",
        "positionInBook": 1465,
        "pageNumberInBook": "2.020",
        "lab": {
            "l": 83.78,
            "a": -5.31,
            "b": -13.66
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-4214 TPG",
        "name": "Powder Blue",
        "rgb": {
            "r": 149,
            "g": 177,
            "b": 206
        },
        "hex": "95B1CE",
        "positionInBook": 1467,
        "pageNumberInBook": "2.020",
        "lab": {
            "l": 70.73,
            "a": -5.16,
            "b": -18.44
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3916 TPG",
        "name": "Flint Stone",
        "rgb": {
            "r": 104,
            "g": 117,
            "b": 133
        },
        "hex": "687585",
        "positionInBook": 1499,
        "pageNumberInBook": "2.025",
        "lab": {
            "l": 48.59,
            "a": -2.34,
            "b": -10.75
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3912 TPG",
        "name": "Grisaille",
        "rgb": {
            "r": 98,
            "g": 102,
            "b": 119
        },
        "hex": "626677",
        "positionInBook": 1501,
        "pageNumberInBook": "2.025",
        "lab": {
            "l": 43.28,
            "a": 1.49,
            "b": -10.28
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-4028 TPG",
        "name": "Insignia Blue",
        "rgb": {
            "r": 65,
            "g": 76,
            "b": 96
        },
        "hex": "414C60",
        "positionInBook": 1503,
        "pageNumberInBook": "2.025",
        "lab": {
            "l": 31.95,
            "a": -0.6,
            "b": -13.59
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-4122 TPG",
        "name": "Moonlit Ocean",
        "rgb": {
            "r": 53,
            "g": 69,
            "b": 88
        },
        "hex": "354558",
        "positionInBook": 1505,
        "pageNumberInBook": "2.025",
        "lab": {
            "l": 28.57,
            "a": -2.61,
            "b": -13.45
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-3923 TPG",
        "name": "Colony Blue",
        "rgb": {
            "r": 103,
            "g": 120,
            "b": 157
        },
        "hex": "67789D",
        "positionInBook": 1507,
        "pageNumberInBook": "2.026",
        "lab": {
            "l": 50.12,
            "a": -0.37,
            "b": -21.91
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-4030 TPG",
        "name": "True Navy",
        "rgb": {
            "r": 76,
            "g": 91,
            "b": 125
        },
        "hex": "4C5B7D",
        "positionInBook": 1509,
        "pageNumberInBook": "2.026",
        "lab": {
            "l": 38.4,
            "a": 0.79,
            "b": -21.19
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-4031 TPG",
        "name": "Sargasso Sea",
        "rgb": {
            "r": 72,
            "g": 81,
            "b": 103
        },
        "hex": "485167",
        "positionInBook": 1511,
        "pageNumberInBook": "2.026",
        "lab": {
            "l": 34.38,
            "a": 0.14,
            "b": -14.16
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-3920 TPG",
        "name": "English Manor",
        "rgb": {
            "r": 113,
            "g": 129,
            "b": 163
        },
        "hex": "7181A3",
        "positionInBook": 1513,
        "pageNumberInBook": "2.027",
        "lab": {
            "l": 53.69,
            "a": -0.41,
            "b": -20.34
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3917 TPG",
        "name": "Gray Blue",
        "rgb": {
            "r": 86,
            "g": 95,
            "b": 126
        },
        "hex": "565F7E",
        "positionInBook": 1515,
        "pageNumberInBook": "2.027",
        "lab": {
            "l": 40.35,
            "a": 2.43,
            "b": -19.03
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3919 TPG",
        "name": "Nightshadow Blue",
        "rgb": {
            "r": 89,
            "g": 95,
            "b": 116
        },
        "hex": "595F74",
        "positionInBook": 1517,
        "pageNumberInBook": "2.027",
        "lab": {
            "l": 40.31,
            "a": 1.33,
            "b": -12.52
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3815 TPG",
        "name": "Evening Blue",
        "rgb": {
            "r": 58,
            "g": 56,
            "b": 77
        },
        "hex": "3A384D",
        "positionInBook": 1519,
        "pageNumberInBook": "2.027",
        "lab": {
            "l": 24.57,
            "a": 4.72,
            "b": -12.33
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-3927 TPG",
        "name": "Velvet Morning",
        "rgb": {
            "r": 102,
            "g": 110,
            "b": 145
        },
        "hex": "666E91",
        "positionInBook": 1521,
        "pageNumberInBook": "2.028",
        "lab": {
            "l": 46.8,
            "a": 2.6,
            "b": -20
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3936 TPG",
        "name": "Skipper Blue",
        "rgb": {
            "r": 85,
            "g": 86,
            "b": 123
        },
        "hex": "55567B",
        "positionInBook": 1523,
        "pageNumberInBook": "2.028",
        "lab": {
            "l": 37.73,
            "a": 6.32,
            "b": -21.26
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3830 TPG",
        "name": "Astral Aura",
        "rgb": {
            "r": 63,
            "g": 60,
            "b": 87
        },
        "hex": "3F3C57",
        "positionInBook": 1525,
        "pageNumberInBook": "2.028",
        "lab": {
            "l": 26.66,
            "a": 5.9,
            "b": -15.45
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3920 TPG",
        "name": "Peacoat",
        "rgb": {
            "r": 63,
            "g": 63,
            "b": 80
        },
        "hex": "3F3F50",
        "positionInBook": 1551,
        "pageNumberInBook": "2.032",
        "lab": {
            "l": 27.2,
            "a": 2.83,
            "b": -10.1
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-4022 TPG",
        "name": "Parisian Night",
        "rgb": {
            "r": 64,
            "g": 67,
            "b": 79
        },
        "hex": "40434F",
        "positionInBook": 1553,
        "pageNumberInBook": "2.032",
        "lab": {
            "l": 28.5,
            "a": 0.99,
            "b": -7.88
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3930 TPG",
        "name": "Odyssey Gray",
        "rgb": {
            "r": 79,
            "g": 79,
            "b": 92
        },
        "hex": "4F4F5C",
        "positionInBook": 1555,
        "pageNumberInBook": "2.033",
        "lab": {
            "l": 34.05,
            "a": 2.07,
            "b": -7.43
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3927 TPG",
        "name": "Graphite",
        "rgb": {
            "r": 72,
            "g": 71,
            "b": 84
        },
        "hex": "484754",
        "positionInBook": 1557,
        "pageNumberInBook": "2.033",
        "lab": {
            "l": 30.61,
            "a": 2.78,
            "b": -7.61
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3923 TPG",
        "name": "Navy Blazer",
        "rgb": {
            "r": 59,
            "g": 62,
            "b": 75
        },
        "hex": "3B3E4B",
        "positionInBook": 1559,
        "pageNumberInBook": "2.033",
        "lab": {
            "l": 26.13,
            "a": 1.38,
            "b": -9.05
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-3713 TPG",
        "name": "Deep Well",
        "rgb": {
            "r": 63,
            "g": 60,
            "b": 72
        },
        "hex": "3F3C48",
        "positionInBook": 1561,
        "pageNumberInBook": "2.033",
        "lab": {
            "l": 26.05,
            "a": 3.08,
            "b": -6.63
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-4019 TPG",
        "name": "India Ink",
        "rgb": {
            "r": 74,
            "g": 79,
            "b": 89
        },
        "hex": "4A4F59",
        "positionInBook": 1563,
        "pageNumberInBook": "2.034",
        "lab": {
            "l": 33.37,
            "a": -0.77,
            "b": -6.5
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-4015 TPG",
        "name": "Blue Graphite",
        "rgb": {
            "r": 64,
            "g": 67,
            "b": 71
        },
        "hex": "404347",
        "positionInBook": 1565,
        "pageNumberInBook": "2.034",
        "lab": {
            "l": 28.12,
            "a": -0.41,
            "b": -2.92
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-4218 TPG",
        "name": "Vulcan",
        "rgb": {
            "r": 63,
            "g": 65,
            "b": 71
        },
        "hex": "3F4147",
        "positionInBook": 1567,
        "pageNumberInBook": "2.034",
        "lab": {
            "l": 27.62,
            "a": 0.24,
            "b": -3.94
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-4320 TPG",
        "name": "Aegean Blue",
        "rgb": {
            "r": 86,
            "g": 119,
            "b": 137
        },
        "hex": "567789",
        "positionInBook": 1599,
        "pageNumberInBook": "2.039",
        "lab": {
            "l": 47.78,
            "a": -8.92,
            "b": -14.29
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-4020 TPG",
        "name": "Captain's Blue",
        "rgb": {
            "r": 91,
            "g": 118,
            "b": 145
        },
        "hex": "5B7691",
        "positionInBook": 1601,
        "pageNumberInBook": "2.039",
        "lab": {
            "l": 48.18,
            "a": -5.49,
            "b": -18.14
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-4026 TPG",
        "name": "Stellar",
        "rgb": {
            "r": 70,
            "g": 100,
            "b": 125
        },
        "hex": "46647D",
        "positionInBook": 1603,
        "pageNumberInBook": "2.039",
        "lab": {
            "l": 40.56,
            "a": -6.24,
            "b": -18.19
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-4028 TPG",
        "name": "Bering Sea",
        "rgb": {
            "r": 84,
            "g": 99,
            "b": 117
        },
        "hex": "546375",
        "positionInBook": 1605,
        "pageNumberInBook": "2.040",
        "lab": {
            "l": 41.03,
            "a": -2.7,
            "b": -12.42
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-4038 TPG",
        "name": "Gibraltar Sea",
        "rgb": {
            "r": 49,
            "g": 71,
            "b": 94
        },
        "hex": "31475E",
        "positionInBook": 1607,
        "pageNumberInBook": "2.040",
        "lab": {
            "l": 28.85,
            "a": -3.33,
            "b": -17.31
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-4034 TPG",
        "name": "Sailor Blue ",
        "rgb": {
            "r": 47,
            "g": 74,
            "b": 98
        },
        "hex": "2F4A62",
        "positionInBook": 1609,
        "pageNumberInBook": "2.040",
        "lab": {
            "l": 29.96,
            "a": -5.09,
            "b": -17.72
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-4023 TPG",
        "name": "Blue Heaven",
        "rgb": {
            "r": 93,
            "g": 127,
            "b": 152
        },
        "hex": "5D7F98",
        "positionInBook": 1611,
        "pageNumberInBook": "2.041",
        "lab": {
            "l": 51.02,
            "a": -7.61,
            "b": -18.01
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-4018 TPG",
        "name": "Real Teal",
        "rgb": {
            "r": 75,
            "g": 100,
            "b": 121
        },
        "hex": "4B6479",
        "positionInBook": 1613,
        "pageNumberInBook": "2.041",
        "lab": {
            "l": 40.76,
            "a": -5.55,
            "b": -15.19
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-4023 TPG",
        "name": "Blue Ashes",
        "rgb": {
            "r": 70,
            "g": 101,
            "b": 125
        },
        "hex": "46657D",
        "positionInBook": 1615,
        "pageNumberInBook": "2.041",
        "lab": {
            "l": 40.87,
            "a": -6.58,
            "b": -17.88
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-4241 TPG",
        "name": "Moroccan Blue",
        "rgb": {
            "r": 39,
            "g": 88,
            "b": 112
        },
        "hex": "275870",
        "positionInBook": 1617,
        "pageNumberInBook": "2.041",
        "lab": {
            "l": 34.53,
            "a": -10.9,
            "b": -19.74
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-4206 TPG",
        "name": "Pearl Blue",
        "rgb": {
            "r": 172,
            "g": 185,
            "b": 191
        },
        "hex": "ACB9BF",
        "positionInBook": 1619,
        "pageNumberInBook": "2.042",
        "lab": {
            "l": 74.3,
            "a": -3.93,
            "b": -4.64
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-4008 TPG",
        "name": "Blue Fog",
        "rgb": {
            "r": 152,
            "g": 171,
            "b": 189
        },
        "hex": "98ABBD",
        "positionInBook": 1621,
        "pageNumberInBook": "2.042",
        "lab": {
            "l": 68.85,
            "a": -4.53,
            "b": -11.59
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-4013 TPG",
        "name": "Ashley Blue",
        "rgb": {
            "r": 135,
            "g": 154,
            "b": 172
        },
        "hex": "879AAC",
        "positionInBook": 1623,
        "pageNumberInBook": "2.042",
        "lab": {
            "l": 62.6,
            "a": -4.41,
            "b": -11.5
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-4303 TPG",
        "name": "Country Air",
        "rgb": {
            "r": 209,
            "g": 222,
            "b": 233
        },
        "hex": "D1DEE9",
        "positionInBook": 1625,
        "pageNumberInBook": "2.043",
        "lab": {
            "l": 87.75,
            "a": -2.79,
            "b": -7.34
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-4247 TPG",
        "name": "Diva Blue",
        "rgb": {
            "r": 0,
            "g": 132,
            "b": 192
        },
        "hex": "0084C0",
        "positionInBook": 1651,
        "pageNumberInBook": "2.046",
        "lab": {
            "l": 50.63,
            "a": -16.84,
            "b": -42.54
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-4427 TPG",
        "name": "Norse Blue",
        "rgb": {
            "r": 86,
            "g": 168,
            "b": 203
        },
        "hex": "56A8CB",
        "positionInBook": 1653,
        "pageNumberInBook": "2.047",
        "lab": {
            "l": 64.41,
            "a": -18.93,
            "b": -26.76
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-4432 TPG",
        "name": "Vivid Blue",
        "rgb": {
            "r": 0,
            "g": 140,
            "b": 181
        },
        "hex": "008CB5",
        "positionInBook": 1655,
        "pageNumberInBook": "2.047",
        "lab": {
            "l": 52.74,
            "a": -22.55,
            "b": -32.98
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-4535 TPG",
        "name": "Blue Jewel",
        "rgb": {
            "r": 0,
            "g": 134,
            "b": 182
        },
        "hex": "0086B6",
        "positionInBook": 1657,
        "pageNumberInBook": "2.047",
        "lab": {
            "l": 49.43,
            "a": -27.3,
            "b": -39.07
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-4537 TPG",
        "name": "Methyl Blue",
        "rgb": {
            "r": 0,
            "g": 125,
            "b": 173
        },
        "hex": "007DAD",
        "positionInBook": 1659,
        "pageNumberInBook": "2.047",
        "lab": {
            "l": 47.3,
            "a": -19.06,
            "b": -37.05
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-4520 TPG",
        "name": "Splish Splash",
        "rgb": {
            "r": 103,
            "g": 190,
            "b": 223
        },
        "hex": "67BEDF",
        "positionInBook": 1661,
        "pageNumberInBook": "2.048",
        "lab": {
            "l": 72.09,
            "a": -20.62,
            "b": -26.37
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-4428 TPG",
        "name": "Crystal Seas",
        "rgb": {
            "r": 96,
            "g": 177,
            "b": 210
        },
        "hex": "60B1D2",
        "positionInBook": 1663,
        "pageNumberInBook": "2.048",
        "lab": {
            "l": 67.78,
            "a": -19.12,
            "b": -25.94
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-4530 TPG",
        "name": "Aquarius",
        "rgb": {
            "r": 66,
            "g": 174,
            "b": 213
        },
        "hex": "42AED5",
        "positionInBook": 1665,
        "pageNumberInBook": "2.048",
        "lab": {
            "l": 65.57,
            "a": -22.77,
            "b": -30.6
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-4307 TPG",
        "name": "Tropical Breeze",
        "rgb": {
            "r": 136,
            "g": 207,
            "b": 231
        },
        "hex": "88CFE7",
        "positionInBook": 1667,
        "pageNumberInBook": "2.049",
        "lab": {
            "l": 78.61,
            "a": -18.62,
            "b": -20.34
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-4525 TPG",
        "name": "Caribbean Sea",
        "rgb": {
            "r": 0,
            "g": 133,
            "b": 163
        },
        "hex": "0085A3",
        "positionInBook": 1699,
        "pageNumberInBook": "2.053",
        "lab": {
            "l": 49.38,
            "a": -27.97,
            "b": -27.91
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-4733 TPG",
        "name": "Enamel Blue",
        "rgb": {
            "r": 0,
            "g": 127,
            "b": 149
        },
        "hex": "007F95",
        "positionInBook": 1701,
        "pageNumberInBook": "2.053",
        "lab": {
            "l": 46.87,
            "a": -28.83,
            "b": -23.77
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-4816 TPG",
        "name": "Blue Radiance",
        "rgb": {
            "r": 100,
            "g": 200,
            "b": 210
        },
        "hex": "64C8D2",
        "positionInBook": 1703,
        "pageNumberInBook": "2.054",
        "lab": {
            "l": 74.36,
            "a": -28.46,
            "b": -16.22
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-4825 TPG",
        "name": "Blue Curacao",
        "rgb": {
            "r": 54,
            "g": 186,
            "b": 198
        },
        "hex": "36BAC6",
        "positionInBook": 1705,
        "pageNumberInBook": "2.054",
        "lab": {
            "l": 68.5,
            "a": -33.28,
            "b": -18.58
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-4834 TPG",
        "name": "Bluebird",
        "rgb": {
            "r": 0,
            "g": 161,
            "b": 180
        },
        "hex": "00A1B4",
        "positionInBook": 1707,
        "pageNumberInBook": "2.054",
        "lab": {
            "l": 58.66,
            "a": -37.24,
            "b": -23.78
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-4409 TPG",
        "name": "Blue Glow",
        "rgb": {
            "r": 171,
            "g": 210,
            "b": 220
        },
        "hex": "ABD2DC",
        "positionInBook": 1709,
        "pageNumberInBook": "2.055",
        "lab": {
            "l": 81.42,
            "a": -11.96,
            "b": -10.03
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-4315 TPG",
        "name": "Sea Angel",
        "rgb": {
            "r": 154,
            "g": 193,
            "b": 205
        },
        "hex": "9AC1CD",
        "positionInBook": 1711,
        "pageNumberInBook": "2.055",
        "lab": {
            "l": 75.22,
            "a": -11.82,
            "b": -11.04
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-4508 TPG",
        "name": "Stratosphere",
        "rgb": {
            "r": 154,
            "g": 191,
            "b": 202
        },
        "hex": "9ABFCA",
        "positionInBook": 1713,
        "pageNumberInBook": "2.055",
        "lab": {
            "l": 74.62,
            "a": -10.9,
            "b": -10.72
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-4415 TPG",
        "name": "Milky Blue",
        "rgb": {
            "r": 116,
            "g": 169,
            "b": 189
        },
        "hex": "74A9BD",
        "positionInBook": 1715,
        "pageNumberInBook": "2.055",
        "lab": {
            "l": 65.86,
            "a": -15,
            "b": -16.65
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-4308 TPG",
        "name": "Baby Blue",
        "rgb": {
            "r": 179,
            "g": 199,
            "b": 213
        },
        "hex": "B3C7D5",
        "positionInBook": 1717,
        "pageNumberInBook": "2.056",
        "lab": {
            "l": 79.04,
            "a": -5.14,
            "b": -9.68
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-4519 TPG",
        "name": "Delphinium Blue",
        "rgb": {
            "r": 102,
            "g": 157,
            "b": 178
        },
        "hex": "669DB2",
        "positionInBook": 1719,
        "pageNumberInBook": "2.056",
        "lab": {
            "l": 61.34,
            "a": -15.32,
            "b": -17.67
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-4421 TPG",
        "name": "Larkspur",
        "rgb": {
            "r": 66,
            "g": 124,
            "b": 147
        },
        "hex": "427C93",
        "positionInBook": 1721,
        "pageNumberInBook": "2.056",
        "lab": {
            "l": 48.57,
            "a": -14.19,
            "b": -19.47
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-4109 TPG",
        "name": "Arona",
        "rgb": {
            "r": 133,
            "g": 155,
            "b": 166
        },
        "hex": "859BA6",
        "positionInBook": 1723,
        "pageNumberInBook": "2.057",
        "lab": {
            "l": 62.36,
            "a": -5.89,
            "b": -9.14
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-4111 TPG",
        "name": "Citadel",
        "rgb": {
            "r": 117,
            "g": 139,
            "b": 152
        },
        "hex": "758B98",
        "positionInBook": 1725,
        "pageNumberInBook": "2.057",
        "lab": {
            "l": 56.23,
            "a": -5.4,
            "b": -10.34
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-4611 TPG",
        "name": "Saltwater Slide",
        "rgb": {
            "r": 206,
            "g": 231,
            "b": 239
        },
        "hex": "CEE7EF",
        "positionInBook": 1751,
        "pageNumberInBook": "2.061",
        "lab": {
            "l": 89.91,
            "a": -7.62,
            "b": -7.23
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-4515 TPG",
        "name": "Blue Elixir",
        "rgb": {
            "r": 139,
            "g": 205,
            "b": 223
        },
        "hex": "8BCDDF",
        "positionInBook": 1753,
        "pageNumberInBook": "2.061",
        "lab": {
            "l": 78.14,
            "a": -18.54,
            "b": -16.81
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-4511 TPG",
        "name": "Gulf Stream",
        "rgb": {
            "r": 141,
            "g": 198,
            "b": 212
        },
        "hex": "8DC6D4",
        "positionInBook": 1755,
        "pageNumberInBook": "2.061",
        "lab": {
            "l": 75.96,
            "a": -16.92,
            "b": -14.2
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-4716 TPG",
        "name": "Ipanema",
        "rgb": {
            "r": 103,
            "g": 188,
            "b": 197
        },
        "hex": "67BCC5",
        "positionInBook": 1757,
        "pageNumberInBook": "2.061",
        "lab": {
            "l": 70.8,
            "a": -25.57,
            "b": -14.05
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-4720 TPG",
        "name": "Tanager Turquoise",
        "rgb": {
            "r": 149,
            "g": 219,
            "b": 229
        },
        "hex": "95DBE5",
        "positionInBook": 1759,
        "pageNumberInBook": "2.062",
        "lab": {
            "l": 82.78,
            "a": -21.4,
            "b": -13
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-4810 TPG",
        "name": "Limpet Shell",
        "rgb": {
            "r": 153,
            "g": 222,
            "b": 223
        },
        "hex": "99DEDF",
        "positionInBook": 1761,
        "pageNumberInBook": "2.062",
        "lab": {
            "l": 83.66,
            "a": -22.42,
            "b": -8.8
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-4814 TPG",
        "name": "Angel Blue",
        "rgb": {
            "r": 131,
            "g": 198,
            "b": 207
        },
        "hex": "83C6CF",
        "positionInBook": 1763,
        "pageNumberInBook": "2.062",
        "lab": {
            "l": 75.31,
            "a": -20.95,
            "b": -12.49
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-4618 TPG",
        "name": "Waterspout",
        "rgb": {
            "r": 147,
            "g": 219,
            "b": 224
        },
        "hex": "93DBE0",
        "positionInBook": 1765,
        "pageNumberInBook": "2.063",
        "lab": {
            "l": 82.46,
            "a": -22.54,
            "b": -11.12
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-4815 TPG",
        "name": "Leisure Time",
        "rgb": {
            "r": 128,
            "g": 191,
            "b": 197
        },
        "hex": "80BFC5",
        "positionInBook": 1767,
        "pageNumberInBook": "2.063",
        "lab": {
            "l": 73.02,
            "a": -20.56,
            "b": -10.49
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-5123 TPG",
        "name": "Baltic",
        "rgb": {
            "r": 51,
            "g": 155,
            "b": 159
        },
        "hex": "339B9F",
        "positionInBook": 1799,
        "pageNumberInBook": "2.067",
        "lab": {
            "l": 57.71,
            "a": -29.38,
            "b": -12.84
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "11-4606 TPG",
        "name": "Ice Castle",
        "rgb": {
            "r": 219,
            "g": 238,
            "b": 239
        },
        "hex": "DBEEEF",
        "positionInBook": 1801,
        "pageNumberInBook": "2.068",
        "lab": {
            "l": 92.53,
            "a": -6.4,
            "b": -3.1
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-5207 TPG",
        "name": "Salt Air",
        "rgb": {
            "r": 204,
            "g": 236,
            "b": 238
        },
        "hex": "CCECEE",
        "positionInBook": 1803,
        "pageNumberInBook": "2.068",
        "lab": {
            "l": 91.04,
            "a": -10.85,
            "b": -5.1
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-4715 TPG",
        "name": "Water Ballet",
        "rgb": {
            "r": 157,
            "g": 221,
            "b": 226
        },
        "hex": "9DDDE2",
        "positionInBook": 1805,
        "pageNumberInBook": "2.068",
        "lab": {
            "l": 83.7,
            "a": -20.24,
            "b": -10.37
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-5206 TPG",
        "name": "Blue Glass",
        "rgb": {
            "r": 197,
            "g": 225,
            "b": 224
        },
        "hex": "C5E1E0",
        "positionInBook": 1807,
        "pageNumberInBook": "2.069",
        "lab": {
            "l": 87.16,
            "a": -9.59,
            "b": -3.56
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-5808 TPG",
        "name": "Honeydew",
        "rgb": {
            "r": 184,
            "g": 227,
            "b": 210
        },
        "hex": "B8E3D2",
        "positionInBook": 1809,
        "pageNumberInBook": "2.069",
        "lab": {
            "l": 86.5,
            "a": -17.44,
            "b": 2.56
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-5410 TPG",
        "name": "Bleached Aqua",
        "rgb": {
            "r": 178,
            "g": 224,
            "b": 220
        },
        "hex": "B2E0DC",
        "positionInBook": 1811,
        "pageNumberInBook": "2.069",
        "lab": {
            "l": 85.57,
            "a": -16.14,
            "b": -4.12
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-5309 TPG",
        "name": "Pastel Turquoise",
        "rgb": {
            "r": 149,
            "g": 197,
            "b": 198
        },
        "hex": "95C5C6",
        "positionInBook": 1813,
        "pageNumberInBook": "2.069",
        "lab": {
            "l": 75.89,
            "a": -16.13,
            "b": -6.44
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-4305 TPG",
        "name": "Spa Blue",
        "rgb": {
            "r": 213,
            "g": 225,
            "b": 223
        },
        "hex": "D5E1DF",
        "positionInBook": 1815,
        "pageNumberInBook": "2.070",
        "lab": {
            "l": 88.56,
            "a": -4.61,
            "b": -0.66
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-4610 TPG",
        "name": "Whispering Blue",
        "rgb": {
            "r": 200,
            "g": 221,
            "b": 221
        },
        "hex": "C8DDDD",
        "positionInBook": 1817,
        "pageNumberInBook": "2.070",
        "lab": {
            "l": 86.32,
            "a": -7.34,
            "b": -2.91
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-4405 TPG",
        "name": "Misty Blue",
        "rgb": {
            "r": 189,
            "g": 207,
            "b": 206
        },
        "hex": "BDCFCE",
        "positionInBook": 1819,
        "pageNumberInBook": "2.070",
        "lab": {
            "l": 81.71,
            "a": -6.73,
            "b": -1.97
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "11-4805 TPG",
        "name": "Hint of Mint",
        "rgb": {
            "r": 220,
            "g": 235,
            "b": 228
        },
        "hex": "DCEBE4",
        "positionInBook": 1821,
        "pageNumberInBook": "2.071",
        "lab": {
            "l": 91.57,
            "a": -6.33,
            "b": 1.31
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-5204 TPG",
        "name": "Morning Mist",
        "rgb": {
            "r": 202,
            "g": 221,
            "b": 217
        },
        "hex": "CADDD9",
        "positionInBook": 1823,
        "pageNumberInBook": "2.071",
        "lab": {
            "l": 86.34,
            "a": -7.1,
            "b": -0.94
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-4806 TPG",
        "name": "Chalk Blue",
        "rgb": {
            "r": 200,
            "g": 216,
            "b": 215
        },
        "hex": "C8D8D7",
        "positionInBook": 1825,
        "pageNumberInBook": "2.071",
        "lab": {
            "l": 85.07,
            "a": -5.95,
            "b": -1.42
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-5308 TPG",
        "name": "Blue Spruce",
        "rgb": {
            "r": 81,
            "g": 113,
            "b": 110
        },
        "hex": "51716E",
        "positionInBook": 1851,
        "pageNumberInBook": "2.075",
        "lab": {
            "l": 44.89,
            "a": -12.34,
            "b": -2.97
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-5408 TPG",
        "name": "Bistro Green",
        "rgb": {
            "r": 59,
            "g": 88,
            "b": 85
        },
        "hex": "3B5855",
        "positionInBook": 1853,
        "pageNumberInBook": "2.075",
        "lab": {
            "l": 34.73,
            "a": -11.71,
            "b": -2.41
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-5320 TPG",
        "name": "Ponderosa Pine",
        "rgb": {
            "r": 50,
            "g": 77,
            "b": 78
        },
        "hex": "324D4E",
        "positionInBook": 1855,
        "pageNumberInBook": "2.075",
        "lab": {
            "l": 30.56,
            "a": -10.29,
            "b": -4.3
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-4922 TPG",
        "name": "Teal Green",
        "rgb": {
            "r": 39,
            "g": 107,
            "b": 108
        },
        "hex": "276B6C",
        "positionInBook": 1857,
        "pageNumberInBook": "2.076",
        "lab": {
            "l": 40.62,
            "a": -21.47,
            "b": -8.66
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-5413 TPG",
        "name": "Jasper",
        "rgb": {
            "r": 63,
            "g": 99,
            "b": 100
        },
        "hex": "3F6364",
        "positionInBook": 1859,
        "pageNumberInBook": "2.076",
        "lab": {
            "l": 39.03,
            "a": -13.22,
            "b": -5.6
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-5315 TPG",
        "name": "Bayberry",
        "rgb": {
            "r": 52,
            "g": 98,
            "b": 96
        },
        "hex": "346260",
        "positionInBook": 1861,
        "pageNumberInBook": "2.076",
        "lab": {
            "l": 37.8,
            "a": -15.98,
            "b": -4.97
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-4835 TPG",
        "name": "Alexandrite",
        "rgb": {
            "r": 22,
            "g": 109,
            "b": 114
        },
        "hex": "166D72",
        "positionInBook": 1863,
        "pageNumberInBook": "2.077",
        "lab": {
            "l": 41.24,
            "a": -23.83,
            "b": -11.42
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-4918 TPG",
        "name": "Spruced-up",
        "rgb": {
            "r": 37,
            "g": 88,
            "b": 95
        },
        "hex": "25585F",
        "positionInBook": 1865,
        "pageNumberInBook": "2.077",
        "lab": {
            "l": 34.06,
            "a": -15.66,
            "b": -10.14
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-4727 TPG",
        "name": "Green Heron",
        "rgb": {
            "r": 50,
            "g": 91,
            "b": 99
        },
        "hex": "325B63",
        "positionInBook": 1867,
        "pageNumberInBook": "2.077",
        "lab": {
            "l": 35.52,
            "a": -12.89,
            "b": -10.13
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-5721 TPG",
        "name": "Electric Green",
        "rgb": {
            "r": 66,
            "g": 192,
            "b": 168
        },
        "hex": "42C0A8",
        "positionInBook": 1899,
        "pageNumberInBook": "2.082",
        "lab": {
            "l": 70,
            "a": -40.8,
            "b": -0.17
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-5427 TPG",
        "name": "Billiard",
        "rgb": {
            "r": 0,
            "g": 174,
            "b": 153
        },
        "hex": "00AE99",
        "positionInBook": 1901,
        "pageNumberInBook": "2.082",
        "lab": {
            "l": 62.78,
            "a": -44.42,
            "b": -2.72
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-5533 TPG",
        "name": "Arcadia",
        "rgb": {
            "r": 0,
            "g": 165,
            "b": 145
        },
        "hex": "00A591",
        "positionInBook": 1903,
        "pageNumberInBook": "2.082",
        "lab": {
            "l": 59.5,
            "a": -44.72,
            "b": -3.24
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-6009 TPG",
        "name": "Brook Green",
        "rgb": {
            "r": 169,
            "g": 222,
            "b": 204
        },
        "hex": "A9DECC",
        "positionInBook": 1905,
        "pageNumberInBook": "2.083",
        "lab": {
            "l": 84.09,
            "a": -21.17,
            "b": 2.08
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-5714 TPG",
        "name": "Beveled Glass",
        "rgb": {
            "r": 125,
            "g": 205,
            "b": 182
        },
        "hex": "7DCDB6",
        "positionInBook": 1907,
        "pageNumberInBook": "2.083",
        "lab": {
            "l": 76.39,
            "a": -30.21,
            "b": 1.9
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-5819 TPG",
        "name": "Spearmint",
        "rgb": {
            "r": 102,
            "g": 192,
            "b": 164
        },
        "hex": "66C0A4",
        "positionInBook": 1909,
        "pageNumberInBook": "2.083",
        "lab": {
            "l": 71.23,
            "a": -34.32,
            "b": 4.29
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-5728 TPG",
        "name": "Mint Leaf",
        "rgb": {
            "r": 13,
            "g": 182,
            "b": 149
        },
        "hex": "0DB695",
        "positionInBook": 1911,
        "pageNumberInBook": "2.083",
        "lab": {
            "l": 65.35,
            "a": -46.09,
            "b": 3.28
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-5507 TPG",
        "name": "Bay",
        "rgb": {
            "r": 189,
            "g": 231,
            "b": 216
        },
        "hex": "BDE7D8",
        "positionInBook": 1913,
        "pageNumberInBook": "2.084",
        "lab": {
            "l": 88.09,
            "a": -16.78,
            "b": 1.92
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-5412 TPG",
        "name": "Beach Glass",
        "rgb": {
            "r": 151,
            "g": 223,
            "b": 206
        },
        "hex": "97DFCE",
        "positionInBook": 1915,
        "pageNumberInBook": "2.084",
        "lab": {
            "l": 83.44,
            "a": -26.8,
            "b": -0.07
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-5420 TPG",
        "name": "Cockatoo",
        "rgb": {
            "r": 94,
            "g": 204,
            "b": 185
        },
        "hex": "5ECCB9",
        "positionInBook": 1917,
        "pageNumberInBook": "2.084",
        "lab": {
            "l": 74.83,
            "a": -36.96,
            "b": -2.3
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-5406 TPG",
        "name": "Opal Blue",
        "rgb": {
            "r": 196,
            "g": 222,
            "b": 215
        },
        "hex": "C4DED7",
        "positionInBook": 1919,
        "pageNumberInBook": "2.085",
        "lab": {
            "l": 86.09,
            "a": -9.88,
            "b": -0.11
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-5505 TPG",
        "name": "Glacier",
        "rgb": {
            "r": 193,
            "g": 218,
            "b": 212
        },
        "hex": "C1DAD4",
        "positionInBook": 1921,
        "pageNumberInBook": "2.085",
        "lab": {
            "l": 85.05,
            "a": -9.96,
            "b": -0.31
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-5413 TPG",
        "name": "Holiday",
        "rgb": {
            "r": 123,
            "g": 194,
            "b": 179
        },
        "hex": "7BC2B3",
        "positionInBook": 1923,
        "pageNumberInBook": "2.085",
        "lab": {
            "l": 72.93,
            "a": -25.25,
            "b": -1.36
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-5711 TPG",
        "name": "Dusty Jade Green",
        "rgb": {
            "r": 124,
            "g": 183,
            "b": 163
        },
        "hex": "7CB7A3",
        "positionInBook": 1925,
        "pageNumberInBook": "2.085",
        "lab": {
            "l": 69.7,
            "a": -22.88,
            "b": 2.65
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-5624 TPG",
        "name": "Shady Glade",
        "rgb": {
            "r": 41,
            "g": 121,
            "b": 105
        },
        "hex": "297969",
        "positionInBook": 1951,
        "pageNumberInBook": "2.089",
        "lab": {
            "l": 45.44,
            "a": -28.78,
            "b": 0.43
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-5322 TPG",
        "name": "Alpine Green",
        "rgb": {
            "r": 35,
            "g": 103,
            "b": 97
        },
        "hex": "236761",
        "positionInBook": 1953,
        "pageNumberInBook": "2.089",
        "lab": {
            "l": 38.75,
            "a": -22.7,
            "b": -4.52
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-5440 TPG",
        "name": "Ocean Floor",
        "rgb": {
            "r": 0,
            "g": 136,
            "b": 124
        },
        "hex": "00887C",
        "positionInBook": 1955,
        "pageNumberInBook": "2.090",
        "lab": {
            "l": 49.76,
            "a": -35.49,
            "b": -5.18
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-5527 TPG",
        "name": "Sporting Green",
        "rgb": {
            "r": 0,
            "g": 124,
            "b": 109
        },
        "hex": "007C6D",
        "positionInBook": 1957,
        "pageNumberInBook": "2.090",
        "lab": {
            "l": 44.8,
            "a": -37.85,
            "b": -3.98
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-5425 TPG",
        "name": "Bear Grass",
        "rgb": {
            "r": 0,
            "g": 114,
            "b": 101
        },
        "hex": "007265",
        "positionInBook": 1959,
        "pageNumberInBook": "2.090",
        "lab": {
            "l": 41.98,
            "a": -31.04,
            "b": -2.45
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-5421 TPG",
        "name": "Sea Green",
        "rgb": {
            "r": 43,
            "g": 160,
            "b": 139
        },
        "hex": "2BA08B",
        "positionInBook": 1961,
        "pageNumberInBook": "2.091",
        "lab": {
            "l": 58.73,
            "a": -37.31,
            "b": -0.47
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-5638 TPG",
        "name": "Vivid Green",
        "rgb": {
            "r": 0,
            "g": 163,
            "b": 134
        },
        "hex": "00A386",
        "positionInBook": 1963,
        "pageNumberInBook": "2.091",
        "lab": {
            "l": 58.44,
            "a": -48.04,
            "b": 1.57
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-5841 TPG",
        "name": "Pepper Green",
        "rgb": {
            "r": 0,
            "g": 133,
            "b": 106
        },
        "hex": "00856A",
        "positionInBook": 1965,
        "pageNumberInBook": "2.091",
        "lab": {
            "l": 47.7,
            "a": -43.98,
            "b": 2.08
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-5642 TPG",
        "name": "Golf Green",
        "rgb": {
            "r": 0,
            "g": 145,
            "b": 112
        },
        "hex": "009170",
        "positionInBook": 1967,
        "pageNumberInBook": "2.091",
        "lab": {
            "l": 51.53,
            "a": -49.29,
            "b": 4.09
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "11-4802 TPG",
        "name": "Summer Shower",
        "rgb": {
            "r": 228,
            "g": 233,
            "b": 225
        },
        "hex": "E4E9E1",
        "positionInBook": 1999,
        "pageNumberInBook": "2.096",
        "lab": {
            "l": 91.64,
            "a": -2.57,
            "b": 2.81
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-5203 TPG",
        "name": "Murmur",
        "rgb": {
            "r": 211,
            "g": 220,
            "b": 214
        },
        "hex": "D3DCD6",
        "positionInBook": 2001,
        "pageNumberInBook": "2.096",
        "lab": {
            "l": 86.92,
            "a": -3.93,
            "b": 1.49
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-5404 TPG",
        "name": "Whisper Green",
        "rgb": {
            "r": 226,
            "g": 230,
            "b": 217
        },
        "hex": "E2E6D9",
        "positionInBook": 2003,
        "pageNumberInBook": "2.097",
        "lab": {
            "l": 90.59,
            "a": -2.71,
            "b": 5.56
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-0107 TPG",
        "name": "Dewkist",
        "rgb": {
            "r": 193,
            "g": 208,
            "b": 190
        },
        "hex": "C1D0BE",
        "positionInBook": 2005,
        "pageNumberInBook": "2.097",
        "lab": {
            "l": 81.86,
            "a": -7.79,
            "b": 6.52
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-6107 TPG",
        "name": "Green Lily",
        "rgb": {
            "r": 189,
            "g": 206,
            "b": 189
        },
        "hex": "BDCEBD",
        "positionInBook": 2007,
        "pageNumberInBook": "2.097",
        "lab": {
            "l": 80.93,
            "a": -8.34,
            "b": 5.64
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-6008 TPG",
        "name": "Subtle Green",
        "rgb": {
            "r": 175,
            "g": 201,
            "b": 186
        },
        "hex": "AFC9BA",
        "positionInBook": 2009,
        "pageNumberInBook": "2.097",
        "lab": {
            "l": 78.49,
            "a": -11.23,
            "b": 3.76
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-5305 TPG",
        "name": "Pale Aqua",
        "rgb": {
            "r": 192,
            "g": 205,
            "b": 196
        },
        "hex": "C0CDC4",
        "positionInBook": 2011,
        "pageNumberInBook": "2.098",
        "lab": {
            "l": 81.06,
            "a": -5.45,
            "b": 2.51
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-5706 TPG",
        "name": "Silt Green",
        "rgb": {
            "r": 171,
            "g": 192,
            "b": 181
        },
        "hex": "ABC0B5",
        "positionInBook": 2013,
        "pageNumberInBook": "2.098",
        "lab": {
            "l": 75.77,
            "a": -9.53,
            "b": 2.8
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-5907 TPG",
        "name": "Granite Green",
        "rgb": {
            "r": 133,
            "g": 162,
            "b": 149
        },
        "hex": "85A295",
        "positionInBook": 2015,
        "pageNumberInBook": "2.098",
        "lab": {
            "l": 63.96,
            "a": -13.09,
            "b": 2.91
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-5403 TPG",
        "name": "Blue Flower",
        "rgb": {
            "r": 209,
            "g": 220,
            "b": 215
        },
        "hex": "D1DCD7",
        "positionInBook": 2017,
        "pageNumberInBook": "2.099",
        "lab": {
            "l": 86.75,
            "a": -4.59,
            "b": 1.13
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-4503 TPG",
        "name": "Metal",
        "rgb": {
            "r": 178,
            "g": 188,
            "b": 185
        },
        "hex": "B2BCB9",
        "positionInBook": 2019,
        "pageNumberInBook": "2.099",
        "lab": {
            "l": 75.48,
            "a": -4.4,
            "b": 0.45
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-4704 TPG",
        "name": "Pigeon",
        "rgb": {
            "r": 166,
            "g": 176,
            "b": 171
        },
        "hex": "A6B0AB",
        "positionInBook": 2021,
        "pageNumberInBook": "2.099",
        "lab": {
            "l": 70.91,
            "a": -4.55,
            "b": 1
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-5808 TPG",
        "name": "Iceberg Green",
        "rgb": {
            "r": 137,
            "g": 156,
            "b": 146
        },
        "hex": "899C92",
        "positionInBook": 2023,
        "pageNumberInBook": "2.099",
        "lab": {
            "l": 62.64,
            "a": -8.88,
            "b": 2.61
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-6009 TPG",
        "name": "Laurel Wreath",
        "rgb": {
            "r": 98,
            "g": 114,
            "b": 105
        },
        "hex": "627269",
        "positionInBook": 2025,
        "pageNumberInBook": "2.100",
        "lab": {
            "l": 46.28,
            "a": -7.39,
            "b": 2.24
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-0509 TPG",
        "name": "Rosin",
        "rgb": {
            "r": 68,
            "g": 72,
            "b": 65
        },
        "hex": "444841",
        "positionInBook": 2051,
        "pageNumberInBook": "2.103",
        "lab": {
            "l": 29.97,
            "a": -2.93,
            "b": 3.26
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-0613 TPG",
        "name": "Vetiver",
        "rgb": {
            "r": 128,
            "g": 129,
            "b": 116
        },
        "hex": "808174",
        "positionInBook": 2053,
        "pageNumberInBook": "2.104",
        "lab": {
            "l": 53.51,
            "a": -2.37,
            "b": 6.98
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0517 TPG",
        "name": "Tea Leaf",
        "rgb": {
            "r": 106,
            "g": 105,
            "b": 96
        },
        "hex": "6A6960",
        "positionInBook": 2055,
        "pageNumberInBook": "2.104",
        "lab": {
            "l": 44.27,
            "a": -0.66,
            "b": 5.32
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-0404 TPG",
        "name": "Kambaba",
        "rgb": {
            "r": 84,
            "g": 87,
            "b": 79
        },
        "hex": "54574F",
        "positionInBook": 2057,
        "pageNumberInBook": "2.104",
        "lab": {
            "l": 36.41,
            "a": -2.53,
            "b": 3.93
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-6304 TPG",
        "name": "Pussywillow Gray",
        "rgb": {
            "r": 174,
            "g": 175,
            "b": 164
        },
        "hex": "AEAFA4",
        "positionInBook": 2059,
        "pageNumberInBook": "2.105",
        "lab": {
            "l": 71.08,
            "a": -1.8,
            "b": 5.44
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-4306 TPG",
        "name": "Belgian Block",
        "rgb": {
            "r": 166,
            "g": 171,
            "b": 166
        },
        "hex": "A6ABA6",
        "positionInBook": 2061,
        "pageNumberInBook": "2.105",
        "lab": {
            "l": 69.45,
            "a": -2.19,
            "b": 1.45
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-0209 TPG",
        "name": "Forest Fog",
        "rgb": {
            "r": 145,
            "g": 147,
            "b": 141
        },
        "hex": "91938D",
        "positionInBook": 2063,
        "pageNumberInBook": "2.105",
        "lab": {
            "l": 60.59,
            "a": -1.68,
            "b": 3.05
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0516 TPG",
        "name": "Smokey Olive",
        "rgb": {
            "r": 110,
            "g": 109,
            "b": 99
        },
        "hex": "6E6D63",
        "positionInBook": 2065,
        "pageNumberInBook": "2.105",
        "lab": {
            "l": 45.88,
            "a": -0.58,
            "b": 5.64
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-6105 TPG",
        "name": "Celadon Tint",
        "rgb": {
            "r": 205,
            "g": 208,
            "b": 192
        },
        "hex": "CDD0C0",
        "positionInBook": 2067,
        "pageNumberInBook": "2.106",
        "lab": {
            "l": 82.89,
            "a": -2.99,
            "b": 7.18
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-6324 TPG",
        "name": "Jadesheen",
        "rgb": {
            "r": 115,
            "g": 160,
            "b": 120
        },
        "hex": "73A078",
        "positionInBook": 2099,
        "pageNumberInBook": "2.110",
        "lab": {
            "l": 61.5,
            "a": -21.77,
            "b": 14.67
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-6114 TPG",
        "name": "Hemlock",
        "rgb": {
            "r": 150,
            "g": 193,
            "b": 163
        },
        "hex": "96C1A3",
        "positionInBook": 2101,
        "pageNumberInBook": "2.111",
        "lab": {
            "l": 73.99,
            "a": -18.9,
            "b": 8.95
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-6120 TPG",
        "name": "Ming",
        "rgb": {
            "r": 128,
            "g": 181,
            "b": 143
        },
        "hex": "80B58F",
        "positionInBook": 2103,
        "pageNumberInBook": "2.111",
        "lab": {
            "l": 68.78,
            "a": -25.06,
            "b": 12.59
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-6329 TPG",
        "name": "Absinthe Green",
        "rgb": {
            "r": 115,
            "g": 181,
            "b": 132
        },
        "hex": "73B584",
        "positionInBook": 2105,
        "pageNumberInBook": "2.111",
        "lab": {
            "l": 68.04,
            "a": -31.68,
            "b": 17.75
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0110 TPG",
        "name": "English Ivy",
        "rgb": {
            "r": 104,
            "g": 136,
            "b": 99
        },
        "hex": "688863",
        "positionInBook": 2107,
        "pageNumberInBook": "2.111",
        "lab": {
            "l": 53.32,
            "a": -16.5,
            "b": 14.89
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-6127 TPG",
        "name": "Greenbriar",
        "rgb": {
            "r": 79,
            "g": 157,
            "b": 115
        },
        "hex": "4F9D73",
        "positionInBook": 2109,
        "pageNumberInBook": "2.112",
        "lab": {
            "l": 58.39,
            "a": -32.85,
            "b": 12.65
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-6030 TPG",
        "name": "Jelly Bean",
        "rgb": {
            "r": 0,
            "g": 138,
            "b": 89
        },
        "hex": "008A59",
        "positionInBook": 2111,
        "pageNumberInBook": "2.112",
        "lab": {
            "l": 49.8,
            "a": -43.56,
            "b": 14.94
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-6026 TPG",
        "name": "Verdant Green",
        "rgb": {
            "r": 41,
            "g": 111,
            "b": 83
        },
        "hex": "296F53",
        "positionInBook": 2113,
        "pageNumberInBook": "2.112",
        "lab": {
            "l": 41.46,
            "a": -28.14,
            "b": 7.51
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-6154 TPG",
        "name": "Green Bee",
        "rgb": {
            "r": 25,
            "g": 146,
            "b": 85
        },
        "hex": "199255",
        "positionInBook": 2115,
        "pageNumberInBook": "2.113",
        "lab": {
            "l": 52.72,
            "a": -43.89,
            "b": 20.96
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-6031 TPG",
        "name": "First Tee",
        "rgb": {
            "r": 0,
            "g": 124,
            "b": 76
        },
        "hex": "007C4C",
        "positionInBook": 2117,
        "pageNumberInBook": "2.113",
        "lab": {
            "l": 44.68,
            "a": -41.22,
            "b": 15.36
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-6026 TPG",
        "name": "Abundant Green",
        "rgb": {
            "r": 35,
            "g": 110,
            "b": 72
        },
        "hex": "236E48",
        "positionInBook": 2119,
        "pageNumberInBook": "2.113",
        "lab": {
            "l": 40.89,
            "a": -32.06,
            "b": 13.47
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-6027 TPG",
        "name": "Green Jacket",
        "rgb": {
            "r": 32,
            "g": 102,
            "b": 78
        },
        "hex": "20664E",
        "positionInBook": 2121,
        "pageNumberInBook": "2.113",
        "lab": {
            "l": 38.13,
            "a": -27.51,
            "b": 6.11
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-0221 TPG",
        "name": "Pistachio Green",
        "rgb": {
            "r": 171,
            "g": 214,
            "b": 158
        },
        "hex": "ABD69E",
        "positionInBook": 2123,
        "pageNumberInBook": "2.114",
        "lab": {
            "l": 81.32,
            "a": -22.7,
            "b": 22.12
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-6340 TPG",
        "name": "Spring Bouquet",
        "rgb": {
            "r": 111,
            "g": 207,
            "b": 144
        },
        "hex": "6FCF90",
        "positionInBook": 2125,
        "pageNumberInBook": "2.114",
        "lab": {
            "l": 75.59,
            "a": -40.83,
            "b": 20.9
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-0340 TPG",
        "name": "Kelp Forest",
        "rgb": {
            "r": 117,
            "g": 140,
            "b": 62
        },
        "hex": "758C3E",
        "positionInBook": 2151,
        "pageNumberInBook": "2.118",
        "lab": {
            "l": 55.04,
            "a": -17.7,
            "b": 37.65
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0230 TPG",
        "name": "Banana Palm",
        "rgb": {
            "r": 87,
            "g": 115,
            "b": 64
        },
        "hex": "577340",
        "positionInBook": 2153,
        "pageNumberInBook": "2.118",
        "lab": {
            "l": 45.16,
            "a": -18.44,
            "b": 24.35
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-0231 TPG",
        "name": "Forest Elf",
        "rgb": {
            "r": 72,
            "g": 96,
            "b": 63
        },
        "hex": "48603F",
        "positionInBook": 2155,
        "pageNumberInBook": "2.118",
        "lab": {
            "l": 37.92,
            "a": -15.57,
            "b": 15.83
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-0331 TPG",
        "name": "Sap Green",
        "rgb": {
            "r": 177,
            "g": 206,
            "b": 134
        },
        "hex": "B1CE86",
        "positionInBook": 2157,
        "pageNumberInBook": "2.119",
        "lab": {
            "l": 79.19,
            "a": -18.59,
            "b": 31.68
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-0235 TPG",
        "name": "Kiwi",
        "rgb": {
            "r": 124,
            "g": 172,
            "b": 95
        },
        "hex": "7CAC5F",
        "positionInBook": 2159,
        "pageNumberInBook": "2.119",
        "lab": {
            "l": 65.26,
            "a": -26.95,
            "b": 32.95
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-0230 TPG",
        "name": "Forest Green",
        "rgb": {
            "r": 106,
            "g": 142,
            "b": 88
        },
        "hex": "6A8E58",
        "positionInBook": 2161,
        "pageNumberInBook": "2.119",
        "lab": {
            "l": 55.11,
            "a": -20.72,
            "b": 23.67
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0125 TPG",
        "name": "Artichoke Green",
        "rgb": {
            "r": 86,
            "g": 116,
            "b": 81
        },
        "hex": "567451",
        "positionInBook": 2163,
        "pageNumberInBook": "2.119",
        "lab": {
            "l": 45.44,
            "a": -16.08,
            "b": 14.53
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-6437 TPG",
        "name": "Grass Green",
        "rgb": {
            "r": 125,
            "g": 180,
            "b": 108
        },
        "hex": "7DB46C",
        "positionInBook": 2165,
        "pageNumberInBook": "2.120",
        "lab": {
            "l": 67.87,
            "a": -29.46,
            "b": 29.34
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-6428 TPG",
        "name": "Green Tea",
        "rgb": {
            "r": 129,
            "g": 167,
            "b": 110
        },
        "hex": "81A76E",
        "positionInBook": 2167,
        "pageNumberInBook": "2.120",
        "lab": {
            "l": 64.36,
            "a": -21.81,
            "b": 24.42
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0313 TPG",
        "name": "Seafoam Green",
        "rgb": {
            "r": 201,
            "g": 217,
            "b": 179
        },
        "hex": "C9D9B3",
        "positionInBook": 2199,
        "pageNumberInBook": "2.125",
        "lab": {
            "l": 84.59,
            "a": -9.97,
            "b": 16.32
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-0319 TPG",
        "name": "Shadow Lime",
        "rgb": {
            "r": 211,
            "g": 224,
            "b": 156
        },
        "hex": "D3E09C",
        "positionInBook": 2201,
        "pageNumberInBook": "2.125",
        "lab": {
            "l": 86.76,
            "a": -11.81,
            "b": 30.92
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-0317 TPG",
        "name": "Lily Green",
        "rgb": {
            "r": 196,
            "g": 209,
            "b": 154
        },
        "hex": "C4D19A",
        "positionInBook": 2203,
        "pageNumberInBook": "2.125",
        "lab": {
            "l": 81.72,
            "a": -10.94,
            "b": 25.3
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-0531 TPG",
        "name": "Sweet Pea",
        "rgb": {
            "r": 165,
            "g": 173,
            "b": 112
        },
        "hex": "A5AD70",
        "positionInBook": 2205,
        "pageNumberInBook": "2.125",
        "lab": {
            "l": 68.83,
            "a": -9.35,
            "b": 29.73
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0315 TPG",
        "name": "White Jade",
        "rgb": {
            "r": 212,
            "g": 219,
            "b": 179
        },
        "hex": "D4DBB3",
        "positionInBook": 2207,
        "pageNumberInBook": "2.126",
        "lab": {
            "l": 86.07,
            "a": -7.17,
            "b": 18.47
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-0116 TPG",
        "name": "Margarita",
        "rgb": {
            "r": 183,
            "g": 199,
            "b": 145
        },
        "hex": "B7C791",
        "positionInBook": 2209,
        "pageNumberInBook": "2.126",
        "lab": {
            "l": 77.92,
            "a": -12.78,
            "b": 25.02
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-0525 TPG",
        "name": "Weeping Willow",
        "rgb": {
            "r": 186,
            "g": 185,
            "b": 132
        },
        "hex": "BAB984",
        "positionInBook": 2211,
        "pageNumberInBook": "2.126",
        "lab": {
            "l": 74.14,
            "a": -4.4,
            "b": 26.35
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0435 TPG",
        "name": "Daiquiri Green",
        "rgb": {
            "r": 205,
            "g": 215,
            "b": 127
        },
        "hex": "CDD77F",
        "positionInBook": 2213,
        "pageNumberInBook": "2.127",
        "lab": {
            "l": 83.73,
            "a": -12.78,
            "b": 41.29
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-0445 TPG",
        "name": "Bright Chartreuse",
        "rgb": {
            "r": 177,
            "g": 188,
            "b": 85
        },
        "hex": "B1BC55",
        "positionInBook": 2215,
        "pageNumberInBook": "2.127",
        "lab": {
            "l": 73.62,
            "a": -13.93,
            "b": 48.65
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-0332 TPG",
        "name": "Leaf Green",
        "rgb": {
            "r": 166,
            "g": 177,
            "b": 114
        },
        "hex": "A6B172",
        "positionInBook": 2217,
        "pageNumberInBook": "2.127",
        "lab": {
            "l": 70.06,
            "a": -10.89,
            "b": 30.01
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-0439 TPG",
        "name": "Spinach Green",
        "rgb": {
            "r": 144,
            "g": 158,
            "b": 86
        },
        "hex": "909E56",
        "positionInBook": 2219,
        "pageNumberInBook": "2.127",
        "lab": {
            "l": 62.76,
            "a": -12.82,
            "b": 34.64
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-0443 TPG",
        "name": "Love Bird",
        "rgb": {
            "r": 197,
            "g": 222,
            "b": 72
        },
        "hex": "C5DE48",
        "positionInBook": 2221,
        "pageNumberInBook": "2.128",
        "lab": {
            "l": 84.41,
            "a": -22.42,
            "b": 65.55
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-0550 TPG",
        "name": "Lime Punch",
        "rgb": {
            "r": 190,
            "g": 213,
            "b": 66
        },
        "hex": "BED542",
        "positionInBook": 2223,
        "pageNumberInBook": "2.128",
        "lab": {
            "l": 81.55,
            "a": -22.33,
            "b": 65.53
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-0230 TPG",
        "name": "Macaw Green",
        "rgb": {
            "r": 158,
            "g": 183,
            "b": 73
        },
        "hex": "9EB749",
        "positionInBook": 2225,
        "pageNumberInBook": "2.128",
        "lab": {
            "l": 70.69,
            "a": -20.27,
            "b": 50.41
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0607 TPG",
        "name": "Green Essence",
        "rgb": {
            "r": 230,
            "g": 230,
            "b": 199
        },
        "hex": "E6E6C7",
        "positionInBook": 2251,
        "pageNumberInBook": "2.132",
        "lab": {
            "l": 90.75,
            "a": -3.71,
            "b": 15.08
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0524 TPG",
        "name": "Citron",
        "rgb": {
            "r": 222,
            "g": 222,
            "b": 157
        },
        "hex": "DEDE9D",
        "positionInBook": 2253,
        "pageNumberInBook": "2.132",
        "lab": {
            "l": 87.3,
            "a": -6.68,
            "b": 31.54
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "11-0205 TPG",
        "name": "Glass Green",
        "rgb": {
            "r": 238,
            "g": 237,
            "b": 209
        },
        "hex": "EEEDD1",
        "positionInBook": 2255,
        "pageNumberInBook": "2.133",
        "lab": {
            "l": 93.17,
            "a": -2.77,
            "b": 13.2
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0418 TPG",
        "name": "Hay",
        "rgb": {
            "r": 214,
            "g": 206,
            "b": 165
        },
        "hex": "D6CEA5",
        "positionInBook": 2257,
        "pageNumberInBook": "2.133",
        "lab": {
            "l": 82.57,
            "a": -1.7,
            "b": 21.38
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-0333 TPG",
        "name": "Lima Bean",
        "rgb": {
            "r": 221,
            "g": 211,
            "b": 142
        },
        "hex": "DDD38E",
        "positionInBook": 2259,
        "pageNumberInBook": "2.133",
        "lab": {
            "l": 84.18,
            "a": -3.44,
            "b": 35.34
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-0739 TPG",
        "name": "Cream Gold",
        "rgb": {
            "r": 224,
            "g": 197,
            "b": 104
        },
        "hex": "E0C568",
        "positionInBook": 2261,
        "pageNumberInBook": "2.133",
        "lab": {
            "l": 80.51,
            "a": 1.73,
            "b": 49.64
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "11-0617 TPG",
        "name": "Transparent Yellow",
        "rgb": {
            "r": 246,
            "g": 237,
            "b": 195
        },
        "hex": "F6EDC3",
        "positionInBook": 2263,
        "pageNumberInBook": "2.134",
        "lab": {
            "l": 93.72,
            "a": -0.96,
            "b": 21.17
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0619 TPG",
        "name": "Dusty Yellow",
        "rgb": {
            "r": 213,
            "g": 208,
            "b": 158
        },
        "hex": "D5D09E",
        "positionInBook": 2265,
        "pageNumberInBook": "2.134",
        "lab": {
            "l": 83.01,
            "a": -3.14,
            "b": 25.19
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-0826 TPG",
        "name": "Pampas",
        "rgb": {
            "r": 209,
            "g": 189,
            "b": 129
        },
        "hex": "D1BD81",
        "positionInBook": 2267,
        "pageNumberInBook": "2.134",
        "lab": {
            "l": 77.43,
            "a": 1.34,
            "b": 33.31
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-0532 TPG",
        "name": "Moss",
        "rgb": {
            "r": 155,
            "g": 155,
            "b": 91
        },
        "hex": "9B9B5B",
        "positionInBook": 2299,
        "pageNumberInBook": "2.139",
        "lab": {
            "l": 62.83,
            "a": -6.78,
            "b": 32.66
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0530 TPG",
        "name": "Going Green",
        "rgb": {
            "r": 126,
            "g": 133,
            "b": 69
        },
        "hex": "7E8545",
        "positionInBook": 2301,
        "pageNumberInBook": "2.139",
        "lab": {
            "l": 53.82,
            "a": -9.26,
            "b": 33.12
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0538 TPG",
        "name": "Woodbine",
        "rgb": {
            "r": 130,
            "g": 133,
            "b": 62
        },
        "hex": "82853E",
        "positionInBook": 2303,
        "pageNumberInBook": "2.139",
        "lab": {
            "l": 54.24,
            "a": -8.62,
            "b": 37.14
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-0434 TPG",
        "name": "Green Banana",
        "rgb": {
            "r": 182,
            "g": 187,
            "b": 113
        },
        "hex": "B6BB71",
        "positionInBook": 2305,
        "pageNumberInBook": "2.140",
        "lab": {
            "l": 74.01,
            "a": -9.15,
            "b": 35.7
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-0531 TPG",
        "name": "Perfect Pear",
        "rgb": {
            "r": 148,
            "g": 144,
            "b": 73
        },
        "hex": "949049",
        "positionInBook": 2307,
        "pageNumberInBook": "2.140",
        "lab": {
            "l": 58.9,
            "a": -5.31,
            "b": 37.47
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0428 TPG",
        "name": "Peat Moss",
        "rgb": {
            "r": 112,
            "g": 114,
            "b": 68
        },
        "hex": "707244",
        "positionInBook": 2309,
        "pageNumberInBook": "2.140",
        "lab": {
            "l": 46.92,
            "a": -6.11,
            "b": 25.21
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-0425 TPG",
        "name": "Beechnut",
        "rgb": {
            "r": 189,
            "g": 190,
            "b": 136
        },
        "hex": "BDBE88",
        "positionInBook": 2311,
        "pageNumberInBook": "2.141",
        "lab": {
            "l": 75.85,
            "a": -6.29,
            "b": 26.92
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-0324 TPG",
        "name": "Epsom",
        "rgb": {
            "r": 130,
            "g": 147,
            "b": 104
        },
        "hex": "829368",
        "positionInBook": 2313,
        "pageNumberInBook": "2.141",
        "lab": {
            "l": 58.49,
            "a": -11.52,
            "b": 20.02
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0324 TPG",
        "name": "Calliste Green",
        "rgb": {
            "r": 123,
            "g": 128,
            "b": 89
        },
        "hex": "7B8059",
        "positionInBook": 2315,
        "pageNumberInBook": "2.141",
        "lab": {
            "l": 52.35,
            "a": -6.88,
            "b": 20.39
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0435 TPG",
        "name": "Calla Green",
        "rgb": {
            "r": 112,
            "g": 118,
            "b": 71
        },
        "hex": "707647",
        "positionInBook": 2317,
        "pageNumberInBook": "2.141",
        "lab": {
            "l": 48.14,
            "a": -8.32,
            "b": 25.22
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-0216 TPG",
        "name": "Lint",
        "rgb": {
            "r": 183,
            "g": 190,
            "b": 154
        },
        "hex": "B7BE9A",
        "positionInBook": 2319,
        "pageNumberInBook": "2.142",
        "lab": {
            "l": 75.6,
            "a": -6.42,
            "b": 16.58
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-0525 TPG",
        "name": "Mosstone",
        "rgb": {
            "r": 139,
            "g": 141,
            "b": 104
        },
        "hex": "8B8D68",
        "positionInBook": 2321,
        "pageNumberInBook": "2.142",
        "lab": {
            "l": 57.67,
            "a": -4.47,
            "b": 18.69
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0228 TPG",
        "name": "Pesto",
        "rgb": {
            "r": 95,
            "g": 105,
            "b": 70
        },
        "hex": "5F6946",
        "positionInBook": 2323,
        "pageNumberInBook": "2.142",
        "lab": {
            "l": 42.7,
            "a": -9.31,
            "b": 18.59
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-0523 TPG",
        "name": "Winter Pear",
        "rgb": {
            "r": 174,
            "g": 179,
            "b": 133
        },
        "hex": "AEB385",
        "positionInBook": 2325,
        "pageNumberInBook": "2.143",
        "lab": {
            "l": 71.68,
            "a": -7.42,
            "b": 22.78
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-0511 TPG",
        "name": "Grape Leaf",
        "rgb": {
            "r": 90,
            "g": 91,
            "b": 79
        },
        "hex": "5A5B4F",
        "positionInBook": 2351,
        "pageNumberInBook": "2.146",
        "lab": {
            "l": 38.31,
            "a": -2.49,
            "b": 6.93
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-0518 TPG",
        "name": "Gray Green",
        "rgb": {
            "r": 162,
            "g": 157,
            "b": 125
        },
        "hex": "A29D7D",
        "positionInBook": 2353,
        "pageNumberInBook": "2.147",
        "lab": {
            "l": 64.58,
            "a": -2.04,
            "b": 17.8
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-0627 TPG",
        "name": "Dried Herb",
        "rgb": {
            "r": 138,
            "g": 128,
            "b": 97
        },
        "hex": "8A8061",
        "positionInBook": 2355,
        "pageNumberInBook": "2.147",
        "lab": {
            "l": 54.01,
            "a": 0.71,
            "b": 18.6
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0825 TPG",
        "name": "Nutria",
        "rgb": {
            "r": 117,
            "g": 106,
            "b": 76
        },
        "hex": "756A4C",
        "positionInBook": 2357,
        "pageNumberInBook": "2.147",
        "lab": {
            "l": 45.21,
            "a": 1,
            "b": 18.33
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0625 TPG",
        "name": "Martini Olive",
        "rgb": {
            "r": 119,
            "g": 111,
            "b": 87
        },
        "hex": "776F57",
        "positionInBook": 2359,
        "pageNumberInBook": "2.147",
        "lab": {
            "l": 47.14,
            "a": 0.43,
            "b": 14.68
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-0318 TPG",
        "name": "Sage Green",
        "rgb": {
            "r": 175,
            "g": 172,
            "b": 135
        },
        "hex": "AFAC87",
        "positionInBook": 2361,
        "pageNumberInBook": "2.148",
        "lab": {
            "l": 69.86,
            "a": -3.44,
            "b": 19.52
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-0619 TPG",
        "name": "Overland Trek",
        "rgb": {
            "r": 140,
            "g": 133,
            "b": 117
        },
        "hex": "8C8575",
        "positionInBook": 2363,
        "pageNumberInBook": "2.148",
        "lab": {
            "l": 55.76,
            "a": 1.28,
            "b": 9.45
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-0620 TPG",
        "name": "Aloe",
        "rgb": {
            "r": 134,
            "g": 125,
            "b": 101
        },
        "hex": "867D65",
        "positionInBook": 2365,
        "pageNumberInBook": "2.148",
        "lab": {
            "l": 52.79,
            "a": 0.52,
            "b": 14.33
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-0522 TPG",
        "name": "Pale Olive Green",
        "rgb": {
            "r": 183,
            "g": 172,
            "b": 138
        },
        "hex": "B7AC8A",
        "positionInBook": 2367,
        "pageNumberInBook": "2.149",
        "lab": {
            "l": 70.55,
            "a": 0.8,
            "b": 18.6
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-1034 TPG",
        "name": "Breen",
        "rgb": {
            "r": 119,
            "g": 97,
            "b": 68
        },
        "hex": "776144",
        "positionInBook": 2399,
        "pageNumberInBook": "2.153",
        "lab": {
            "l": 42.98,
            "a": 6.25,
            "b": 20.33
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "19-0618 TPG",
        "name": "Beech",
        "rgb": {
            "r": 92,
            "g": 84,
            "b": 64
        },
        "hex": "5C5440",
        "positionInBook": 2401,
        "pageNumberInBook": "2.153",
        "lab": {
            "l": 36.28,
            "a": 0.57,
            "b": 13.51
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-0732 TPG",
        "name": "Olivenite",
        "rgb": {
            "r": 194,
            "g": 173,
            "b": 105
        },
        "hex": "C2AD69",
        "positionInBook": 2403,
        "pageNumberInBook": "2.154",
        "lab": {
            "l": 71.63,
            "a": 1.13,
            "b": 38.31
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-0839 TPG",
        "name": "Golden Palm",
        "rgb": {
            "r": 175,
            "g": 145,
            "b": 49
        },
        "hex": "AF9131",
        "positionInBook": 2405,
        "pageNumberInBook": "2.154",
        "lab": {
            "l": 62.01,
            "a": 3.71,
            "b": 53.52
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0835 TPG",
        "name": "Dried Tobacco",
        "rgb": {
            "r": 153,
            "g": 126,
            "b": 67
        },
        "hex": "997E43",
        "positionInBook": 2407,
        "pageNumberInBook": "2.154",
        "lab": {
            "l": 54.66,
            "a": 5.06,
            "b": 36.42
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-0725 TPG",
        "name": "Raffia",
        "rgb": {
            "r": 219,
            "g": 200,
            "b": 134
        },
        "hex": "DBC886",
        "positionInBook": 2409,
        "pageNumberInBook": "2.155",
        "lab": {
            "l": 81.34,
            "a": -0.05,
            "b": 35.85
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-0740 TPG",
        "name": "Bamboo",
        "rgb": {
            "r": 211,
            "g": 181,
            "b": 88
        },
        "hex": "D3B558",
        "positionInBook": 2411,
        "pageNumberInBook": "2.155",
        "lab": {
            "l": 75.04,
            "a": 3.23,
            "b": 50.91
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-0751 TPG",
        "name": "Lemon Curry",
        "rgb": {
            "r": 206,
            "g": 164,
            "b": 51
        },
        "hex": "CEA433",
        "positionInBook": 2413,
        "pageNumberInBook": "2.155",
        "lab": {
            "l": 70.14,
            "a": 7.62,
            "b": 61.88
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-0840 TPG",
        "name": "Tapenade",
        "rgb": {
            "r": 131,
            "g": 104,
            "b": 62
        },
        "hex": "83683E",
        "positionInBook": 2415,
        "pageNumberInBook": "2.155",
        "lab": {
            "l": 46.22,
            "a": 7.02,
            "b": 28.16
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-0952 TPG",
        "name": "Spicy Mustard",
        "rgb": {
            "r": 219,
            "g": 174,
            "b": 73
        },
        "hex": "DBAE49",
        "positionInBook": 2417,
        "pageNumberInBook": "2.156",
        "lab": {
            "l": 74.15,
            "a": 9.71,
            "b": 56.69
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-0952 TPG",
        "name": "Nugget Gold",
        "rgb": {
            "r": 203,
            "g": 157,
            "b": 49
        },
        "hex": "CB9D31",
        "positionInBook": 2419,
        "pageNumberInBook": "2.156",
        "lab": {
            "l": 67.97,
            "a": 10.37,
            "b": 60.33
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-0953 TPG",
        "name": "Tawny Olive",
        "rgb": {
            "r": 197,
            "g": 150,
            "b": 51
        },
        "hex": "C59633",
        "positionInBook": 2421,
        "pageNumberInBook": "2.156",
        "lab": {
            "l": 65.63,
            "a": 11.17,
            "b": 57.26
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-1031 TPG",
        "name": "Rattan",
        "rgb": {
            "r": 212,
            "g": 179,
            "b": 115
        },
        "hex": "D4B373",
        "positionInBook": 2423,
        "pageNumberInBook": "2.157",
        "lab": {
            "l": 74.97,
            "a": 6.63,
            "b": 37.37
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1132 TPG",
        "name": "Fall Leaf",
        "rgb": {
            "r": 200,
            "g": 170,
            "b": 110
        },
        "hex": "C8AA6E",
        "positionInBook": 2425,
        "pageNumberInBook": "2.157",
        "lab": {
            "l": 71.46,
            "a": 5.55,
            "b": 35.65
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1334 TPG",
        "name": "Tan",
        "rgb": {
            "r": 186,
            "g": 156,
            "b": 118
        },
        "hex": "BA9C76",
        "positionInBook": 2451,
        "pageNumberInBook": "2.161",
        "lab": {
            "l": 66.57,
            "a": 7.79,
            "b": 24.59
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1038 TPG",
        "name": "Tiger's Eye",
        "rgb": {
            "r": 158,
            "g": 129,
            "b": 104
        },
        "hex": "9E8168",
        "positionInBook": 2453,
        "pageNumberInBook": "2.161",
        "lab": {
            "l": 56.43,
            "a": 9.24,
            "b": 17.78
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1020 TPG",
        "name": "Cocoa Crème",
        "rgb": {
            "r": 139,
            "g": 115,
            "b": 96
        },
        "hex": "8B7360",
        "positionInBook": 2455,
        "pageNumberInBook": "2.161",
        "lab": {
            "l": 50.47,
            "a": 8.04,
            "b": 14.28
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1025 TPG",
        "name": "Foxtrot",
        "rgb": {
            "r": 143,
            "g": 108,
            "b": 80
        },
        "hex": "8F6C50",
        "positionInBook": 2457,
        "pageNumberInBook": "2.161",
        "lab": {
            "l": 48.83,
            "a": 12.7,
            "b": 21.54
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-0928 TPG",
        "name": "Curry",
        "rgb": {
            "r": 188,
            "g": 158,
            "b": 110
        },
        "hex": "BC9E6E",
        "positionInBook": 2459,
        "pageNumberInBook": "2.162",
        "lab": {
            "l": 67.12,
            "a": 7.05,
            "b": 29.82
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1045 TPG",
        "name": "Apple Cinnamon",
        "rgb": {
            "r": 174,
            "g": 137,
            "b": 93
        },
        "hex": "AE895D",
        "positionInBook": 2461,
        "pageNumberInBook": "2.162",
        "lab": {
            "l": 60.31,
            "a": 10.34,
            "b": 30.09
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "17-1125 TPG",
        "name": "Dijon",
        "rgb": {
            "r": 154,
            "g": 125,
            "b": 82
        },
        "hex": "9A7D52",
        "positionInBook": 2463,
        "pageNumberInBook": "2.162",
        "lab": {
            "l": 54.52,
            "a": 7.53,
            "b": 27.93
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-1031 TPG",
        "name": "Apricot Sherbet",
        "rgb": {
            "r": 253,
            "g": 209,
            "b": 156
        },
        "hex": "FDD19C",
        "positionInBook": 2465,
        "pageNumberInBook": "2.163",
        "lab": {
            "l": 87.15,
            "a": 10.93,
            "b": 33.09
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1144 TPG",
        "name": "Oak Buff",
        "rgb": {
            "r": 208,
            "g": 159,
            "b": 100
        },
        "hex": "D09F64",
        "positionInBook": 2467,
        "pageNumberInBook": "2.163",
        "lab": {
            "l": 69.65,
            "a": 13.49,
            "b": 38.84
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-1159 TPG",
        "name": "Zinnia",
        "rgb": {
            "r": 255,
            "g": 166,
            "b": 56
        },
        "hex": "FFA638",
        "positionInBook": 2499,
        "pageNumberInBook": "2.167",
        "lab": {
            "l": 78.04,
            "a": 32.75,
            "b": 70.58
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-1025 TPG",
        "name": "Impala",
        "rgb": {
            "r": 253,
            "g": 212,
            "b": 152
        },
        "hex": "FDD498",
        "positionInBook": 2501,
        "pageNumberInBook": "2.168",
        "lab": {
            "l": 87.75,
            "a": 9.16,
            "b": 36.08
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-1045 TPG",
        "name": "Amber",
        "rgb": {
            "r": 240,
            "g": 176,
            "b": 88
        },
        "hex": "F0B058",
        "positionInBook": 2503,
        "pageNumberInBook": "2.168",
        "lab": {
            "l": 77.12,
            "a": 17.83,
            "b": 54.4
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-0941 TPG",
        "name": "Beeswax",
        "rgb": {
            "r": 239,
            "g": 172,
            "b": 81
        },
        "hex": "EFAC51",
        "positionInBook": 2505,
        "pageNumberInBook": "2.168",
        "lab": {
            "l": 76.05,
            "a": 19.39,
            "b": 56.36
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-0939 TPG",
        "name": "Golden Cream",
        "rgb": {
            "r": 241,
            "g": 188,
            "b": 105
        },
        "hex": "F1BC69",
        "positionInBook": 2507,
        "pageNumberInBook": "2.169",
        "lab": {
            "l": 80.22,
            "a": 12.95,
            "b": 49.7
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-1052 TPG",
        "name": "Kumquat",
        "rgb": {
            "r": 252,
            "g": 176,
            "b": 90
        },
        "hex": "FCB05A",
        "positionInBook": 2509,
        "pageNumberInBook": "2.169",
        "lab": {
            "l": 78.46,
            "a": 23.35,
            "b": 55.34
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1050 TPG",
        "name": "Golden Glow",
        "rgb": {
            "r": 221,
            "g": 161,
            "b": 66
        },
        "hex": "DDA142",
        "positionInBook": 2511,
        "pageNumberInBook": "2.169",
        "lab": {
            "l": 71.26,
            "a": 16.77,
            "b": 57.37
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-1051 TPG",
        "name": "Golden Orange",
        "rgb": {
            "r": 215,
            "g": 146,
            "b": 50
        },
        "hex": "D79232",
        "positionInBook": 2513,
        "pageNumberInBook": "2.169",
        "lab": {
            "l": 67.1,
            "a": 21.9,
            "b": 59.38
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-0932 TPG",
        "name": "Cornsilk",
        "rgb": {
            "r": 236,
            "g": 196,
            "b": 120
        },
        "hex": "ECC478",
        "positionInBook": 2515,
        "pageNumberInBook": "2.17",
        "lab": {
            "l": 81.84,
            "a": 8.14,
            "b": 44.04
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-0851 TPG",
        "name": "Samoan Sun",
        "rgb": {
            "r": 245,
            "g": 204,
            "b": 114
        },
        "hex": "F5CC72",
        "positionInBook": 2517,
        "pageNumberInBook": "2.17",
        "lab": {
            "l": 84.56,
            "a": 8.37,
            "b": 50.02
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-0850 TPG",
        "name": "Daffodil",
        "rgb": {
            "r": 255,
            "g": 199,
            "b": 82
        },
        "hex": "FFC752",
        "positionInBook": 2519,
        "pageNumberInBook": "2.17",
        "lab": {
            "l": 84.61,
            "a": 13.53,
            "b": 65.72
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0826 TPG",
        "name": "Golden Haze",
        "rgb": {
            "r": 250,
            "g": 218,
            "b": 149
        },
        "hex": "FADA95",
        "positionInBook": 2521,
        "pageNumberInBook": "2.171",
        "lab": {
            "l": 88.82,
            "a": 4.89,
            "b": 38.64
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-0848 TPG",
        "name": "Mimosa",
        "rgb": {
            "r": 235,
            "g": 191,
            "b": 87
        },
        "hex": "EBBF57",
        "positionInBook": 2523,
        "pageNumberInBook": "2.171",
        "lab": {
            "l": 80.22,
            "a": 8.09,
            "b": 57.54
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-0957 TPG",
        "name": "Spectra Yellow",
        "rgb": {
            "r": 255,
            "g": 196,
            "b": 41
        },
        "hex": "FFC429",
        "positionInBook": 2525,
        "pageNumberInBook": "2.171",
        "lab": {
            "l": 83.37,
            "a": 13.54,
            "b": 78.13
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-0751 TPG",
        "name": "High Visibility",
        "rgb": {
            "r": 245,
            "g": 203,
            "b": 0
        },
        "hex": "F5CB00",
        "positionInBook": 2551,
        "pageNumberInBook": "2.175",
        "lab": {
            "l": 83.93,
            "a": 4.68,
            "b": 84.38
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-0757 TPG",
        "name": "Misted Marigold",
        "rgb": {
            "r": 232,
            "g": 195,
            "b": 56
        },
        "hex": "E8C338",
        "positionInBook": 2553,
        "pageNumberInBook": "2.175",
        "lab": {
            "l": 80.62,
            "a": 4.77,
            "b": 69.88
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-0748 TPG",
        "name": "Gold Flake",
        "rgb": {
            "r": 194,
            "g": 153,
            "b": 39
        },
        "hex": "C29927",
        "positionInBook": 2555,
        "pageNumberInBook": "2.175",
        "lab": {
            "l": 66,
            "a": 8.03,
            "b": 61.8
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-0644 TPG",
        "name": "Golden Kiwi",
        "rgb": {
            "r": 247,
            "g": 225,
            "b": 74
        },
        "hex": "F7E14A",
        "positionInBook": 2557,
        "pageNumberInBook": "2.176",
        "lab": {
            "l": 89.49,
            "a": -2.89,
            "b": 71.8
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0643 TPG",
        "name": "Blazing Yellow",
        "rgb": {
            "r": 246,
            "g": 230,
            "b": 62
        },
        "hex": "F6E63E",
        "positionInBook": 2559,
        "pageNumberInBook": "2.176",
        "lab": {
            "l": 90.54,
            "a": -6.08,
            "b": 76.91
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "14-0760 TPG",
        "name": "Cyber Yellow",
        "rgb": {
            "r": 253,
            "g": 210,
            "b": 14
        },
        "hex": "FDD20E",
        "positionInBook": 2561,
        "pageNumberInBook": "2.176",
        "lab": {
            "l": 86.33,
            "a": 4.82,
            "b": 84.8
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0735 TPG",
        "name": "Yellowtail",
        "rgb": {
            "r": 237,
            "g": 219,
            "b": 107
        },
        "hex": "EDDB6B",
        "positionInBook": 2563,
        "pageNumberInBook": "2.177",
        "lab": {
            "l": 87.46,
            "a": -3.04,
            "b": 56.21
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-0647 TPG",
        "name": "Illuminating",
        "rgb": {
            "r": 243,
            "g": 223,
            "b": 77
        },
        "hex": "F3DF4D",
        "positionInBook": 2565,
        "pageNumberInBook": "2.177",
        "lab": {
            "l": 88.79,
            "a": -3.64,
            "b": 69.77
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-0635 TPG",
        "name": "Snake Eye",
        "rgb": {
            "r": 206,
            "g": 188,
            "b": 56
        },
        "hex": "CEBC38",
        "positionInBook": 2567,
        "pageNumberInBook": "2.177",
        "lab": {
            "l": 76.17,
            "a": -3.42,
            "b": 64.82
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0806 TPG",
        "name": "Rutabaga",
        "rgb": {
            "r": 236,
            "g": 224,
            "b": 189
        },
        "hex": "ECE0BD",
        "positionInBook": 2599,
        "pageNumberInBook": "2.182",
        "lab": {
            "l": 89.62,
            "a": 0.12,
            "b": 18.76
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0910 TPG",
        "name": "Lamb's Wool",
        "rgb": {
            "r": 234,
            "g": 212,
            "b": 179
        },
        "hex": "EAD4B3",
        "positionInBook": 2601,
        "pageNumberInBook": "2.182",
        "lab": {
            "l": 86.14,
            "a": 4.93,
            "b": 19.13
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0714 TPG",
        "name": "Cornhusk",
        "rgb": {
            "r": 244,
            "g": 218,
            "b": 178
        },
        "hex": "F4DAB2",
        "positionInBook": 2603,
        "pageNumberInBook": "2.182",
        "lab": {
            "l": 88.7,
            "a": 4.66,
            "b": 23.83
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "11-0515 TPG",
        "name": "Lemon Icing",
        "rgb": {
            "r": 244,
            "g": 235,
            "b": 200
        },
        "hex": "F4EBC8",
        "positionInBook": 2605,
        "pageNumberInBook": "2.183",
        "lab": {
            "l": 93.25,
            "a": -0.7,
            "b": 18.36
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0713 TPG",
        "name": "Almond Oil",
        "rgb": {
            "r": 241,
            "g": 229,
            "b": 190
        },
        "hex": "F1E5BE",
        "positionInBook": 2607,
        "pageNumberInBook": "2.183",
        "lab": {
            "l": 91.16,
            "a": 0.48,
            "b": 20.24
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0712 TPG",
        "name": "Vanilla",
        "rgb": {
            "r": 244,
            "g": 228,
            "b": 196
        },
        "hex": "F4E4C4",
        "positionInBook": 2609,
        "pageNumberInBook": "2.183",
        "lab": {
            "l": 91.44,
            "a": 1.74,
            "b": 18.07
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0817 TPG",
        "name": "Apricot Gelato",
        "rgb": {
            "r": 240,
            "g": 212,
            "b": 164
        },
        "hex": "F0D4A4",
        "positionInBook": 2611,
        "pageNumberInBook": "2.183",
        "lab": {
            "l": 86.63,
            "a": 5.05,
            "b": 28.19
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "11-0202 TPG",
        "name": "Baby's Breath",
        "rgb": {
            "r": 235,
            "g": 230,
            "b": 213
        },
        "hex": "EBE6D5",
        "positionInBook": 2613,
        "pageNumberInBook": "2.184",
        "lab": {
            "l": 91.22,
            "a": -0.01,
            "b": 8.76
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "11-0107 TPG",
        "name": "Papyrus",
        "rgb": {
            "r": 241,
            "g": 236,
            "b": 215
        },
        "hex": "F1ECD7",
        "positionInBook": 2615,
        "pageNumberInBook": "2.184",
        "lab": {
            "l": 93.44,
            "a": -0.7,
            "b": 10.7
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "12-0602 TPG",
        "name": "Arctic Wolf",
        "rgb": {
            "r": 230,
            "g": 223,
            "b": 209
        },
        "hex": "E6DFD1",
        "positionInBook": 2617,
        "pageNumberInBook": "2.184",
        "lab": {
            "l": 89.25,
            "a": 0.96,
            "b": 7.84
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "13-1012 TPG",
        "name": "Frosted Almond",
        "rgb": {
            "r": 217,
            "g": 196,
            "b": 173
        },
        "hex": "D9C4AD",
        "positionInBook": 2619,
        "pageNumberInBook": "2.185",
        "lab": {
            "l": 80.48,
            "a": 5.46,
            "b": 14.45
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "15-0927 TPG",
        "name": "Pale Gold",
        "rgb": {
            "r": 199,
            "g": 155,
            "b": 105
        },
        "hex": "C79B69",
        "positionInBook": 2621,
        "pageNumberInBook": "2.185",
        "lab": {
            "l": 67.69,
            "a": 13.2,
            "b": 32.74
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "16-1325 TPG",
        "name": "Copper",
        "rgb": {
            "r": 191,
            "g": 125,
            "b": 92
        },
        "hex": "BF7D5C",
        "positionInBook": 2623,
        "pageNumberInBook": "2.185",
        "lab": {
            "l": 59.5,
            "a": 24.85,
            "b": 29.89
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhiPaperTpg",
            "title": "FHI Paper TPG"
        },
        "recipes": null
    },
    {
        "code": "18-1750 TCX",
        "name": "Viva Magenta",
        "rgb": {
            "r": 187,
            "g": 38,
            "b": 73
        },
        "hex": "BB2649",
        "positionInBook": 966,
        "pageNumberInBook": "28",
        "lab": {
            "l": 43.6,
            "a": 61.25,
            "b": 20.98
        },
        "cmyk": null,
        "book": {
            "id": "pantoneFhCottonTcx",
            "title": "FHI Cotton TCX"
        },
        "recipes": null
    }
];