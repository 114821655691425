import React from "react";

import Container from "react-bootstrap/Container";
import { Jumbotron } from "./migration";

const GetInTouch = ({ message, email }) => {
  return (
    <Jumbotron id="iletisim" className="m-0">
      <div className="container row">
        <Container className="text-center">
          <div className="row">

            <span className="col-6">
            </span>

            <span className="col-6">
              <p>
                <span> Whatsapp destek hattı </span>
                <span><a href="https://wa.me/905312111910">
                  <i style={{ color: "#075E54" }}
                    className={`fab fa-whatsapp socialicons`}> +905312111910 </i>
                </a>
                </span>
              </p>
              <p>
                <span> Çalışma saatleri </span>
                <span> Hafta içi ve Hafta sonu </span>
                <span> 10:00 - 20:00 </span>
              </p>

            </span>
          </div>
        </Container>
      </div >
    </Jumbotron >
  );
};

export default GetInTouch;
